<template>
  <v-row>
    <v-col
        cols="12"
    >
      <h3>
        Výkazy
      </h3>

      <p>
        {{ Wage.priceSpendReports|currency('€') }}

        <template
            v-if="Wage.priceSpendReportsBank || Wage.priceSpendReportsCash"
        >
          <br/>

          <small>
            <template
                v-if="Wage.priceSpendReportsBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Wage.priceSpendReportsBank|currency('€') }}
            </template>

            <template
                v-if="Wage.priceSpendReportsCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Wage.priceSpendReportsCash|currency('€') }}
            </template>
          </small>
        </template>
      </p>

      <IsEmptyHelper
          :value="Wage.wageTicketItemReports.length > 0"
          if-no="table"
          if-yes="p"
          :no-gutters="true"
      >
        <thead>
          <tr>
            <th
                width="2.5%"
            >
              <v-checkbox
                  v-model="all"
                  :dense="true"
                  hide-details="auto"
                  @change="(value) => {
                    ids = []

                    totalTime = 0

                    totalPrice = 0

                    if (value) {
                      Wage.wageTicketItemReports.forEach((_WageTicketItemReport) => {
                        ids.push(_WageTicketItemReport.TicketItemReport.id)

                        totalTime += _WageTicketItemReport.timeSpend

                        totalPrice += _WageTicketItemReport.priceSpend
                      })
                    }
                  }"
              />
            </th>

            <th
                width="10%"
                align="left"
            >
              Vykonanie
            </th>

            <th
                width="17.5%"
                align="left"
            >
              Projekt
            </th>

            <th
                width="10%"
                align="left"
            >
              Čas
            </th>

            <th
                width="10%"
                align="left"
            >
              Plat
            </th>

            <th
                width="10%"
                align="left"
            >
              Suma
            </th>

            <th
                width="10%"
                align="left"
            >
              Stav
            </th>

            <th
                width="7.5%"
                align="left"
            >
              Uhradenie
            </th>

            <th
                width="7.5%"
                align="left"
            >
              Platba
            </th>

            <th
                width="15%"
                align="left"
            >
              Účet
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
              v-for="(_WageTicketItemReport) in Wage.wageTicketItemReports"
              :key="_WageTicketItemReport.TicketItemReport.id"
              valign="top"
          >
            <td>
              <v-checkbox
                  v-model="ids"
                  :value="_WageTicketItemReport.TicketItemReport.id"
                  :dense="true"
                  hide-details="auto"
                  @change="() => {
                    if (ids.length === Wage.wageTicketItemReports.length) {
                      all = true
                    } else {
                      all = false
                    }

                    if (ids.includes(_WageTicketItemReport.TicketItemReport.id)) {
                      totalTime += _WageTicketItemReport.timeSpend

                      totalPrice += _WageTicketItemReport.priceSpend
                    } else {
                      totalTime -= _WageTicketItemReport.timeSpend

                      totalPrice -= _WageTicketItemReport.priceSpend
                    }
                  }"
              />
            </td>

            <td>
              {{ _WageTicketItemReport.workedAt|moment('DD.MM.YYYY') }}
            </td>

            <td>
              {{ _WageTicketItemReport.currentProjectTitle }}
            </td>

            <td>
              {{ _WageTicketItemReport.timeSpend|number }} h
            </td>

            <td>
              {{ _WageTicketItemReport.priceHour|currency('€ / h') }}
            </td>

            <td>
              {{ _WageTicketItemReport.priceSpend|currency('€') }}
            </td>

            <td>
              {{ statusList[_WageTicketItemReport.status]?.text }}
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketItemReport.paidAt"
                  if-no="span"
                  if-yes="span"
              >
                {{ _WageTicketItemReport.paidAt|moment('DD.MM.YYYY') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketItemReport.pay"
                  if-no="span"
                  if-yes="span"
              >
                {{ payList[_WageTicketItemReport.pay]?.text }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketItemReport.accountBillingName"
                  if-no="span"
                  if-yes="span"
              >
                {{ _WageTicketItemReport.accountBillingName }}
              </IsEmptyHelper>
            </td>
          </tr>
        </tbody>

        <tfoot
            v-if="ids.length > 0"
        >
          <tr>
            <th
                align="left"
            >
              <UpdateMassTicketItemReport
                  :x-small="true"
                  :closingId="Wage.Closing.id"
                  :ids="ids"
                  @onUpdateMass="$emit('refresh')"
              />
            </th>

            <th
                align="left"
                :colspan="2"
            />

            <th
                align="left"
            >
              {{ totalTime|number }} h
            </th>

            <th
                align="left"
            />

            <th
                align="left"
            >
              {{ totalPrice|currency('€') }}
            </th>

            <th
                align="left"
                :colspan="4"
            />
          </tr>
        </tfoot>
      </IsEmptyHelper>
    </v-col>
  </v-row>
</template>

<script>
  import UpdateMassTicketItemReport from '@/components/TicketItemReports/UpdateMass'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItemReports',
    props: [
      'Wage'
    ],
    emits: [
      'refresh'
    ],
    components: {
      UpdateMassTicketItemReport,
      IsEmptyHelper
    },
    data() {
      return {
        all: false,
        ids: [],
        totalTime: 0,
        totalPrice: 0
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.WageTicketItemReport?.status
      },
      payList: function () {
        return this.$store.state.core.Entity.WageTicketItemReport?.pay
      }
    }
  }
</script>
