<template>
  <CreateSave
      :maxWidth="500"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreateAccount', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.paidAt"
              :error-messages="errors.paidAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum uhradenia"
              type="date"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.price"
              :error-messages="errors.price"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Suma"
              type="number"
              suffix="€"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-select
              v-model="ItemForm.pay"
              :error-messages="errors.pay"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Platba"
              :items="pays"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.note"
              :error-messages="errors.note"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import Expenses from '@/services/expenses'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'CreateAccount',
    props: [
      'accountId'
    ],
    emits: [
      'onCreateAccount'
    ],
    components: {
      CreateSave
    },
    computed: {
      payList: function () {
        return this.$store.state.core.Entity.Expense?.pay
      },
      pays: function () {
        return Object.values(this.payList)
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť výdaj'
      },
      setItemForm() {
        return {
          paidAt: this.$moment().format('YYYY-MM-DD'),
          title: null,
          price: 0,
          pay: this.payList.bank?.value,
          note: null
        }
      },
      createData(ItemForm) {
        return Expenses
          .createAccount({
            accountId: this.accountId,
            data: ItemForm
          })
      }
    }
  }
</script>
