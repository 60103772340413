import UserProfile from '@/services/user-profile'

const state = {
  User: null
}

const mutations = {
  SET_USER(state, User) {
    state.User = User
  }
}

const actions = {
  async check({commit}) {
    return await UserProfile
      .exist()
      .then((response) => {
        commit('SET_USER', response.data.data.User)
      })
      .catch(() => {
        commit('SET_USER', null)
      })
  }
}

const getters = {
  isLogged: (state) => {
    return state.User !== null
  },
  isUnLogged: (state) => {
    return state.User === null
  },
  hasRole: (state) => (role) => {
    return state.User.roles.includes(role)
  }
}

const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default auth
