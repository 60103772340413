var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Plusy ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalPluses,'€'))+" "),(_vm.Bilance.totalPlusesBank || _vm.Bilance.totalPlusesCash)?[_c('br'),_c('small',[(_vm.Bilance.totalPlusesBank)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bank-transfer ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalPlusesBank,'€'))+" ")]:_vm._e(),(_vm.Bilance.totalPlusesCash)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalPlusesCash,'€'))+" ")]:_vm._e()],2)]:_vm._e()],2),_c('IsEmptyHelper',{attrs:{"value":_vm.Bilance.bilancePluses.length > 0,"if-no":"table","if-yes":"p","no-gutters":true}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"2.5%"}},[_c('v-checkbox',{attrs:{"dense":true,"hide-details":"auto"},on:{"change":(value) => {
                  _vm.ids = []

                  _vm.total = 0

                  if (value) {
                    _vm.Bilance.bilancePluses.forEach((_BilancePlus) => {
                      _vm.ids.push(_BilancePlus.id)

                      _vm.total += _BilancePlus.price
                    })
                  }
                }},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Dátum ")]),_c('th',{attrs:{"width":"70%","align":"left"}},[_vm._v(" Názov ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Suma ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Platba ")])])]),_c('tbody',_vm._l((_vm.Bilance.bilancePluses),function(_BilancePlus){return _c('tr',{key:_BilancePlus.id,attrs:{"valign":"top"}},[_c('td',[_c('v-checkbox',{attrs:{"value":_BilancePlus.id,"dense":true,"hide-details":"auto"},on:{"change":() => {
                  if (_vm.ids.length === _vm.Bilance.bilancePluses.length) {
                    _vm.all = true
                  } else {
                    _vm.all = false
                  }

                  if (_vm.ids.includes(_BilancePlus.id)) {
                    _vm.total += _BilancePlus.price
                  } else {
                    _vm.total -= _BilancePlus.price
                  }
                }},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_BilancePlus.paidAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_BilancePlus.paidAt,'DD.MM.YYYY'))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_BilancePlus.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_BilancePlus.price,'€'))+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_BilancePlus.pay,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm.payList[_BilancePlus.pay]?.text)+" ")])],1)])}),0),(_vm.ids.length > 0)?_c('tfoot',[_c('tr',[_c('th',{attrs:{"align":"left","colspan":3}}),_c('th',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total,'€'))+" ")]),_c('th',{attrs:{"align":"left"}})])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }