var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":7,"empty":"Nenašli sa žiadni užívatelia.","itemsKey":"users","filterKey":"User","defaultFilters":{
          type: null
        },"defaultOrder":"use.billingName","defaultBy":"ASC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}}),_vm._v("   ")]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.type,"outlined":true,"dense":true,"hide-details":"auto","label":"Typ","items":_vm.types,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.type),callback:function ($$v) {_vm.$set(params.Filters, "type", $$v)},expression:"params.Filters.type"}})],1)],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',{staticClass:"fixed"},[_c('OrderList',{attrs:{"orderKey":"use.name","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" E-mail firemný ")]),_c('th',[_vm._v(" E-mail osobný ")]),_c('th',[_vm._v(" Telefón ")]),_c('th',[_vm._v(" Typ ")]),_c('th',[_vm._v(" Plat ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item,
            init
          }){return [_c('td',{staticClass:"fixed"},[_c('TruncateHelper',{attrs:{"title":Item.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.name)+" ")])],1),_c('td',[_c('a',{attrs:{"href":'mailto: ' + Item.emailBusiness}},[_c('TruncateHelper',{attrs:{"title":Item.emailBusiness,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.emailBusiness)+" ")])],1)]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.emailPersonal,"if-no":"a","if-yes":"span","href":'mailto: ' + Item.emailPersonal}},[_c('TruncateHelper',{attrs:{"title":Item.emailPersonal,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.emailPersonal)+" ")])],1)],1),_c('td',[_c('a',{attrs:{"href":'tel: ' + Item.phone}},[_c('TruncateHelper',{attrs:{"title":Item.phone,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.phone)+" ")])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.typeList[Item.type]?.text)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(Item.basePriceHour,'€ / h'))+" "),(Item.basePriceHour !== Item.minPriceHour)?[_vm._v(" (min. "+_vm._s(_vm._f("currency")(Item.minPriceHour,'€ / h'))+") ")]:_vm._e()],2),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/management/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-cash ")])],1),_vm._v("   "),_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(Item.UserData.deletable)?[_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}})]:_vm._e()],2)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }