<template>
  <v-row>
    <v-col
        cols="12"
    >
      <PanelPart
          maxWidth="100%"
          color="primary"
          icon="mdi-filter"
          title="Filtre"
      >
        <template
            v-slot:default
        >
          <slot
              name="default"
              :setFilter="setFilter"
          />
        </template>
      </PanelPart>
    </v-col>
  </v-row>
</template>

<script>
  import PanelPart from '@/components/_Part/Panel'

  export default {
    name: 'Filter',
    props: [
      'value'
    ],
    emits: [
      'setParams'
    ],
    components: {
      PanelPart
    },
    methods: {
      setFilter() {
        this.$emit('setParams', {
          page: 1
        })
      }
    }
  }
</script>
