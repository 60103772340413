<template>
  <FormPart
      :Item="Ticket"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-btn
              type="submit"
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
          >
            <v-icon>
              mdi-calculator
            </v-icon>

            Vybrať
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketType from '@/services/ticket-type'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormTypeChoosePricing',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          token: Item.token
        }
      },
      submitData(ItemForm) {
        return TicketType
          .choosePricing({
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail(response) {
        if (response.status !== 409) {
          this.$router.push('/tickets')
        }
      }
    }
  }
</script>
