<template>
  <FormPart
      :Item="TicketItem"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.deadlineAt"
              :error-messages="errors.deadlineAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum dokončenia"
              type="date"
              :clearable="true"
              @change="submit"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.priority"
              :error-messages="errors.priority"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Priorita"
              suffix="úroveň"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormSettings',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          deadlineAt: (
            Item.deadlineAt
              ? this.$moment(Item.deadlineAt).format('YYYY-MM-DD')
              : null
          ),
          priority: Item.priority
        }
      },
      submitData(ItemForm) {
        return TicketItems
          .updateSettings({
            id: this.TicketItem.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/ticket/' + this.TicketItem.Ticket.id)
      }
    }
  }
</script>
