<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 40px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="true"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="7"
          empty="Nenašli sa žiadne maily."
          itemsKey="mails"
          filterKey="Mail"
          :defaultFilters="{
            subject: null,
            status: null
          }"
          defaultOrder="mai.createdAt"
          defaultBy="DESC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-row>
            <v-col
                cols="12"
                lg="3"
            >
              <SuggestIndexesModule
                  v-model="params.Filters.subject"
                  :error-messages="errors.Filters?.subject"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Predmet"
                  :clearable="true"
                  @change="setFilter"
                  module="mail_subject"
              />
            </v-col>

            <v-col
                cols="12"
                lg="3"
            >
              <v-select
                  v-model="params.Filters.status"
                  :error-messages="errors.Filters?.status"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Stav"
                  :items="statuses"
                  :clearable="true"
                  @change="setFilter"
              />
            </v-col>
          </v-row>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th>
            Od
          </th>

          <th>
            Komu
          </th>

          <th>
            Predmet
          </th>

          <th>
            Stav
          </th>

          <th>
            <OrderList
                orderKey="mai.createdAt"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            <OrderList
                orderKey="mai.updatedAt"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item
            }"
        >
          <td>
            <a
                :href="'mailto: ' + Item.fromEmail"
            >
              <TruncateHelper
                  :title="Item.fromEmail"
                  maxWidth="300px"
              >
                {{ Item.fromEmail }}

                <template
                    v-if="Item.fromName"
                >
                  ({{ Item.fromName }})
                </template>
              </TruncateHelper>
            </a>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.toEmail"
            >
              <TruncateHelper
                  :title="Item.toEmail"
                  maxWidth="300px"
              >
                {{ Item.toEmail }}

                <template
                    v-if="Item.toName"
                >
                  ({{ Item.toName }})
                </template>
              </TruncateHelper>
            </a>
          </td>

          <td>
            <TruncateHelper
                :title="Item.subject"
                maxWidth="300px"
            >
              {{ Item.subject }}
            </TruncateHelper>
          </td>

          <td>
            {{ statusList[Item.status]?.text }}
          </td>

          <td>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.updatedAt"
                if-no="span"
                if-yes="span"
            >
              {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
            </IsEmptyHelper>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/mail/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Mails from '@/services/mails'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Mails',
    components: {
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper,
      SuggestIndexesModule
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.Mail?.status
      },
      statuses: function () {
        return Object.values(this.statusList)
      }
    },
    methods: {
      fetchData(params) {
        return Mails
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Maily'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
