var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":title,"hasSearch":true,"hasFilter":false,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":5,"empty":"Nenašli sa žiadni klienti.","itemsKey":"clients","filterKey":"Client","defaultOrder":"cli.billingName","defaultBy":"ASC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}}),_vm._v("   ")]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',{staticClass:"fixed"},[_c('OrderList',{attrs:{"orderKey":"cli.billingName","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_c('OrderList',{attrs:{"orderKey":"cli.contactName","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" E-mail ")]),_c('th',[_vm._v(" Telefón ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item,
            init
          }){return [_c('td',{staticClass:"fixed"},[_c('v-icon',[(Item.billingOnCompany)?[_vm._v(" mdi-domain ")]:[_vm._v(" mdi-account ")]],2),_vm._v("   "),_c('TruncateHelper',{attrs:{"title":Item.billingName,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.billingName)+" ")])],1),_c('td',[_c('TruncateHelper',{attrs:{"title":Item.contactName,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.contactName)+" ")])],1),_c('td',[_c('a',{attrs:{"href":'mailto: ' + Item.contactEmail}},[_c('TruncateHelper',{attrs:{"title":Item.contactEmail,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.contactEmail)+" ")])],1)]),_c('td',[_c('a',{attrs:{"href":'tel: ' + Item.contactPhone}},[_c('TruncateHelper',{attrs:{"title":Item.contactPhone,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.contactPhone)+" ")])],1)]),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/client/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1),_vm._v("   "),_c('CreateClientProject',{attrs:{"clientId":Item.id},on:{"onCreateClient":init}}),_vm._v("   "),_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(_vm.$store.getters['auth/isLogged'] && _vm.$store.getters['auth/hasRole']('ROLE_MASTER'))?[_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}})]:_vm._e()],2)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }