import Vue from 'vue'

import Qs from 'qs'

class WagesAuth {
  async index({params}) {
    return await Vue.axios
      .get('app/wages/auth', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/wages/auth/' + id)
  }
}

const wagesAuth = new WagesAuth()

export default wagesAuth
