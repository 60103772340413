import Vue from 'vue'

class UserNewPassword {
  async restore({data}) {
    return await Vue.axios
      .post('app/user/new-password/restore', data)
  }

  async exist({newPasswordToken}) {
    return await Vue.axios
      .get('app/user/new-password/exist/' + newPasswordToken)
  }

  async set({newPasswordToken, data}) {
    return await Vue.axios
      .put('app/user/new-password/set/' + newPasswordToken, data)
  }
}

const userNewPassword = new UserNewPassword()

export default userNewPassword
