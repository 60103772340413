<template>
  <v-row>
    <v-col
        cols="12"
    >
      <h3>
        Tarifa
      </h3>

      <p>
        {{ TicketItem.tariffTitle }}
      </p>
    </v-col>

    <v-col
        cols="12"
    >
      <h3>
        Popis
      </h3>

      <IsEmptyHelper
          :value="TicketItem.tariffDescription"
          if-no="p"
          if-yes="p"
      >
        <pre>{{ TicketItem.tariffDescription }}</pre>
      </IsEmptyHelper>
    </v-col>

    <v-col
        cols="12"
        lg="4"
    >
      <h3>
        Sadzba
      </h3>

      <p>
        {{ TicketItem.tariffPrice|currency('€') }}

        <template
            v-if="TicketItem.tariffFrequency"
        >
          / {{ TicketItem.tariffFrequency }}
        </template>

        / {{ TicketItem.tariffUnit }}
      </p>
    </v-col>

    <v-col
        cols="12"
        lg="4"
    >
      <h3>
        Typ
      </h3>

      <p>
        {{ tariffTypeList[TicketItem.tariffType]?.text }}
      </p>
    </v-col>

    <v-col
        cols="12"
        lg="4"
    >
      <h3>
        Nastavenia
      </h3>

      <p>
        <template
            v-if="TicketItem.tariffType === tariffTypeList?.simple?.value"
        >
          {{ TicketItem.tariffMinProfit|percent }}
        </template>

        <template
            v-if="TicketItem.tariffType === tariffTypeList?.group?.value"
        >
          {{ TicketItem.tariffPriceOffers|currency('€') }} / {{ TicketItem.tariffTimeOffers|number }} h
        </template>

        (max. {{ TicketItem.tariffMaxPriceHour|currency('€ / h') }})
      </p>
    </v-col>
  </v-row>
</template>

<script>
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TariffInfo',
    props: [
      'TicketItem'
    ],
    components: {
      IsEmptyHelper
    },
    computed: {
      tariffTypeList: function () {
        return this.$store.state.core.Entity.TicketItem?.tariffType
      }
    }
  }
</script>
