<template>
  <div
      v-if="content"
  >
    <slot
        name="default"
        :loading="loading"
        :Item="Item"
        :title="title"
        :backLink="backLink"
        :refresh="refresh"
    />
  </div>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  export default {
    name: 'Page',
    props: [
      'itemKey',
      'fetchData',
      'setTitle',
      'setBreadcrumbs'
    ],
    emits: [
      'onSuccess',
      'onFail'
    ],
    data() {
      return {
        content: false,
        loading: false,
        Item: null,
        title: null,
        breadcrumbs: []
      }
    },
    metaInfo() {
      return {
        title: this.title
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      backLink() {
        return (
          this.breadcrumbs.length > 0
            ? this.breadcrumbs.at(-1).to
            : '/'
        )
      }
    },
    methods: {
      init() {
        this.initData()
      },
      refresh() {
        this.refreshData()
      },
      initData() {
        this.prepareData('content', false)
      },
      refreshData() {
        this.prepareData('loading', true)
      },
      initBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Systém',
              to: '/'
            },
            ...this.breadcrumbs,
            {
              text: this.title,
              to: this.$route.path
            }
          ]
        })
      },
      prepareData(key, value) {
        if (this.fetchData) {
          this[key] = value

          this.fetchData()
            .then((response) => {
              this.Item = response.data.data[this.itemKey]

              this.title = this.setTitle(this.Item)

              this.breadcrumbs = this.setBreadcrumbs(this.Item)

              this.initBreadcrumbs()

              this.$emit('onSuccess', response)
            })
            .catch((error) => {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            })
            .finally(() => {
              this[key] = !value
            })
        } else {
          this[key] = !value

          this.title = this.setTitle()

          this.breadcrumbs = this.setBreadcrumbs()

          this.initBreadcrumbs()
        }
      }
    }
  }
</script>
