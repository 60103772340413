<template>
  <SuggestPart
      item-value="id"
      item-text="billingName"
      itemsKey="accounts"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="createButton"
      >
        <Create
            :small="true"
            @onCreate="init"
        />

        &nbsp;
      </template>
    </template>
  </SuggestPart>
</template>

<script>
  import Accounts from '@/services/accounts'

  import SuggestPart from '@/components/_Part/Suggest'

  import Create from '@/components/Accounts/Create'

  export default {
    name: 'Suggest',
    props: [
      'createButton'
    ],
    components: {
      SuggestPart,
      Create
    },
    methods: {
      fetchData() {
        return Accounts
          .suggest()
      }
    }
  }
</script>
