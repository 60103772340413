<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/files"
      itemKey="File"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.error"
            cols="12"
        >
          <v-alert
              type="error"
          >
            {{ Item.error }}
          </v-alert>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Priečinok
          </h3>

          <p>
            {{ Item.dir }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Názov
          </h3>

          <p>
            <a
                :href="$global.axiosUrl() + 'files/' + Item.token + '/generate/original'"
                target="_blank"
            >
              {{ Item.name }}
            </a>
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Typ
          </h3>

          <p>
            {{ Item.type }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Stav
          </h3>

          <p>
            {{ statusList[Item.status]?.text }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Dátum a čas zadania
          </h3>

          <p>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Dátum a čas nahratia
          </h3>

          <IsEmptyHelper
              :value="Item.updatedAt"
              if-no="p"
              if-yes="p"
          >
            {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
          </IsEmptyHelper>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Files from '@/services/files'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'File',
    components: {
      NotBlankPage,
      IsEmptyHelper
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.File?.status
      }
    },
    methods: {
      fetchData() {
        return Files
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Súbory',
            to: '/files'
          }
        ]
      }
    }
  }
</script>
