<template>
  <v-row
      v-if="pages !== 0"
      align="center"
  >
    <v-col
        cols="4"
    >
      <a
          v-for="(_limit, i) in limits"
          :key="i"
          @click="setLimit(_limit.value)"
      >
        {{ _limit.text }}
      </a>
    </v-col>

    <v-spacer/>

    <v-col
        cols="auto"
    >
      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="page === 1 || loading"
          color="primary"
          @click="previousPage"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
    </v-col>

    <v-col
        cols="auto"
    >
      {{ page }} / {{ pages }}
    </v-col>

    <v-col
        cols="auto"
    >
      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="page === pages || loading"
          color="primary"
          @click="nextPage"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Pagination',
    props: [
      'loading',
      'total',
      'limit',
      'page',
      'limitList'
    ],
    emits: [
      'setParams'
    ],
    computed: {
      limits: function () {
        return Object.values(this.limitList)
      },
      pages: function () {
        return Math.ceil(this.total / this.limit)
      }
    },
    methods: {
      setLimit(limit) {
        this.$emit('setParams', {
          limit: limit,
          page: 1
        })
      },
      previousPage() {
        this.$emit('setParams', {
          page: this.page - 1
        })
      },
      nextPage() {
        this.$emit('setParams', {
          page: this.page + 1
        })
      }
    }
  }
</script>
