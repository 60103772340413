<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdateTask', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <vue-editor
              v-model="ItemForm.content"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.files"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Prílohy"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :no-gutters="true"
                  :dense="true"
              >
                <template
                    v-if="files.length > 0"
                >
                  <v-col
                      v-for="(_file, index) in files"
                      :key="_file.id"
                      cols="12"
                  >
                    <a
                        :href="$global.axiosUrl() + 'files/'  + _file.token + '/generate/original'"
                        target="_blank"
                    >
                      {{ _file.name }}
                    </a>

                    <v-btn
                        :x-small="true"
                        :icon="true"
                        :outlined="true"
                        color="error"
                        @click="() => {
                          ItemForm.files.splice(index, 1)

                          files.splice(index, 1)
                        }"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </template>

                <v-col
                    cols="12"
                >
                  <File
                      :x-small="true"
                      :id="Item.id"
                      @onFile="(response) => {
                        ItemForm.files.push(response.data.data.id)

                        files.push({
                          id: response.data.data.id,
                          token: response.data.data.token,
                          name: response.data.data.name
                        })
                      }"
                  />
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Tickets from '@/services/tickets'

  import File from '@/components/Tickets/File'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'UpdateTask',
    props: [
      'id'
    ],
    emits: [
      'onUpdateTask'
    ],
    components: {
      File,
      UpdateSave
    },
    data() {
      return {
        files: []
      }
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť tiket "' + Item.title + '"'
      },
      setItemForm(Item) {
        this.files = Item.files.map((_File) => {
          return {
            id: _File.id,
            token: _File.token,
            name: _File.name
          }
        })

        return {
          content: Item.content,
          files: Item.files.map((_File) => _File.id)
        }
      },
      updateData(ItemForm) {
        return Tickets
          .updateTask({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
