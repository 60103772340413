<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="false"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="3"
          empty="Nenašli sa žiadne skupiny číselníkov."
          itemsKey="indexGroups"
          filterKey="IndexGroup"
          defaultOrder="indgro.sort"
          defaultBy="ASC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          &nbsp;
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th
              class="fixed"
          >
            Názov
          </th>

          <th>
            <OrderList
                orderKey="indgro.sort"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="fixed"
          >
            <TruncateHelper
                :title="Item.title"
                maxWidth="300px"
            >
              {{ Item.title }}
            </TruncateHelper>
          </td>

          <td>
            {{ Item.sort }}
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/index-group/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>

            &nbsp;

            <CreateIndexGroupIndex
                :indexGroupId="Item.id"
                @onCreateIndexGroup="init"
            />

            &nbsp;

            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            &nbsp;

            <Delete
                :id="Item.id"
                @onDelete="init"
            />
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import IndexGroups from '@/services/index-groups'

  import Create from '@/components/IndexGroups/Create'
  import Update from '@/components/IndexGroups/Update'
  import Delete from '@/components/IndexGroups/Delete'

  import CreateIndexGroupIndex from '@/components/Indexes/CreateIndexGroup'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'IndexGroups',
    components: {
      Create,
      Update,
      Delete,
      CreateIndexGroupIndex,
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper
    },
    methods: {
      fetchData(params) {
        return IndexGroups
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Skupiny číselníkov'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
