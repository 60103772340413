var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SaveModal',_vm._b({attrs:{"color":"success","icon":_vm.icon,"maxWidth":_vm.maxWidth,"button":"Nahrať","setTitle":_vm.setTitle,"setItemForm":_vm.setItemForm,"saveData":_vm.fileData},on:{"onSave":(response) => _vm.$emit('onFile', response)},scopedSlots:_vm._u([{key:"default",fn:function({
        errors,
        ItemForm
      }){return [(errors.name || errors.type || errors.content || errors.size)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Súbor nie je vyplnený! ")]):_vm._e(),_c('vue-file-upload-as-bsf',{attrs:{"id":"file","label_input":"📎"},on:{"fileLoaded":(files) => {
          const file = files[0]

          let FileForm = _vm.loadFile(file)

          if (FileForm) {
            ItemForm.name = FileForm.name

            ItemForm.type = FileForm.type

            ItemForm.content = FileForm.content

            ItemForm.size = FileForm.size
          }
        }}})]}}])},'SaveModal',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }