<template>
  <SaveModal
      color="success"
      icon="mdi-pen"
      :maxWidth="maxWidth"
      button="Podpísať"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="signatureData"
      @onSave="(response) => $emit('onSignature', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-alert
          v-if="errors.name || errors.type || errors.content || errors.size"
          type="error"
      >
        Podpis nie je vyplnený!
      </v-alert>

      <VueSignaturePad
          ref="signature"
          :width="width"
          :height="height"
          :options="{
            onBegin: () => {
              $refs.signature.resizeCanvas()
            },
            onEnd: () => {
              const signature = $refs.signature.saveSignature()

              let SignatureForm = loadSignature(signature)

              if (SignatureForm) {
                ItemForm.name = SignatureForm.name

                ItemForm.type = SignatureForm.type

                ItemForm.content = SignatureForm.content

                ItemForm.size = SignatureForm.size
              }
            }
          }"
      />
    </template>
  </SaveModal>
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Signature',
    props: [
      'maxWidth',
      'setTitle',
      'signatureData',
      'width',
      'height'
    ],
    emits: [
      'onSignature'
    ],
    components: {
      SaveModal
    },
    methods: {
      setItemForm() {
        return {
          name: null,
          type: null,
          content: null,
          size: null
        }
      },
      loadSignature(signature) {
        let SignatureForm = null

        if (signature) {
          SignatureForm = {
            name: 'signature.jpg',
            type: 'image/jpg',
            content: signature.data,
            size: signature.data.length
          }
        }

        return SignatureForm
      }
    }
  }
</script>
