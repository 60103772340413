<template>
  <FormPart
      :Item="TicketItem"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.pricingAutoCalculate"
              :error-messages="errors.pricingAutoCalculate"
              :dense="true"
              hide-details="auto"
              label="Automatický výpočet"
              @change="submit"
          />
        </v-col>

        <template
            v-if="!ItemForm.pricingAutoCalculate"
        >
          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.pricingQuantity"
                :error-messages="errors.pricingQuantity"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Množstvo"
                type="number"
                suffix="x"
                @change="submit"
            />
          </v-col>

          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.pricingIncrease"
                :error-messages="errors.pricingIncrease"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Prirážka"
                type="number"
                suffix="€"
                @change="submit"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.pricingDescription"
              :error-messages="errors.pricingDescription"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Popis"
              :rows="5"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormPricing',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          pricingAutoCalculate: Item.pricingAutoCalculate,
          pricingQuantity: Item.pricingQuantity,
          pricingIncrease: Item.pricingIncrease,
          pricingDescription: Item.pricingDescription
        }
      },
      submitData(ItemForm) {
        return TicketItems
          .updatePricing({
            id: this.TicketItem.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/ticket/' + this.TicketItem.Ticket.id)
      }
    }
  }
</script>
