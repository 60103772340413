var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":"calc(100% - 160px)","hasBackLink":true,"failLink":"/clients","itemKey":"Client","fetchData":_vm.fetchData,"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        refresh
      }){return [_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":refresh}}),_vm._v("   "),(_vm.$store.getters['auth/isLogged'] && _vm.$store.getters['auth/hasRole']('ROLE_MASTER'))?[_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":_vm.onDelete}}),_vm._v("   ")]:_vm._e()]}},{key:"default",fn:function({
        Item,
        refresh
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Projects',{attrs:{"Client":Item},on:{"refresh":refresh}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }