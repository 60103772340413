<template>
  <v-row>
    <v-col
        cols="12"
    >
      <h3>
        Mínusy
      </h3>

      <p>
        {{ Bilance.totalMinuses|currency('€') }}

        <template
            v-if="Bilance.totalMinusesBank || Bilance.totalMinusesCash"
        >
          <br/>

          <small>
            <template
                v-if="Bilance.totalMinusesBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Bilance.totalMinusesBank|currency('€') }}
            </template>

            <template
                v-if="Bilance.totalMinusesCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Bilance.totalMinusesCash|currency('€') }}
            </template>
          </small>
        </template>
      </p>

      <IsEmptyHelper
          :value="Bilance.bilanceMinuses.length > 0"
          if-no="table"
          if-yes="p"
          :no-gutters="true"
      >
        <thead>
          <tr>
            <th
                width="2.5%"
            >
              <v-checkbox
                  v-model="all"
                  :dense="true"
                  hide-details="auto"
                  @change="(value) => {
                    ids = []

                    total = 0

                    if (value) {
                      Bilance.bilanceMinuses.forEach((_BilanceMinus) => {
                        ids.push(_BilanceMinus.id)

                        total += _BilanceMinus.price
                      })
                    }
                  }"
              />
            </th>

            <th
                width="10%"
                align="left"
            >
              Dátum
            </th>

            <th
                width="70%"
                align="left"
            >
              Názov
            </th>

            <th
                width="10%"
                align="left"
            >
              Suma
            </th>

            <th
                width="7.5%"
                align="left"
            >
              Platba
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
              v-for="(_BilanceMinus) in Bilance.bilanceMinuses"
              :key="_BilanceMinus.id"
              valign="top"
          >
            <td>
              <v-checkbox
                  v-model="ids"
                  :value="_BilanceMinus.id"
                  :dense="true"
                  hide-details="auto"
                  @change="() => {
                    if (ids.length === Bilance.bilanceMinuses.length) {
                      all = true
                    } else {
                      all = false
                    }

                    if (ids.includes(_BilanceMinus.id)) {
                      total += _BilanceMinus.price
                    } else {
                      total -= _BilanceMinus.price
                    }
                  }"
              />
            </td>

            <td>
              <IsEmptyHelper
                  :value="_BilanceMinus.paidAt"
                  if-no="span"
                  if-yes="span"
              >
                {{ _BilanceMinus.paidAt|moment('DD.MM.YYYY') }}
              </IsEmptyHelper>
            </td>

            <td>
              {{ _BilanceMinus.title }}
            </td>

            <td>
              {{ _BilanceMinus.price|currency('€') }}
            </td>

            <td>
              <IsEmptyHelper
                  :value="_BilanceMinus.pay"
                  if-no="span"
                  if-yes="span"
              >
                {{ payList[_BilanceMinus.pay]?.text }}
              </IsEmptyHelper>
            </td>
          </tr>
        </tbody>

        <tfoot
            v-if="ids.length > 0"
        >
          <tr>
            <th
                align="left"
                :colspan="3"
            />

            <th
                align="left"
            >
              {{ total|currency('€') }}
            </th>

            <th
                align="left"
            />
          </tr>
        </tfoot>
      </IsEmptyHelper>
    </v-col>
  </v-row>
</template>

<script>
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Minuses',
    props: [
      'Bilance'
    ],
    components: {
      IsEmptyHelper
    },
    data() {
      return {
        all: false,
        ids: [],
        total: 0
      }
    },
    computed: {
      payList: function () {
        return this.$store.state.core.Entity.BilanceMinus?.pay
      }
    }
  }
</script>
