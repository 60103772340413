<template>
  <SaveModal
      color="success"
      :icon="icon"
      :maxWidth="maxWidth"
      button="Nahrať"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="fileData"
      @onSave="(response) => $emit('onFile', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-alert
          v-if="errors.name || errors.type || errors.content || errors.size"
          type="error"
      >
        Súbor nie je vyplnený!
      </v-alert>

      <vue-file-upload-as-bsf
          id="file"
          label_input="📎"
          @fileLoaded="(files) => {
            const file = files[0]

            let FileForm = loadFile(file)

            if (FileForm) {
              ItemForm.name = FileForm.name

              ItemForm.type = FileForm.type

              ItemForm.content = FileForm.content

              ItemForm.size = FileForm.size
            }
          }"
      />
    </template>
  </SaveModal>
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'File',
    props: [
      'icon',
      'maxWidth',
      'setTitle',
      'fileData'
    ],
    emits: [
      'onFile'
    ],
    components: {
      SaveModal
    },
    methods: {
      setItemForm() {
        return {
          name: null,
          type: null,
          content: null,
          size: null
        }
      },
      loadFile(file) {
        let FileForm = null

        if (file) {
          FileForm = {
            name: file.name,
            type: file.type,
            content: file.data,
            size: file.data.length
          }
        }

        return FileForm
      }
    }
  }
</script>
