import Vue from 'vue'

class System {
  async clearCache() {
    return await Vue.axios
      .get('system/clear/cache')
  }
}

const system = new System()

export default system
