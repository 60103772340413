<template>
  <v-row>
    <v-col
        cols="12"
    >
      <h3>
        Položky
      </h3>

      <IsEmptyHelper
          :value="Ticket.ticketItems.length > 0"
          if-no="table"
          if-yes="p"
      >
        <thead>
          <tr>
            <th
                width="75%"
                align="left"
            >
              Názov
            </th>

            <th
                width="15%"
                align="left"
            >
              Množstvo
            </th>

            <th
                width="10%"
                align="left"
            >
              Cena
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
              v-for="(_TicketItem) in Ticket.ticketItems"
              :key="_TicketItem.id"
              valign="top"
          >
            <td>
              {{ _TicketItem.title }}

              <template
                  v-if="_TicketItem.invoicingDescription"
              >
                <br/>

                <pre>{{ _TicketItem.invoicingDescription }}</pre>
              </template>
            </td>

            <td>
              {{ _TicketItem.invoicingQuantity|number }}

              {{ _TicketItem.tariffUnit }}

              <template
                  v-if="_TicketItem.tariffFrequency"
              >
                /

                {{ _TicketItem.tariffFrequency }}
              </template>
            </td>

            <td>
              {{ _TicketItem.invoicingSubtotal|currency('€') }}
            </td>
          </tr>
        </tbody>
      </IsEmptyHelper>
    </v-col>
  </v-row>
</template>

<script>
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'InvoicingItemsInfo',
    props: [
      'Ticket'
    ],
    components: {
      IsEmptyHelper
    }
  }
</script>
