import Vue from 'vue'

class Register {
  async exist() {
    return await Vue.axios
      .get('app/register/exist')
  }
}

const register = new Register()

export default register
