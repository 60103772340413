<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          maxWidth="100%"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <ul>
                <li>
                  Prístup bol odmietnutý
                </li>

                <li>
                  Stránka nebola nájdená
                </li>
              </ul>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
            >
              <p>
                Kontaktujte nás na

                <a
                    href="mailto: helpdesk@fuumanager.sk"
                >
                  helpdesk@fuumanager.sk
                </a>
              </p>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Error',
    components: {
      BlankPage,
      CardPart
    },
    methods: {
      setTitle() {
        return 'Ups ...'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
