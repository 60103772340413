import Vue from 'vue'

import Qs from 'qs'

class TicketItemOffers {
  async indexTicketItem({ticketItemId, params}) {
    return await Vue.axios
      .get('app/ticket-item-offers/by-ticket-item/' + ticketItemId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .post('app/ticket-item-offers/by-ticket-item/' + ticketItemId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-offers/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-item-offers/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-item-offers/' + id)
  }
}

const ticketItemOffers = new TicketItemOffers()

export default ticketItemOffers
