<template>
  <GridPart
      maxWidth="calc(100% - 40px)"
      :title="title"
      :hasSearch="false"
      :hasFilter="false"
      :hasExtra="false"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="7"
      empty="Nenašli sa žiadne výplaty."
      itemsKey="wages"
      filterKey="Wage"
      defaultOrder="clo.closedAt"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="clo.closedAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Čas
      </th>

      <th>
        Výkazy
      </th>

      <th>
        Bonusy
      </th>

      <th>
        Provízie
      </th>

      <th>
        Celkovo
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td
          class="fixed"
      >
        <IsEmptyHelper
            :value="Item.Closing.closedAt"
            if-no="span"
            if-yes="span"
            empty="Neuzavreté"
        >
          {{ Item.Closing?.closedAt|moment('MM/YYYY') }}
        </IsEmptyHelper>
      </td>

      <td>
        {{ Item.timeSpendReports|number }} h
      </td>

      <td>
        {{ Item.priceSpendReports|currency('€') }}

        <template
            v-if="Item.priceSpendReportsBank || Item.priceSpendReportsCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.priceSpendReportsBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.priceSpendReportsBank|currency('€') }}
            </template>

            <template
                v-if="Item.priceSpendReportsCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.priceSpendReportsCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.priceBonuses|currency('€') }}

        <template
            v-if="Item.priceBonusesBank || Item.priceBonusesCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.priceBonusesBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.priceBonusesBank|currency('€') }}
            </template>

            <template
                v-if="Item.priceBonusesCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.priceBonusesCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.priceCommissions|currency('€') }}

        <template
            v-if="Item.priceCommissionsBank || Item.priceCommissionsCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.priceCommissionsBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.priceCommissionsBank|currency('€') }}
            </template>

            <template
                v-if="Item.priceCommissionsCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.priceCommissionsCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.total|currency('€') }}

        <template
            v-if="Item.totalBank || Item.totalCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.totalBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.totalBank|currency('€') }}
            </template>

            <template
                v-if="Item.totalCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.totalCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/wage/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Wages from '@/services/wages'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Wages',
    props: [
      'User'
    ],
    components: {
      GridPart,
      OrderList,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Výplaty'
      }
    },
    methods: {
      fetchData(params) {
        return Wages
          .indexUser({
            userId: this.User.id,
            params: params
          })
      }
    }
  }
</script>
