<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 40px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="true"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="7"
          empty="Nenašli sa žiadne audit logy."
          itemsKey="auditLogs"
          filterKey="AuditLog"
          :defaultFilters="{
            method: null,
            statusCode: null
          }"
          defaultOrder="audlog.createdAt"
          defaultBy="DESC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-row>
            <v-col
                cols="12"
                lg="3"
            >
              <SuggestIndexesModule
                  v-model="params.Filters.method"
                  :error-messages="errors.Filters?.method"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Metóda"
                  :clearable="true"
                  @change="setFilter"
                  module="audit_log_method"
              />
            </v-col>

            <v-col
                cols="12"
                lg="3"
            >
              <SuggestIndexesModule
                  v-model="params.Filters.statusCode"
                  :error-messages="errors.Filters?.statusCode"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Stavový kód"
                  :clearable="true"
                  @change="setFilter"
                  module="audit_log_status_code"
              />
            </v-col>
          </v-row>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th>
            Uri
          </th>

          <th>
            Užívateľ
          </th>

          <th>
            <OrderList
                orderKey="audlog.createdAt"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Metóda
          </th>

          <th>
            <OrderList
                orderKey="audlog.updatedAt"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Stavový kód
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item
            }"
        >
          <td>
            <a
                :href="Item.uri"
                target="_blank"
            >
              <TruncateHelper
                  :title="Item.uri"
                  maxWidth="300px"
              >
                {{ Item.uri }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.User?.name"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.User?.name"
                  maxWidth="300px"
              >
                {{ Item.User?.name }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </td>

          <td>
            {{ Item.method }}
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.updatedAt"
                if-no="span"
                if-yes="span"
            >
              {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
            </IsEmptyHelper>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.statusCode"
                if-no="span"
                if-yes="span"
            >
              {{ Item.statusCode }}
            </IsEmptyHelper>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/audit-log/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import AuditLogs from '@/services/audit-logs'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'AuditLogs',
    components: {
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper,
      SuggestIndexesModule
    },
    methods: {
      fetchData(params) {
        return AuditLogs
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Audit logy'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
