var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Provízie ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceCommissions,'€'))+" "),(_vm.Wage.priceCommissionsBank || _vm.Wage.priceCommissionsCash)?[_c('br'),_c('small',[(_vm.Wage.priceCommissionsBank)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bank-transfer ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceCommissionsBank,'€'))+" ")]:_vm._e(),(_vm.Wage.priceCommissionsCash)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceCommissionsCash,'€'))+" ")]:_vm._e()],2)]:_vm._e()],2),_c('IsEmptyHelper',{attrs:{"value":_vm.Wage.wageTicketCommissions.length > 0,"if-no":"table","if-yes":"p","no-gutters":true}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"2.5%"}},[_c('v-checkbox',{attrs:{"dense":true,"hide-details":"auto"},on:{"change":(value) => {
                  _vm.ids = []

                  _vm.total = 0

                  if (value) {
                    _vm.Wage.wageTicketCommissions.forEach((_WageTicketCommission) => {
                      _vm.ids.push(_WageTicketCommission.TicketCommission.id)

                      _vm.total += _WageTicketCommission.price
                    })
                  }
                }},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Vykonanie ")]),_c('th',{attrs:{"width":"17.5%","align":"left"}},[_vm._v(" Projekt ")]),_c('th',{attrs:{"width":"20%","align":"left"}}),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Suma ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Stav ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Uhradenie ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Platba ")]),_c('th',{attrs:{"width":"15%","align":"left"}},[_vm._v(" Účet ")])])]),_c('tbody',_vm._l((_vm.Wage.wageTicketCommissions),function(_WageTicketCommission){return _c('tr',{key:_WageTicketCommission.TicketCommission.id,attrs:{"valign":"top"}},[_c('td',[_c('v-checkbox',{attrs:{"value":_WageTicketCommission.TicketCommission.id,"dense":true,"hide-details":"auto"},on:{"change":() => {
                  if (_vm.ids.length === _vm.Wage.wageTicketCommissions.length) {
                    _vm.all = true
                  } else {
                    _vm.all = false
                  }

                  if (_vm.ids.includes(_WageTicketCommission.TicketCommission.id)) {
                    _vm.total += _WageTicketCommission.price
                  } else {
                    _vm.total -= _WageTicketCommission.price
                  }
                }},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_WageTicketCommission.workedAt,'DD.MM.YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_WageTicketCommission.currentProjectTitle)+" ")]),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_WageTicketCommission.price,'€'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.statusList[_WageTicketCommission.status]?.text)+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketCommission.paidAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_WageTicketCommission.paidAt,'DD.MM.YYYY'))+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketCommission.pay,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm.payList[_WageTicketCommission.pay]?.text)+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketCommission.accountBillingName,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_WageTicketCommission.accountBillingName)+" ")])],1)])}),0),(_vm.ids.length > 0)?_c('tfoot',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_c('UpdateMassTicketCommission',{attrs:{"x-small":true,"closingId":_vm.Wage.Closing.id,"ids":_vm.ids},on:{"onUpdateMass":function($event){return _vm.$emit('refresh')}}})],1),_c('th',{attrs:{"align":"left","colspan":3}}),_c('th',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total,'€'))+" ")]),_c('th',{attrs:{"align":"left","colspan":4}})])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }