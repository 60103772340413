<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Client"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <h3>
            1. Fakturačné údaje
          </h3>
        </v-col>

        <v-col
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.billingOnCompany"
              :error-messages="errors.keyOffice"
              :dense="true"
              hide-details="auto"
              label="Fakturovať na firmu"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.billingName"
              :error-messages="errors.billingName"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Spoločnosť"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.billingSign"
              :error-messages="errors.billingSign"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="V zastúpení"
          />
        </v-col>

        <template
            v-if="ItemForm.billingOnCompany"
        >
          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingBusinessId"
                :error-messages="errors.billingBusinessId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="IČO"
            />
          </v-col>

          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingTaxId"
                :error-messages="errors.billingTaxId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="DIČ"
            />
          </v-col>

          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingVatId"
                :error-messages="errors.billingVatId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="IČ DPH"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.billingStreet"
              :error-messages="errors.billingStreet"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Ulica"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.billingCity"
              :error-messages="errors.billingCity"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Mesto"
          />
        </v-col>

        <v-col
            cols="12"
            lg="2"
        >
          <v-text-field
              v-model="ItemForm.billingZip"
              :error-messages="errors.billingZip"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="PSČ"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <SuggestIndexesModule
              v-model="ItemForm.billingCountry"
              :error-messages="errors.billingCountry"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Krajina"
              :clearable="true"
              module="general_billing_country"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <h3>
            2. Kontaktné údaje
          </h3>
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.contactName"
              :error-messages="errors.contactName"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Meno"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.contactEmail"
              :error-messages="errors.contactEmail"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.contactPhone"
              :error-messages="errors.contactPhone"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Telefón"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Clients from '@/services/clients'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestIndexesModule
    },
    methods: {
      fetchData() {
        return Clients
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť klienta "' + Item.billingName + '"'
      },
      setItemForm(Item) {
        return {
          billingOnCompany: Item.billingOnCompany,
          billingName: Item.billingName,
          billingSign: Item.billingSign,
          billingBusinessId: Item.billingBusinessId,
          billingTaxId: Item.billingTaxId,
          billingVatId: Item.billingVatId,
          billingStreet: Item.billingStreet,
          billingCity: Item.billingCity,
          billingZip: Item.billingZip,
          billingCountry: Item.billingCountry,
          contactName: Item.contactName,
          contactEmail: Item.contactEmail,
          contactPhone: Item.contactPhone
        }
      },
      updateData(ItemForm) {
        return Clients
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
