<template>
  <SaveModal
      color="red"
      icon="mdi-close"
      :maxWidth="500"
      button="Odmietnuť"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="rejectData"
      @onSave="onReject"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.pricingFeedback"
              :error-messages="errors.pricingFeedback"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dôvod"
              :rows="5"
          />
        </v-col>
      </v-row>
    </template>
  </SaveModal>
</template>

<script>
  import TicketPricing from '@/services/ticket-pricing'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Reject',
    props: [
      'pricingToken'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        token: null
      }
    },
    methods: {
      fetchData() {
        return TicketPricing
          .exist({
            pricingToken: this.pricingToken
          })
      },
      setTitle(Item) {
        return 'Odmietnuť cenovú ponuku č.' + Item.id
      },
      setItemForm(Item) {
        this.token = Item.token

        return {
          pricingFeedback: null
        }
      },
      rejectData(ItemForm) {
        return TicketPricing
          .reject({
            pricingToken: this.pricingToken,
            data: ItemForm
          })
      },
      onReject() {
        setTimeout(() => {
          window.location.assign(this.$global.axiosUrl() + 'tickets/' + this.token + '/generate/pricing')
        }, 100)
      }
    }
  }
</script>
