<template>
  <SaveModal
      color="success"
      icon="mdi-plus"
      :maxWidth="maxWidth"
      button="Vytvoriť"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="createData"
      @onSave="(response) => $emit('onCreate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <slot
          name="default"
          :errors="errors"
          :ItemForm="ItemForm"
      />
    </template>
  </SaveModal>
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Create',
    props: [
      'maxWidth',
      'setTitle',
      'setItemForm',
      'createData'
    ],
    emits: [
      'onCreate'
    ],
    components: {
      SaveModal
    }
  }
</script>
