<template>
  <CreateSave
      :maxWidth="750"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.description"
              :error-messages="errors.description"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Popis"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <v-text-field
              v-model="ItemForm.price"
              :error-messages="errors.price"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Sadzba"
              type="number"
              suffix="€"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <SuggestIndexesModule
              v-model="ItemForm.frequency"
              :error-messages="errors.frequency"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Frekvencia"
              :clearable="true"
              module="tariff_frequency"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <SuggestIndexesModule
              v-model="ItemForm.unit"
              :error-messages="errors.unit"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Jednotka"
              :clearable="true"
              module="tariff_unit"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-select
              v-model="ItemForm.type"
              :error-messages="errors.type"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Typ"
              :items="types"
          />
        </v-col>

        <v-col
            v-if="ItemForm.type === typeList.simple?.value"
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.minProfit"
              :error-messages="errors.minProfit"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Minimálny zárobok"
              type="number"
              suffix="%"
          />
        </v-col>

        <template
            v-if="ItemForm.type === typeList.group?.value"
        >
          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.priceOffers"
                :error-messages="errors.priceOffers"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Cena práce"
                type="number"
                suffix="€"
            />
          </v-col>

          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.timeOffers"
                :error-messages="errors.timeOffers"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Čas práce"
                type="number"
                suffix="h"
            />
          </v-col>

          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.maxPriceHour"
                :error-messages="errors.maxPriceHour"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Maximálny plat"
                type="number"
                suffix="€ /h"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import Tariffs from '@/services/tariffs'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Create',
    emits: [
      'onCreate'
    ],
    components: {
      CreateSave,
      SuggestIndexesModule
    },
    computed: {
      typeList: function () {
        return this.$store.state.core.Entity.Tariff?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť tarifu'
      },
      setItemForm() {
        return {
          title: null,
          description: null,
          price: 0,
          frequency: null,
          unit: null,
          type: this.typeList.simple?.value,
          minProfit: 0,
          timeOffers: 0,
          priceOffers: 0,
          maxPriceHour: 0
        }
      },
      createData(ItemForm) {
        return Tariffs
          .create({
            data: ItemForm
          })
      }
    }
  }
</script>
