<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/bilances"
      itemKey="Bilance"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <Pluses
              :Bilance="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <Minuses
              :Bilance="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <Wages
              :Bilance="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Bilances from '@/services/bilances'

  import Pluses from '@/components/Bilance/Pluses'
  import Minuses from '@/components/Bilance/Minuses'
  import Wages from '@/components/Bilance/Wages'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'Bilance',
    components: {
      Pluses,
      Minuses,
      Wages,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return Bilances
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        if (Item.Closing.closedAt) {
          return this.$moment(Item.Closing?.closedAt).format('MM/YYYY')
        } else {
          return 'Neuzavreté'
        }
      },
      setBreadcrumbs(Item) {
        return [
          {
            text: 'Financie',
            to: '/finance'
          },
          {
            text: Item.Account.billingName,
            to: '/finance/' + Item.Account.id
          }
        ]
      }
    }
  }
</script>
