<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <TruncateHelper
            :title="title"
            :maxWidth="maxWidth"
        >
          <v-icon
              :color="color"
          >
            {{ icon }}
          </v-icon>

          {{ title }}
        </TruncateHelper>

        <slot
            name="action"
        />
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <slot
            name="default"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Panel',
    props: [
      'maxWidth',
      'color',
      'icon',
      'title'
    ],
    components: {
      TruncateHelper
    }
  }
</script>
