<template>
  <span
      :title="title"
      class="text-truncate"
      :style="{
        maxWidth: maxWidth
      }"
  >
    <slot/>
  </span>
</template>

<script>
  export default {
    name: 'Truncate',
    props: [
      'title',
      'maxWidth'
    ]
  }
</script>
