<template>
  <v-form
      @submit.stop.prevent="submit"
  >
    <slot
        name="default"
        :loading="loading"
        :errors="errors"
        :ItemForm="ItemForm"
        :submit="submit"
    />
  </v-form>
</template>

<script>
  export default {
    name: 'Form',
    props: [
      'Item',
      'setItemForm',
      'submitData'
    ],
    emits: [
      'onSuccess',
      'onErrors',
      'onFail'
    ],
    data() {
      return {
        loading: false,
        errors: {},
        ItemForm: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.errors = {}

        this.ItemForm = this.setItemForm(this.Item)
      },
      async submit() {
        this.loading = true

        this.errors = {}

        await this.submitData(this.ItemForm)
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.errors = error.response.data.errors

              this.$emit('onErrors', error.response)
            } else {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
