import Vue from 'vue'

import Vue2Filters from 'vue2-filters'

const Vue2FiltersConfig = {
  number: {
    format: '0.00',
    thousandsSeparator: ' ',
    decimalSeparator: ','
  },
  percent: {
    decimalDigits: 2,
    multiplier: 1,
    decimalSeparator: ','
  },
  currency: {
    symbol: '',
    decimalDigits: 2,
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  }
}

Vue.use(Vue2Filters, Vue2FiltersConfig)
