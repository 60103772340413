var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":_vm.title,"hasSearch":true,"hasFilter":false,"hasExtra":true,"standardClass":() => {},"extraClass":() => {},"colspan":3,"empty":"Nenašli sa žiadne podiely.","itemsKey":"ticketShares","filterKey":"TicketShare","defaultOrder":"ticsha.amount","defaultBy":"DESC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
        init
      }){return [(_vm.Ticket.ticketShareCreatable)?[_c('CreateTicket',{attrs:{"ticketId":_vm.Ticket.id},on:{"onCreateTicket":(response) => {
            init()

            _vm.$emit('refresh', response)
          }}}),_vm._v("   ")]:_vm._e()]}},{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"list-head",fn:function({
        order,
        by,
        orderList,
        byList,
        setOrderBy
      }){return [_c('th',{staticClass:"fixed"},[_vm._v(" Užívateľ ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"ticsha.amount","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
        Item,
        init
      }){return [_c('td',{staticClass:"fixed"},[_c('TruncateHelper',{attrs:{"title":Item.User.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.User.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("percent")(Item.amount))+" ")]),_c('td',{staticClass:"text-right fixed"},[(Item.updatable)?[_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":(response) => {
              init()

              _vm.$emit('refresh', response)
            }}})]:_vm._e(),(Item.deletable)?[_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":(response) => {
              init()

              _vm.$emit('refresh', response)
            }}})]:_vm._e()],2)]}},{key:"list-body-extra",fn:function({
        Item
      }){return [_c('td',{attrs:{"colspan":3}},[_c('IsEmptyHelper',{attrs:{"value":Item.note,"if-no":"i","if-yes":"i","empty":"bez poznámky"}},[_vm._v(" "+_vm._s(Item.note)+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }