<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="true"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="4"
      empty="Nenašli sa žiadne príjmy."
      itemsKey="ticketRevenues"
      filterKey="TicketRevenue"
      defaultOrder="rev.paidAt"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
      >
        <template
            v-if="Ticket.ticketRevenueCreatable"
        >
          <CreateTicket
              :ticketId="Ticket.id"
              @onCreateTicket="(response) => {
                init()

                $emit('refresh', response)
              }"
          />

          &nbsp;
        </template>
      </template>
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="rev.paidAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Názov
      </th>

      <th>
        Čiastka
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        {{ Item.Revenue.paidAt|moment('DD.MM.YYYY') }}
      </td>

      <td>
        <TruncateHelper
            :title="Item.Revenue.title"
            maxWidth="300px"
        >
          {{ Item.Revenue.title }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.pricePart|currency('€') }}
      </td>

      <td
          class="text-right fixed"
      >
        <template
            v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
        >
          <template
              v-if="Item.deletable"
          >
            <Delete
                :id="Item.id"
                @onDelete="(response) => {
                  init()

                  $emit('refresh', response)
                }"
            />
          </template>
        </template>
      </td>
    </template>

    <template
        v-slot:list-body-extra="{
          Item
        }"
    >
      <td
          :colspan="4"
      >
        <IsEmptyHelper
            :value="Item.note"
            if-no="i"
            if-yes="i"
            empty="bez poznámky"
        >
          {{ Item.note }}
        </IsEmptyHelper>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import TicketRevenues from '@/services/ticket-revenues'

  import CreateTicket from '@/components/TicketRevenues/CreateTicket'
  import Delete from '@/components/TicketRevenues/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketRevenues',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateTicket,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Príjmy'
      }
    },
    methods: {
      fetchData(params) {
        return TicketRevenues
          .indexTicket({
            ticketId: this.Ticket.id,
            params: params
          })
      }
    }
  }
</script>
