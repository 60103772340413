import Vue from 'vue'

export const global = {
  publicHost() {
    return process.env.VUE_APP_PUBLIC_HOST
  },
  publicPath() {
    return process.env.VUE_APP_PUBLIC_PATH
  },
  publicUrl() {
    return this.publicHost() + this.publicPath()
  },
  title() {
    return process.env.VUE_APP_TITLE
  },
  logo() {
    return process.env.VUE_APP_LOGO
  },
  style() {
    return process.env.VUE_APP_STYLE
  },
  version() {
    return process.env.VUE_APP_VERSION
  },
  axiosUrl() {
    return process.env.VUE_APP_AXIOS_URL
  }
}

Vue.prototype.$global = global
