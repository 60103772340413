import Vue from 'vue'

import Qs from 'qs'

class TicketItems {
  async suggestTicket({ticketId}) {
    return await Vue.axios
      .get('app/ticket-items/suggest/by-ticket/' + ticketId)
  }

  async indexTicket({ticketId, params}) {
    return await Vue.axios
      .get('app/ticket-items/by-ticket/' + ticketId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexUser({userId, params}) {
    return await Vue.axios
      .get('app/ticket-items/by-user/' + userId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('app/ticket-items/by-ticket/' + ticketId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-items/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id, data)
  }

  async updateTask({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/task', data)
  }

  async updateStatus({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/status', data)
  }

  async updateSettings({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/settings', data)
  }

  async updateAlert({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/alert', data)
  }

  async updateAssignment({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/assignment', data)
  }

  async updatePricing({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/pricing', data)
  }

  async updateInvoicing({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/' + id + '/invoicing', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-items/' + id)
  }

  async uploadFile({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/ticket-items/' + id + '/upload/file', data)
    } else {
      return await Vue.axios
        .post('app/ticket-items/upload/file', data)
    }
  }
}

const ticketItems = new TicketItems()

export default ticketItems
