import Vue from 'vue'

import Qs from 'qs'

class TicketCommissions {
  async indexTicket({ticketId, params}) {
    return await Vue.axios
      .get('app/ticket-commissions/by-ticket/' + ticketId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('app/ticket-commissions/by-ticket/' + ticketId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-commissions/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-commissions/' + id, data)
  }

  async updateMass({data}) {
    return await Vue.axios
      .put('app/ticket-commissions/mass', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-commissions/' + id)
  }
}

const ticketCommissions = new TicketCommissions()

export default ticketCommissions
