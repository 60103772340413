<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          maxWidth="100%"
          :title="title"
      >
        <template
            v-slot:default
        >
          <Form/>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import Form from '@/components/Login/Form'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Login',
    components: {
      Form,
      BlankPage,
      CardPart
    },
    methods: {
      setTitle() {
        return 'Prihlásiť sa'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
