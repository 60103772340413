var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PagePart',{attrs:{"itemKey":_vm.itemKey,"fetchData":_vm.fetchData,"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},on:{"onFail":_vm.onFail},scopedSlots:_vm._u([{key:"default",fn:function({
        loading,
        Item,
        title,
        backLink,
        refresh
      }){return [_c('CardPart',{attrs:{"maxWidth":_vm.maxWidth,"title":title},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-spacer'),_vm._t("action",null,{"Item":Item,"refresh":refresh}),(_vm.hasBackLink)?[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":backLink,"exact":true}},[_c('v-icon',[_vm._v(" mdi-history ")])],1),_vm._v("   ")]:_vm._e(),_c('v-btn',{attrs:{"icon":true,"outlined":true,"loading":loading,"disabled":loading,"color":"default"},on:{"click":refresh}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]},proxy:true},{key:"default",fn:function(){return [(loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_vm._t("default",null,{"Item":Item,"refresh":refresh})]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }