<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="true"
      :hasExtra="false"
      :standardClass="(Item) => {
        return statusList[Item.status]?.class
      }"
      :extraClass="() => {}"
      :colspan="15"
      empty="Nenašli sa žiadne položky."
      itemsKey="ticketItems"
      filterKey="TicketItem"
      :defaultFilters="{
        status: null
      }"
      defaultOrder="ticite.sort"
      defaultBy="ASC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <template
            v-if="Ticket.ticketItemCreatable"
        >
          <CreateTicket
              :ticketId="Ticket.id"
              @onCreateTicket="(response) => {
                init()

                $emit('refresh', response)
              }"
          />

          &nbsp;
        </template>
      </template>
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:filter="{
          params,
          errors,
          setFilter
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="3"
        >
          <v-select
              v-model="params.Filters.status"
              :error-messages="errors.Filters?.status"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Stav"
              :items="statuses"
              :clearable="true"
              @change="setFilter"
          />
        </v-col>
      </v-row>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th>
        Tarifa
      </th>

      <th
          class="fixed"
      >
        Názov
      </th>

      <th>
        Stav
      </th>

      <th>
        <OrderList
            orderKey="ticite.deadlineAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        <OrderList
            orderKey="ticite.priority"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Nacenený čas
      </th>

      <th>
        Odpracovaný čas
      </th>

      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <th>
          Nacenená suma
        </th>

        <th>
          Odpracovaná suma
        </th>
      </template>

      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <th>
          Cenová ponuka
        </th>

        <th>
          Fakturácia
        </th>
      </template>

      <th>
        <OrderList
            orderKey="ticite.sort"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Vedúci
      </th>

      <th>
        Pracovník
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td>
        <TruncateHelper
            :title="Item.currentTariffTitle"
            maxWidth="300px"
        >
          {{ Item.currentTariffTitle }}
        </TruncateHelper>
      </td>

      <td
          class="fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.status"
            if-no="span"
            if-yes="span"
        >
          {{ statusList[Item.status]?.text }}
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.deadlineAt"
            if-no="span"
            if-yes="span"
        >
          <v-icon
              v-if="Item.alertDeadlineAt && Item.printableAlert"
              color="error"
          >
            mdi-alert
          </v-icon>

          {{ Item.deadlineAt|moment('DD.MM.YYYY') }}
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.priority"
            if-no="span"
            if-yes="span"
        >
          {{ Item.priority }} úroveň
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.printablePricing"
            if-no="span"
            if-yes="span"
        >
          {{ Item.timeEstimationOffers|number }} h
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.printableInvoicing"
            if-no="span"
            if-yes="span"
        >
          <v-icon
              v-if="Item.alertTimeSpend && Item.printableAlert"
              color="error"
          >
            mdi-alert
          </v-icon>

          {{ Item.timeSpendReports|number }} h / {{ Item.maxTimeSpend|number }} h
        </IsEmptyHelper>
      </td>

      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <td>
          <IsEmptyHelper
              :value="Item.printablePricing"
              if-no="span"
              if-yes="span"
          >
            {{ Item.priceEstimationOffers|currency('€') }}
          </IsEmptyHelper>
        </td>

        <td>
          <IsEmptyHelper
              :value="Item.printableInvoicing"
              if-no="span"
              if-yes="span"
          >
            {{ Item.priceSpendReports|currency('€') }}
          </IsEmptyHelper>
        </td>
      </template>

      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <td>
          <IsEmptyHelper
              :value="Item.printablePricing"
              if-no="span"
              if-yes="span"
          >
            {{ Item.pricingSubtotal|currency('€') }}
          </IsEmptyHelper>
        </td>

        <td>
          <IsEmptyHelper
              :value="Item.printableInvoicing"
              if-no="span"
              if-yes="span"
          >
            {{ Item.invoicingSubtotal|currency('€') }}
          </IsEmptyHelper>
        </td>
      </template>

      <td>
        {{ Item.sort }}
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.Leader?.name"
            if-no="span"
            if-yes="span"
        >
          <TruncateHelper
              :title="Item.Leader?.name"
              maxWidth="300px"
          >
            {{ Item.Leader?.name }}
          </TruncateHelper>
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.Worker?.name"
            if-no="span"
            if-yes="span"
        >
          <TruncateHelper
              :title="Item.Worker?.name"
              maxWidth="300px"
          >
            {{ Item.Worker?.name }}
          </TruncateHelper>
        </IsEmptyHelper>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/ticket-item/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>

          <v-badge
              color="error"
              :value="Item.alertTasks"
              :content="Item.undoneTasks"
          />
        </v-btn>

        <template
            v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
        >
          <template
              v-if="Item.updatable"
          >
            &nbsp;

            <Update
                :id="Item.id"
                @onUpdate="(response) => {
                  init()

                  $emit('refresh', response)
                }"
            />
          </template>

          <template
              v-if="Item.deletable"
          >
            &nbsp;

            <Delete
                :id="Item.id"
                @onDelete="(response) => {
                  init()

                  $emit('refresh', response)
                }"
            />
          </template>
        </template>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import CreateTicket from '@/components/TicketItems/CreateTicket'
  import Update from '@/components/TicketItems/Update'
  import Delete from '@/components/TicketItems/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItems',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateTicket,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Položky'
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItem?.status
      },
      statuses: function () {
        return Object.values(this.statusList)
      }
    },
    methods: {
      fetchData(params) {
        return TicketItems
          .indexTicket({
            ticketId: this.Ticket.id,
            params: params
          })
      }
    }
  }
</script>
