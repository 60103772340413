<template>
  <SignatureSave
      :maxWidth="500"
      :setTitle="setTitle"
      :signatureData="signatureData"
      width="100%"
      height="150px"
      @onSignature="(response) => $emit('onSignature', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Accounts from '@/services/accounts'

  import SignatureSave from '@/components/_Part/_Modal/_Save/Signature'

  export default {
    name: 'Signature',
    props: [
      'id'
    ],
    emits: [
      'onSignature'
    ],
    components: {
      SignatureSave
    },
    methods: {
      setTitle() {
        return 'Podpis'
      },
      signatureData(ItemForm) {
        return Accounts
          .uploadSignature({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
