<template>
  <ConfirmModal
      color="primary"
      icon="mdi-skip-next"
      :maxWidth="500"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="protocolSkipAndConfirmData"
      @onConfirm="(response) => $emit('onProtocolSkipAndConfirm', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Tickets from '@/services/tickets'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'ProtocolSkipAndConfirm',
    props: [
      'id'
    ],
    emits: [
      'onProtocolSkipAndConfirm'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Preskočiť a potvrdiť protokol č.' + Item.id
      },
      setQuestion(Item) {
        return 'Naozaj chcete preskočiť a potvrdiť protokol č.' + Item.id + '?'
      },
      protocolSkipAndConfirmData() {
        return Tickets
          .protocolSkipAndConfirm({
            id: this.id
          })
      }
    }
  }
</script>
