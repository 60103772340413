<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="TicketBonus"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.workedAt"
              :error-messages="errors.workedAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum vykonania"
              type="date"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.price"
              :error-messages="errors.price"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Suma"
              type="number"
              suffix="€"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.note"
              :error-messages="errors.note"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import TicketBonuses from '@/services/ticket-bonuses'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave
    },
    methods: {
      fetchData() {
        return TicketBonuses
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť bonus z ' + this.$moment(Item.workedAt).format('DD.MM.YYYY') + ' pre ' + Item.User.name
      },
      setItemForm(Item) {
        return {
          workedAt: this.$moment(Item.workedAt).format('YYYY-MM-DD'),
          price: Item.price,
          note: Item.note
        }
      },
      updateData(ItemForm) {
        return TicketBonuses
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
