var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":_vm.title,"hasSearch":true,"hasFilter":false,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":5,"empty":"Nenašli sa žiadne notifikácie.","itemsKey":"notifications","filterKey":"Notification","defaultOrder":"custom","defaultBy":"CUSTOM","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","href":_vm.$global.axiosUrl() + 'app/notification/visit/auth'}},[_c('v-icon',[_vm._v(" mdi-share ")])],1),_vm._v("   ")]},proxy:true},{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"list-head",fn:function(){return [_c('th',{staticClass:"fixed"},[_vm._v(" Správa ")]),_c('th',[_vm._v(" Stav ")]),_c('th',[_vm._v(" Dátum a čas upozornenia ")]),_c('th',[_vm._v(" Dátum a čas navštívenia ")]),_c('th',{staticClass:"text-right fixed"})]},proxy:true},{key:"list-body-standard",fn:function({
        Item
      }){return [_c('td',{staticClass:"fixed"},[_vm._v(" "+_vm._s(Item.message)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.statusList[Item.status]?.text)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.updatedAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","href":_vm.$global.axiosUrl() + 'app/notification/visit/auth/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-share ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }