<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="TicketItemReport"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestTicketsOnlyStatusInProgress
              v-model="Ticket"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Tiket"
              :clearable="true"
              @change="() => {
                ItemForm.TicketItem = null
              }"
          />
        </v-col>

        <v-col
            v-if="Ticket"
            :key="Ticket"
            cols="12"
        >
          <SuggestTicketItemsTicket
              v-model="ItemForm.TicketItem"
              :error-messages="errors.TicketItem"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Položka"
              :clearable="true"
              :ticketId="Ticket"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import TicketItemReports from '@/services/ticket-item-reports'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestTicketsOnlyStatusInProgress from '@/components/Tickets/SuggestOnlyStatusInProgress'
  import SuggestTicketItemsTicket from '@/components/TicketItems/SuggestTicket'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestTicketsOnlyStatusInProgress,
      SuggestTicketItemsTicket
    },
    data() {
      return {
        Ticket: null
      }
    },
    methods: {
      fetchData() {
        return TicketItemReports
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť výkaz z ' + this.$moment(Item.workedAt).format('DD.MM.YYYY') + ' od ' + Item.User.name
      },
      setItemForm(Item) {
        this.Ticket = Item.TicketItem.Ticket.id

        return {
          TicketItem: Item.TicketItem.id
        }
      },
      updateData(ItemForm) {
        return TicketItemReports
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
