<template>
  <FormPart
      :Item="Ticket"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestUsersRole
              v-model="ItemForm.Owner"
              :error-messages="errors.Owner"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Vlastník"
              :clearable="true"
              role="ROLE_WORKER"
              :createButton="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
              @change="submit"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <SuggestUsersRole
              v-model="ItemForm.Seller"
              :error-messages="errors.Seller"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Obchodník"
              :clearable="true"
              role="ROLE_SELLER"
              :createButton="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
              @change="submit"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <SuggestUsersRole
              v-model="ItemForm.Manager"
              :error-messages="errors.Manager"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Manažér"
              :clearable="true"
              role="ROLE_MANAGER"
              :createButton="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Tickets from '@/services/tickets'

  import FormPart from '@/components/_Part/Form'

  import SuggestUsersRole from '@/components/Users/SuggestRole'

  export default {
    name: 'FormAssignment',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart,
      SuggestUsersRole
    },
    methods: {
      setItemForm(Item) {
        return {
          Owner: Item.Owner?.id,
          Seller: Item.Seller?.id,
          Manager: Item.Manager?.id
        }
      },
      submitData(ItemForm) {
        return Tickets
          .updateAssignment({
            id: this.Ticket.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/tickets')
      }
    }
  }
</script>
