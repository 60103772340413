import Vue from 'vue'

import Qs from 'qs'

class TicketItemsAuth {
  async index({params}) {
    return await Vue.axios
      .get('app/ticket-items/auth', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-items/auth/' + id)
  }

  async updateStatus({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/auth/' + id + '/status', data)
  }

  async updateAssignment({id, data}) {
    return await Vue.axios
      .put('app/ticket-items/auth/' + id + '/assignment', data)
  }
}

const ticketItemsAuth = new TicketItemsAuth()

export default ticketItemsAuth
