<template>
  <FormPart
      :Item="TicketItem"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.maxTimeSpend"
              :error-messages="errors.maxTimeSpend"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Maximálny odpracovaný čas"
              type="number"
              suffix="h"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormAlert',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          maxTimeSpend: Item.maxTimeSpend
        }
      },
      submitData(ItemForm) {
        return TicketItems
          .updateAlert({
            id: this.TicketItem.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/ticket/' + this.TicketItem.Ticket.id)
      }
    }
  }
</script>
