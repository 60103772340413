<template>
  <v-row>
    <v-col
        cols="12"
    >
      <slot
          name="default"
          :setSearch="setSearch"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Search',
    emits: [
      'setParams'
    ],
    methods: {
      setSearch() {
        this.$emit('setParams', {
          page: 1
        })
      }
    }
  }
</script>
