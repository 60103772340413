import Vue from 'vue'

import Qs from 'qs'

class Tickets {
  async suggestOnlyStatusInProgress() {
    return await Vue.axios
      .get('app/tickets/suggest/only-status-in-progress')
  }

  async index({params}) {
    return await Vue.axios
      .get('app/tickets', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/tickets', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/tickets/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id, data)
  }

  async updateTask({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/task', data)
  }

  async updateAlert({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/alert', data)
  }

  async updateSettings({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/settings', data)
  }

  async updateAssignment({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/assignment', data)
  }

  async pricingSkipAndAccept({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/pricing/skip-and-accept', data)
  }

  async pricingAccept({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/pricing/accept', data)
  }

  async pricingReject({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/pricing/reject', data)
  }

  async protocolSkipAndConfirm({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/protocol/skip-and-confirm', data)
  }

  async protocolConfirm({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/protocol/confirm', data)
  }

  async close({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/close', data)
  }

  async restore({id, data}) {
    return await Vue.axios
      .put('app/tickets/' + id + '/restore', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/tickets/' + id)
  }

  async duplicate({id, data}) {
    return await Vue.axios
      .post('app/tickets/' + id + '/duplicate', data)
  }

  async uploadFile({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/tickets/' + id + '/upload/file', data)
    } else {
      return await Vue.axios
        .post('app/tickets/upload/file', data)
    }
  }
}

const tickets = new Tickets()

export default tickets
