var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Výkazy ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceSpendReports,'€'))+" "),(_vm.Wage.priceSpendReportsBank || _vm.Wage.priceSpendReportsCash)?[_c('br'),_c('small',[(_vm.Wage.priceSpendReportsBank)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bank-transfer ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceSpendReportsBank,'€'))+" ")]:_vm._e(),(_vm.Wage.priceSpendReportsCash)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Wage.priceSpendReportsCash,'€'))+" ")]:_vm._e()],2)]:_vm._e()],2),_c('IsEmptyHelper',{attrs:{"value":_vm.Wage.wageTicketItemReports.length > 0,"if-no":"table","if-yes":"p","no-gutters":true}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"2.5%"}},[_c('v-checkbox',{attrs:{"dense":true,"hide-details":"auto"},on:{"change":(value) => {
                  _vm.ids = []

                  _vm.totalTime = 0

                  _vm.totalPrice = 0

                  if (value) {
                    _vm.Wage.wageTicketItemReports.forEach((_WageTicketItemReport) => {
                      _vm.ids.push(_WageTicketItemReport.TicketItemReport.id)

                      _vm.totalTime += _WageTicketItemReport.timeSpend

                      _vm.totalPrice += _WageTicketItemReport.priceSpend
                    })
                  }
                }},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Vykonanie ")]),_c('th',{attrs:{"width":"17.5%","align":"left"}},[_vm._v(" Projekt ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Čas ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Plat ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Suma ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Stav ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Uhradenie ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Platba ")]),_c('th',{attrs:{"width":"15%","align":"left"}},[_vm._v(" Účet ")])])]),_c('tbody',_vm._l((_vm.Wage.wageTicketItemReports),function(_WageTicketItemReport){return _c('tr',{key:_WageTicketItemReport.TicketItemReport.id,attrs:{"valign":"top"}},[_c('td',[_c('v-checkbox',{attrs:{"value":_WageTicketItemReport.TicketItemReport.id,"dense":true,"hide-details":"auto"},on:{"change":() => {
                  if (_vm.ids.length === _vm.Wage.wageTicketItemReports.length) {
                    _vm.all = true
                  } else {
                    _vm.all = false
                  }

                  if (_vm.ids.includes(_WageTicketItemReport.TicketItemReport.id)) {
                    _vm.totalTime += _WageTicketItemReport.timeSpend

                    _vm.totalPrice += _WageTicketItemReport.priceSpend
                  } else {
                    _vm.totalTime -= _WageTicketItemReport.timeSpend

                    _vm.totalPrice -= _WageTicketItemReport.priceSpend
                  }
                }},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_WageTicketItemReport.workedAt,'DD.MM.YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_WageTicketItemReport.currentProjectTitle)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(_WageTicketItemReport.timeSpend))+" h ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_WageTicketItemReport.priceHour,'€ / h'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_WageTicketItemReport.priceSpend,'€'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.statusList[_WageTicketItemReport.status]?.text)+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketItemReport.paidAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_WageTicketItemReport.paidAt,'DD.MM.YYYY'))+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketItemReport.pay,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm.payList[_WageTicketItemReport.pay]?.text)+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_WageTicketItemReport.accountBillingName,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_WageTicketItemReport.accountBillingName)+" ")])],1)])}),0),(_vm.ids.length > 0)?_c('tfoot',[_c('tr',[_c('th',{attrs:{"align":"left","colspan":3}}),_c('th',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.totalTime))+" h ")]),_c('th',{attrs:{"align":"left"}}),_c('th',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalPrice,'€'))+" ")]),_c('th',{attrs:{"align":"left","colspan":4}})])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }