<template>
  <NotBlankPage
      maxWidth="calc(100% - 160px)"
      :hasBackLink="true"
      failLink="/clients"
      itemKey="Client"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="refresh"
      />

      &nbsp;

      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
      >
        <Delete
            :id="Item.id"
            @onDelete="onDelete"
        />

        &nbsp;
      </template>
    </template>

    <template
        v-slot:default="{
          Item,
          refresh
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <Projects
              :Client="Item"
              @refresh="refresh"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Clients from '@/services/clients'

  import Update from '@/components/Clients/Update'
  import Delete from '@/components/Clients/Delete'
  import Projects from '@/components/Client/Projects'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'Client',
    components: {
      Update,
      Delete,
      Projects,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return Clients
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.billingName
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Klienti',
            to: '/clients'
          }
        ]
      },
      onDelete() {
        setTimeout(() => {
          this.$router.push('/projects')
        }, 100)
      }
    }
  }
</script>
