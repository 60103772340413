<template>
  <CreateSave
      :maxWidth="500"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreateTicket', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestExpensesOnlyAlertBalance
              v-model="ItemForm.Expense"
              :error-messages="errors.Expense"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Výdaj"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.pricePart"
              :error-messages="errors.pricePart"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Čiastka"
              type="number"
              suffix="€"
          />
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import TicketExpenses from '@/services/ticket-expenses'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import SuggestExpensesOnlyAlertBalance from '@/components/Expenses/SuggestOnlyAlertBalance'

  export default {
    name: 'CreateTicket',
    props: [
      'ticketId'
    ],
    emits: [
      'onCreateTicket'
    ],
    components: {
      CreateSave,
      SuggestExpensesOnlyAlertBalance
    },
    methods: {
      setTitle() {
        return 'Vytvoriť výdaj'
      },
      setItemForm() {
        return {
          Expense: null,
          pricePart: 0
        }
      },
      createData(ItemForm) {
        return TicketExpenses
          .createTicket({
            ticketId: this.ticketId,
            data: ItemForm
          })
      }
    }
  }
</script>
