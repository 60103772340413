<template>
  <v-main
      class="custom-main custom-main-document"
  >
    <v-container>
      <v-row>
        <v-col
            lg="1"
            class="d-none d-lg-block"
        />

        <v-col
            cols="12"
            lg="10"
        >
          <router-view/>
        </v-col>

        <v-col
            lg="1"
            class="d-none d-lg-block"
        />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'PageDocument'
  }
</script>
