var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SaveModal',_vm._b({attrs:{"color":"green","icon":"mdi-check","maxWidth":500,"button":"Prijať","itemKey":"Ticket","fetchData":_vm.fetchData,"setTitle":_vm.setTitle,"setItemForm":_vm.setItemForm,"saveData":_vm.acceptData},on:{"onSave":_vm.onAccept},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        errors,
        ItemForm
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"error-messages":errors.pricingFeedback,"filled":true,"dense":true,"hide-details":"auto","label":"Poznámka","rows":5},model:{value:(ItemForm.pricingFeedback),callback:function ($$v) {_vm.$set(ItemForm, "pricingFeedback", $$v)},expression:"ItemForm.pricingFeedback"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-input',{attrs:{"error-messages":errors.PricingClientSignature,"filled":true,"dense":true,"hide-details":"auto","label":"Podpis"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("   "),_c('v-row',{attrs:{"dense":true,"align":"center"}},[(_vm.pricingClientSignature)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":_vm.$global.axiosUrl() + 'files/' + _vm.pricingClientSignature + '/generate/original',"alt":"Podpis","max-width":"300px","max-height":"100px"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"dense":true}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Signature',{attrs:{"small":true,"pricingToken":Item.pricingToken},on:{"onSignature":(response) => {
                          ItemForm.PricingClientSignature = response.data.data.id

                          _vm.pricingClientSignature = response.data.data.token
                        }}})],1),(ItemForm.PricingClientSignature && _vm.pricingClientSignature)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":true,"icon":true,"outlined":true,"color":"error"},on:{"click":() => {
                          ItemForm.PricingClientSignature = null

                          _vm.pricingClientSignature = null
                        }}},[_c('v-icon',[_vm._v(" mdi-auto-fix ")])],1)],1):_vm._e()],1)],1)],1)]},proxy:true}],null,true)})],1)],1)]}}])},'SaveModal',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }