<template>
  <CardPart
      :maxWidth="maxWidth"
      :title="title + ' (' + total + ')'"
  >
    <template
        v-slot:action
    >
      <v-spacer/>

      <slot
          name="action"
          :init="init"
      />

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click.prevent.stop="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>

    <template
        v-slot:default
    >
      <SearchGrid
          v-if="hasSearch"
          @setParams="setParams"
      >
        <template
            v-slot:default="{
              setSearch
            }"
        >
          <slot
              name="search"
              :params="params"
              :errors="errors"
              :setSearch="setSearch"
          />
        </template>
      </SearchGrid>

      <FilterGrid
          v-if="hasFilter"
          :value="findFilter"
          @setParams="setParams"
      >
        <template
            v-slot:default="{
              setFilter
            }"
        >
          <slot
              name="filter"
              :params="params"
              :errors="errors"
              :setFilter="setFilter"
          />
        </template>
      </FilterGrid>

      <ListGrid
          :hasExtra="hasExtra"
          :standardClass="standardClass"
          :extraClass="extraClass"
          :colspan="colspan"
          :empty="empty"
          :loading="loading"
          :items="items"
          @setParams="setParams"
      >
        <template
            v-slot:head="{
              setOrderBy
            }"
        >
          <slot
              name="list-head"
              :order="params.order"
              :by="params.by"
              :orderList="orderList"
              :byList="byList"
              :setOrderBy="setOrderBy"
          />
        </template>

        <template
            v-slot:body-standard="{
              Item
            }"
        >
          <slot
              name="list-body-standard"
              :Item="Item"
              :init="init"
          />
        </template>

        <template
            v-slot:body-extra="{
              Item
            }"
        >
          <slot
              name="list-body-extra"
              :Item="Item"
              :init="init"
          />
        </template>
      </ListGrid>

      <PaginationGrid
          :loading="loading"
          :total="total"
          :limit="params.limit"
          :page="params.page"
          :limitList="limitList"
          @setParams="setParams"
      />
    </template>
  </CardPart>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  import SearchGrid from '@/components/_Part/_Grid/Search'
  import FilterGrid from '@/components/_Part/_Grid/Filter'
  import ListGrid from '@/components/_Part/_Grid/List'
  import PaginationGrid from '@/components/_Part/_Grid/Pagination'

  export default {
    name: 'Grid',
    props: [
      'maxWidth',
      'title',
      'hasSearch',
      'hasFilter',
      'hasExtra',
      'standardClass',
      'extraClass',
      'colspan',
      'empty',
      'itemsKey',
      'filterKey',
      'defaultFilters',
      'defaultQuery',
      'defaultOrder',
      'defaultBy',
      'defaultLimit',
      'fetchData'
    ],
    emits: [
      'onSuccess',
      'onErrors',
      'onFail'
    ],
    components: {
      CardPart,
      SearchGrid,
      FilterGrid,
      ListGrid,
      PaginationGrid
    },
    data() {
      return {
        loading: false,
        errors: {},
        total: 0,
        items: [],
        params: {
          Filters: {
            ...this.defaultFilters,
          },
          query: this.defaultQuery,
          order: this.$store.state.core.Filter[this.filterKey]?.order[this.defaultOrder]?.value,
          by: this.$store.state.core.Filter[this.filterKey]?.by[this.defaultBy]?.value,
          limit: this.$store.state.core.Filter[this.filterKey]?.limit[this.defaultLimit]?.value,
          page: 1
        }
      }
    },
    computed: {
      orderList: function () {
        return this.$store.state.core.Filter[this.filterKey]?.order
      },
      byList: function () {
        return this.$store.state.core.Filter[this.filterKey]?.by
      },
      limitList: function () {
        return this.$store.state.core.Filter[this.filterKey]?.limit
      },
      findFilter: function() {
        return Object.values(this.params.Filters).find((value) => value)
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.loading = true

        this.errors = {}

        this.fetchData(this.params)
          .then((response) => {
            this.total = response.data.data.total

            this.items = response.data.data[this.itemsKey]

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.errors = error.response.data.errors

              this.$emit('onErrors', error.response)
            } else {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setParams(params) {
        this.params = {
          ...this.params,
          ...params
        }

        this.init()
      }
    }
  }
</script>
