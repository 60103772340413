import Vue from 'vue'

class UserProfile {
  async exist() {
    return await Vue.axios
      .get('app/user/profile/exist')
  }

  async set({data}) {
    return await Vue.axios
      .put('app/user/profile/set', data)
  }
}

const userProfile = new UserProfile()

export default userProfile
