var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":_vm.title,"hasSearch":true,"hasFilter":true,"hasExtra":true,"standardClass":() => {},"extraClass":() => {},"colspan":6,"empty":"Nenašli sa žiadne príjmy.","itemsKey":"revenues","filterKey":"Revenue","defaultFilters":{
      pay: null
    },"defaultOrder":"rev.paidAt","defaultBy":"DESC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
        init
      }){return [_c('CreateAccount',{attrs:{"accountId":_vm.Account.id},on:{"onCreateAccount":init}}),_vm._v("   ")]}},{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
        params,
        errors,
        setFilter
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.pay,"outlined":true,"dense":true,"hide-details":"auto","label":"Platba","items":_vm.pays,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.pay),callback:function ($$v) {_vm.$set(params.Filters, "pay", $$v)},expression:"params.Filters.pay"}})],1)],1)]}},{key:"list-head",fn:function({
        order,
        by,
        orderList,
        byList,
        setOrderBy
      }){return [_c('th',{staticClass:"fixed"},[_c('OrderList',{attrs:{"orderKey":"rev.paidAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Názov ")]),_c('th',[_vm._v(" Suma ")]),_c('th',[_vm._v(" Zostatok ")]),_c('th',[_vm._v(" Platba ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
        Item,
        init
      }){return [_c('td',{staticClass:"fixed"},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.paidAt,'DD.MM.YYYY'))+" ")]),_c('td',[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(Item.price,'€'))+" ")]),_c('td',[(Item.alertBalance)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("currency")(Item.balance,'€'))+" ")],1),_c('td',[_vm._v(" "+_vm._s(_vm.payList[Item.pay].text)+" ")]),_c('td',{staticClass:"text-right fixed"},[_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(Item.deletable)?[_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}})]:_vm._e()],2)]}},{key:"list-body-extra",fn:function({
        Item
      }){return [_c('td',{attrs:{"colspan":6}},[_c('IsEmptyHelper',{attrs:{"value":Item.note,"if-no":"i","if-yes":"i","empty":"bez poznámky"}},[_vm._v(" "+_vm._s(Item.note)+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }