<template>
  <div>
    <v-app-bar
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-app-bar"
    >
      <v-app-bar-nav-icon
          @click="$store.dispatch('layout/toggleDrawer')"
      />

      <v-app-bar-title>
        <v-img
            :src="$global.logo()"
            :alt="$global.title()"
            max-width="200px"
        />
      </v-app-bar-title>

      <v-spacer/>

      <NotificationsApp/>

      &nbsp;

      <ClearCacheConfirm/>
    </v-app-bar>

    <v-navigation-drawer
        v-model="$store.state.layout.drawer.status"
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-navigation-drawer"
    >
      <v-list
          :light="true"
          class="custom-list-user"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.auth.User.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <ProfileSetSave/>

              &nbsp;

              <LogoutConfirm/>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list
          v-if="items"
          class="custom-list-menu"
      >
        <MenuApp
            v-for="(_item, i) in items"
            :key="i"
            :item="_item"
        />
      </v-list>
    </v-navigation-drawer>

    <v-main
        class="custom-main custom-main-backend"
    >
      <v-container
          :fluid="true"
      >
        <v-breadcrumbs
            v-if="$store.state.layout.breadcrumbs.length > 0"
            divider="/"
            :items="$store.state.layout.breadcrumbs"
        />

        <router-view/>
      </v-container>
    </v-main>

    <v-footer
        :app="true"
        :dark="true"
        class="custom-footer"
    >
      <v-spacer/>

      v{{ $global.version() }}

      <v-spacer/>
    </v-footer>
  </div>
</template>

<script>
  import menu from '@/plugins/config/menu'

  import ClearCacheConfirm from '@/components/_App/_Modal/_Confirm/ClearCache'
  import LogoutConfirm from '@/components/_App/_Modal/_Confirm/Logout'

  import ProfileSetSave from '@/components/_App/_Modal/_Save/ProfileSet'

  import MenuApp from '@/components/_App/Menu'
  import NotificationsApp from '@/components/_App/Notifications'

  export default {
    name: 'PageBackend',
    components: {
      ClearCacheConfirm,
      LogoutConfirm,
      ProfileSetSave,
      MenuApp,
      NotificationsApp
    },
    computed: {
      items() {
        return menu.items[this.$store.state.auth.User.type]
      }
    }
  }
</script>
