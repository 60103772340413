<template>
  <div>
    <v-row>
      <v-col
          cols="12"
      >
        <v-alert
            type="info"
        >
          {{ question }}
        </v-alert>
      </v-col>
    </v-row>

    <slot
        name="default"
        :loading="loading"
        :accept="accept"
    />
  </div>
</template>

<script>
  export default {
    name: 'Consent',
    props: [
      'Item',
      'setQuestion',
      'acceptData'
    ],
    emits: [
      'onSuccess',
      'onFail'
    ],
    data() {
      return {
        loading: false,
        question: null
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.question = this.setQuestion(this.Item)
      },
      async accept() {
        this.loading = true

        await this.acceptData()
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })

            this.$emit('onFail', error.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
