import Vue from 'vue'

import Qs from 'qs'

class Users {
  async suggestRole({role}) {
    return await Vue.axios
      .get('app/users/suggest/by-role/' + role)
  }

  async suggestRoleTicketItem({role, ticketItemId}) {
    return await Vue.axios
      .get('app/users/suggest/by-role-and-ticket-item/' + role + '/' + ticketItemId)
  }

  async index({params}) {
    return await Vue.axios
      .get('app/users', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/users', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/users/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/users/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/users/' + id)
  }
}

const users = new Users()

export default users
