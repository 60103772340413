<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="true"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="7"
          empty="Nenašli sa žiadni užívatelia."
          itemsKey="users"
          filterKey="User"
          :defaultFilters="{
            type: null
          }"
          defaultOrder="use.billingName"
          defaultBy="ASC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          &nbsp;
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-row>
            <v-col
                cols="12"
                lg="3"
            >
              <v-select
                  v-model="params.Filters.type"
                  :error-messages="errors.Filters?.type"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Typ"
                  :items="types"
                  :clearable="true"
                  @change="setFilter"
              />
            </v-col>
          </v-row>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th
              class="fixed"
          >
            <OrderList
                orderKey="use.name"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            E-mail firemný
          </th>

          <th>
            E-mail osobný
          </th>

          <th>
            Telefón
          </th>

          <th>
            Typ
          </th>

          <th>
            Plat
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="fixed"
          >
            <TruncateHelper
                :title="Item.name"
                maxWidth="300px"
            >
              {{ Item.name }}
            </TruncateHelper>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.emailBusiness"
            >
              <TruncateHelper
                  :title="Item.emailBusiness"
                  maxWidth="300px"
              >
                {{ Item.emailBusiness }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.emailPersonal"
                if-no="a"
                if-yes="span"
                :href="'mailto: ' + Item.emailPersonal"
            >
              <TruncateHelper
                  :title="Item.emailPersonal"
                  maxWidth="300px"
              >
                {{ Item.emailPersonal }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td>
            <a
                :href="'tel: ' + Item.phone"
            >
              <TruncateHelper
                  :title="Item.phone"
                  maxWidth="300px"
              >
                {{ Item.phone }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            {{ typeList[Item.type]?.text }}
          </td>

          <td>
            {{ Item.basePriceHour|currency('€ / h') }}

            <template
                v-if="Item.basePriceHour !== Item.minPriceHour"
            >
              (min. {{ Item.minPriceHour|currency('€ / h') }})
            </template>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/management/' + Item.id"
            >
              <v-icon>
                mdi-cash
              </v-icon>
            </v-btn>

            &nbsp;

            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            <template
                v-if="Item.UserData.deletable"
            >
              &nbsp;

              <Delete
                  :id="Item.id"
                  @onDelete="init"
              />
            </template>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Users from '@/services/users'

  import Create from '@/components/Users/Create'
  import Update from '@/components/Users/Update'
  import Delete from '@/components/Users/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'
  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Users',
    components: {
      Create,
      Update,
      Delete,
      BlankPage,
      GridPart,
      OrderList,
      IsEmptyHelper,
      TruncateHelper
    },
    computed: {
      typeList: function () {
        return this.$store.state.core.Entity.User?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      fetchData(params) {
        return Users
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Užívatelia'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
