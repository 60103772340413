<template>
  <component
      v-if="value"
      :is="ifNo"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot/>
  </component>

  <component
      v-else
      :is="ifYes"
  >
    <template
        v-if="empty"
    >
      {{ empty }}
    </template>

    <template
        v-else
    >
      -
    </template>
  </component>
</template>

<script>
  export default {
    name: 'IsEmpty',
    props: [
      'value',
      'ifNo',
      'ifYes',
      'empty'
    ]
  }
</script>
