<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="false"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="5"
          empty="Nenašli sa žiadni klienti."
          itemsKey="clients"
          filterKey="Client"
          defaultOrder="cli.billingName"
          defaultBy="ASC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          &nbsp;
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th
              class="fixed"
          >
            <OrderList
                orderKey="cli.billingName"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            <OrderList
                orderKey="cli.contactName"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            E-mail
          </th>

          <th>
            Telefón
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="fixed"
          >
            <v-icon>
              <template
                  v-if="Item.billingOnCompany"
              >
                mdi-domain
              </template>

              <template
                  v-else
              >
                mdi-account
              </template>
            </v-icon>

            &nbsp;

            <TruncateHelper
                :title="Item.billingName"
                maxWidth="300px"
            >
              {{ Item.billingName }}
            </TruncateHelper>
          </td>

          <td>
            <TruncateHelper
                :title="Item.contactName"
                maxWidth="300px"
            >
              {{ Item.contactName }}
            </TruncateHelper>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.contactEmail"
            >
              <TruncateHelper
                  :title="Item.contactEmail"
                  maxWidth="300px"
              >
                {{ Item.contactEmail }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <a
                :href="'tel: ' + Item.contactPhone"
            >
              <TruncateHelper
                  :title="Item.contactPhone"
                  maxWidth="300px"
              >
                {{ Item.contactPhone }}
              </TruncateHelper>
            </a>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/client/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>

            &nbsp;

            <CreateClientProject
                :clientId="Item.id"
                @onCreateClient="init"
            />

            &nbsp;

            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
            >
              &nbsp;

              <Delete
                  :id="Item.id"
                  @onDelete="init"
              />
            </template>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Clients from '@/services/clients'

  import Create from '@/components/Clients/Create'
  import Update from '@/components/Clients/Update'
  import Delete from '@/components/Clients/Delete'

  import CreateClientProject from '@/components/Projects/CreateClient'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Clients',
    components: {
      Create,
      Update,
      Delete,
      CreateClientProject,
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper
    },
    methods: {
      fetchData(params) {
        return Clients
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Klienti'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
