<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="false"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="3"
      empty="Nenašli sa žiadne číselníky."
      itemsKey="indexes"
      filterKey="Index"
      defaultOrder="ind.sort"
      defaultBy="ASC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <CreateIndexGroup
          :indexGroupId="IndexGroup.id"
          @onCreateIndexGroup="(response) => {
            init()

            $emit('refresh', response)
          }"
      />

      &nbsp;
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        Názov
      </th>

      <th>
        <OrderList
            orderKey="ind.sort"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.sort }}
      </td>

      <td
          class="text-right fixed"
      >
        <Update
            :id="Item.id"
            @onUpdate="(response) => {
              init()

              $emit('refresh', response)
            }"
        />

        &nbsp;

        <Delete
            :id="Item.id"
            @onDelete="(response) => {
              init()

              $emit('refresh', response)
            }"
        />
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Indexes from '@/services/indexes'

  import CreateIndexGroup from '@/components/Indexes/CreateIndexGroup'
  import Update from '@/components/Indexes/Update'
  import Delete from '@/components/Indexes/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Indexes',
    props: [
      'IndexGroup'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateIndexGroup,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper
    },
    data() {
      return {
        title: 'Číselníky'
      }
    },
    methods: {
      fetchData(params) {
        return Indexes
          .indexIndexGroup({
            indexGroupId: this.IndexGroup.id,
            params: params
          })
      }
    }
  }
</script>
