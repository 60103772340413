<template>
  <PagePart
      :itemKey="itemKey"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading,
          Item,
          title,
          backLink,
          refresh
        }"
    >
      <CardPart
          :maxWidth="maxWidth"
          :title="title"
      >
        <template
            v-slot:action
        >
          <v-spacer/>

          <slot
              name="action"
              :Item="Item"
              :refresh="refresh"
          />

          <template
              v-if="hasBackLink"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="backLink"
                :exact="true"
            >
              <v-icon>
                mdi-history
              </v-icon>
            </v-btn>

            &nbsp;
          </template>

          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="default"
              @click="refresh"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <template
            v-slot:default
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <slot
              name="default"
              :Item="Item"
              :refresh="refresh"
          />
        </template>
      </CardPart>
    </template>
  </PagePart>
</template>

<script>
  import PagePart from '@/components/_Part/Page'
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'NotBlank',
    props: [
      'maxWidth',
      'hasBackLink',
      'failLink',
      'itemKey',
      'fetchData',
      'setTitle',
      'setBreadcrumbs'
    ],
    components: {
      PagePart,
      CardPart
    },
    methods: {
      onFail() {
        setTimeout(() => {
          this.$router.push(this.failLink)
        }, 100)
      }
    }
  }
</script>
