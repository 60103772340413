<template>
  <ConfirmModal
      color="red"
      icon="mdi-close"
      :maxWidth="500"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="pricingRejectData"
      @onConfirm="(response) => $emit('onPricingReject', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Tickets from '@/services/tickets'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'PricingReject',
    props: [
      'id'
    ],
    emits: [
      'onPricingReject'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Odmietnuť cenovú ponuku č.' + Item.id
      },
      setQuestion(Item) {
        return 'Naozaj chcete odmietnuť cenovú ponuku č.' + Item.id + '?'
      },
      pricingRejectData() {
        return Tickets
          .pricingReject({
            id: this.id
          })
      }
    }
  }
</script>
