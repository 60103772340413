var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalPart',_vm._b({attrs:{"color":_vm.color,"icon":_vm.icon,"maxWidth":_vm.maxWidth,"itemKey":_vm.itemKey,"fetchData":_vm.fetchData,"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        closeDialog
      }){return [_c('FormPart',{attrs:{"Item":Item,"setItemForm":_vm.setItemForm,"submitData":_vm.saveData},on:{"onSuccess":(response) => {
          _vm.$emit('onSave', response)

          closeDialog()
        },"onFail":closeDialog},scopedSlots:_vm._u([{key:"default",fn:function({
            loading,
            errors,
            ItemForm
          }){return [_vm._t("default",null,{"Item":Item,"errors":errors,"ItemForm":ItemForm}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","rounded":true,"outlined":true,"loading":loading,"disabled":loading,"color":_vm.color}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_vm._v(" "+_vm._s(_vm.button)+" ")],1),_vm._v("   "),_c('v-btn',{attrs:{"rounded":true,"outlined":true,"color":"default"},on:{"click":closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")]),_vm._v(" Zrušiť ")],1)],1)],1)]}}],null,true)})]}}])},'ModalPart',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }