<template>
  <ConfirmModal
      color="error"
      icon="mdi-exclamation"
      :maxWidth="500"
      itemKey="TicketItemComment"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="updateStatusUnimportantData"
      @onConfirm="(response) => $emit('onUpdateStatusUnimportant', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import TicketItemCommentsAuth from '@/services/ticket-item-comments-auth'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'UpdateStatusUnimportant',
    props: [
      'id'
    ],
    emits: [
      'onUpdateStatusUnimportant'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return TicketItemCommentsAuth
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Označiť komentár z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name + ' ako nedôležitý'
      },
      setQuestion(Item) {
        return 'Naozaj chcete označiť komentár z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name + ' ako nedôležitý?'
      },
      updateStatusUnimportantData() {
        return TicketItemCommentsAuth
          .updateStatusUnimportant({
            id: this.id
          })
      }
    }
  }
</script>
