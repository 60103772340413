import Vue from 'vue'

import Qs from 'qs'

class TicketItemReports {
  async indexTicket({ticketId, params}) {
    return await Vue.axios
      .get('app/ticket-item-reports/by-ticket/' + ticketId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexTicketItem({ticketItemId, params}) {
    return await Vue.axios
      .get('app/ticket-item-reports/by-ticket-item/' + ticketItemId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-reports/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-item-reports/' + id, data)
  }

  async updateMass({data}) {
    return await Vue.axios
      .put('app/ticket-item-reports/mass', data)
  }
}

const ticketItemReports = new TicketItemReports()

export default ticketItemReports
