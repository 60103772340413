<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="TicketItem"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="8"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <v-text-field
              v-model="ItemForm.sort"
              :error-messages="errors.sort"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poradie"
              type="number"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave
    },
    methods: {
      fetchData() {
        return TicketItems
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť položku "' + Item.title + '"'
      },
      setItemForm(Item) {
        return {
          title: Item.title,
          sort: Item.sort
        }
      },
      updateData(ItemForm) {
        return TicketItems
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
