import Vue from 'vue'

class TicketItemTasksAuth {
  async createTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .post('app/ticket-item-tasks/auth/by-ticket-item/' + ticketItemId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-tasks/auth/' + id)
  }

  async updateStatusDone({id}) {
    return await Vue.axios
      .put('app/ticket-item-tasks/auth/' + id + '/status/done')
  }

  async updateStatusUndone({id}) {
    return await Vue.axios
      .put('app/ticket-item-tasks/auth/' + id + '/status/undone')
  }
}

const ticketItemTasksAuth = new TicketItemTasksAuth()

export default ticketItemTasksAuth
