<template>
  <NotBlankPage
      v-if="$route.params.userId"
      :key="$route.params.userId"
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/management"
      itemKey="User"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestUsersRole
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Užívateľ"
              role="ROLE_WORKER"
              :createButton="true"
              @change="(value) => {
                $router.push('/management/' + value)
              }"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabs"
          >
            <v-tab>
              Položky
            </v-tab>

            <v-tab>
              Notifikácie
            </v-tab>

            <v-tab>
              Výplaty
            </v-tab>
          </v-tabs>

          <v-tabs-items
              v-model="tabs"
          >
            <v-tab-item>
              <TicketItems
                  :User="Item"
              />
            </v-tab-item>

            <v-tab-item>
              <Notifications
                  :User="Item"
              />
            </v-tab-item>

            <v-tab-item>
              <Wages
                  :User="Item"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>

  <BlankPage
      v-else
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          maxWidth="100%"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <SuggestUsersRole
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Užívateľ"
                  role="ROLE_WORKER"
                  :createButton="true"
                  @change="(value) => {
                    $router.push('/management/' + value)
                  }"
              />
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import Users from '@/services/users'

  import TicketItems from '@/components/Management/TicketItems'
  import Notifications from '@/components/Management/Notifications'
  import Wages from '@/components/Management/Wages'

  import SuggestUsersRole from '@/components/Users/SuggestRole'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'
  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Management',
    components: {
      TicketItems,
      Notifications,
      Wages,
      SuggestUsersRole,
      NotBlankPage,
      BlankPage,
      CardPart
    },
    data() {
      return {
        tabs: 2
      }
    },
    methods: {
      fetchData() {
        return Users
          .read({
            id: this.$route.params.userId
          })
      },
      setTitle(Item) {
        if (this.$route.params.userId) {
          return Item.name
        } else {
          return 'Manažment'
        }
      },
      setBreadcrumbs() {
        if (this.$route.params.userId) {
          return [
            {
              text: 'Manažment',
              to: '/management',
              exact: true
            }
          ]
        } else {
          return []
        }
      }
    }
  }
</script>
