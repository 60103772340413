<template>
  <NotBlankPage
      maxWidth="calc(100% - 160px)"
      :hasBackLink="true"
      failLink="/tickets"
      itemKey="TicketItem"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <template
            v-if="Item.updatable"
        >
          <Update
              :id="Item.id"
              @onUpdate="refresh"
          />

          &nbsp;
        </template>

        <template
            v-if="Item.deletable"
        >
          <Delete
              :id="Item.id"
              @onDelete="onDelete"
          />

          &nbsp;
        </template>
      </template>
    </template>

    <template
        v-slot:default="{
          Item,
          refresh
        }"
    >
      <v-row>
        <v-col
            v-if="[Item.Ticket?.Seller?.id, Item.Ticket?.Manager?.id].includes($store.state.auth.User.id)"
            cols="12"
        >
          <v-alert
              color="blue"
              :outlined="true"
              class="text-center"
          >
            Pre úpravu komentáru, úlohy alebo výkazu kliknite tu

            <v-btn
                :x-small="true"
                :icon="true"
                :outlined="true"
                color="blue"
                :to="'/ticket-item-auth/' + Item.id"
            >
              <v-icon>
                mdi-share
              </v-icon>
            </v-btn>
          </v-alert>
        </v-col>

        <v-col
            cols="12"
        >
          <h3>
            Tarifa
          </h3>

          <p>
            {{ Item.currentTariffTitle }}
          </p>
        </v-col>

        <template
            v-if="Item.showableTask || Item.showableTicketItemComments || Item.showableTicketItemTasks"
        >
          <v-col
              cols="12"
              lg="8"
          >
            <v-row>
              <template
                  v-if="Item.showableTask"
              >
                <v-col
                    cols="12"
                >
                  <PanelPart
                      maxWidth="calc(100% - 40px)"
                      color="primary"
                      icon="mdi-content-copy"
                      title="Zadanie"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="Item.updatableTask"
                      >
                        <v-spacer/>

                        <UpdateTask
                            :small="true"
                            :id="Item.id"
                            @onUpdateTask="refresh"
                        />
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.content"
                          if-no="div"
                          if-yes="p"
                      >
                        <div
                            v-html="Item.content"
                        />
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>

                <v-col
                    cols="12"
                >
                  <PanelPart
                      maxWidth="calc(100% - 40px)"
                      color="primary"
                      icon="mdi-attachment"
                      :title="'Prílohy (' + Item.files.length + ')'"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="Item.updatableTask"
                      >
                        <v-spacer/>

                        <UpdateTask
                            :small="true"
                            :id="Item.id"
                            @onUpdateTask="refresh"
                        />
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.files.length > 0"
                          if-no="v-row"
                          if-yes="p"
                          empty="žiadne"
                      >
                        <v-col
                            v-for="(_File) in Item.files"
                            :key="_File.id"
                            cols="auto"
                            align="center"
                        >
                          <a
                              :href="$global.axiosUrl() + 'files/'  + _File.token + '/generate/original'"
                              target="_blank"
                          >
                            <v-icon
                                :large="true"
                            >
                              mdi-file
                            </v-icon>

                            <br/>

                            {{ _File.name }}
                          </a>
                        </v-col>
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>

                <v-col
                    v-if="Item.Ticket?.Project"
                    cols="12"
                >
                  <PanelPart
                      maxWidth="calc(100% - 40px)"
                      color="primary"
                      icon="mdi-key"
                      title="Poznámka"
                      :value="Item.Ticket?.Project?.note"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="[Item.Ticket?.Seller?.id, Item.Ticket?.Manager?.id].includes($store.state.auth.User.id)"
                      >
                        <v-spacer/>

                        <UpdateNoteProjectAuth
                            :small="true"
                            :id="Item.Ticket?.Project?.id"
                            @onUpdateNote="refresh"
                        />
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.Ticket?.Project?.note"
                          if-no="div"
                          if-yes="p"
                      >
                        <div
                            v-html="Item.Ticket?.Project?.note"
                        />
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>
              </template>

              <v-col
                  v-if="Item.showableTicketItemComments"
                  cols="12"
              >
                <TicketItemComments
                    :TicketItem="Item"
                    @refresh="refresh"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
              v-if="Item.showableTicketItemTasks"
              cols="12"
              lg="4"
          >
            <TicketItemTasks
                :TicketItem="Item"
                @refresh="refresh"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
            lg="3"
        >
          <CardPart
              maxWidth="100%"
              title="Informácie"
          >
            <v-row>
              <template
                  v-if="Item.showableStatus"
              >
                <v-col
                    v-if="Item.updatableStatus"
                    cols="12"
                >
                  <FormStatus
                      :TicketItem="Item"
                      @refresh="refresh"
                  />
                </v-col>

                <template
                    v-else
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Stav
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.status"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ statusList[Item.status]?.text }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </template>

              <template
                  v-if="Item.showableSettings"
              >
                <v-col
                    v-if="Item.updatableSettings"
                    cols="12"
                >
                  <FormSettings
                      :TicketItem="Item"
                      @refresh="refresh"
                  />
                </v-col>

                <template
                    v-else
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Dátum dokončenia
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.deadlineAt"
                            if-no="p"
                            if-yes="p"
                        >
                          <v-icon
                              v-if="Item.alertDeadlineAt && Item.printableAlert"
                              color="error"
                          >
                            mdi-alert
                          </v-icon>

                          {{ Item.deadlineAt|moment('DD.MM.YYYY') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Priorita
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.priority"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.priority }} úroveň
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </template>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="6"
                  >
                    <h4>
                      Nacenený čas
                    </h4>
                  </v-col>

                  <v-col
                      cols="6"
                  >
                    <IsEmptyHelper
                        :value="Item.printablePricing"
                        if-no="p"
                        if-yes="p"
                    >
                      {{ Item.timeEstimationOffers|number }} h
                    </IsEmptyHelper>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="6"
                  >
                    <h4>
                      Odpracovaný čas
                    </h4>
                  </v-col>

                  <v-col
                      cols="6"
                  >
                    <IsEmptyHelper
                        :value="Item.printableInvoicing"
                        if-no="p"
                        if-yes="p"
                    >
                      <v-icon
                          v-if="Item.alerTimeSpend && Item.printableAlert"
                          color="error"
                      >
                        mdi-alert
                      </v-icon>

                      {{ Item.timeSpendReports|number }} h / {{ Item.maxTimeSpend|number }} h
                    </IsEmptyHelper>
                  </v-col>
                </v-row>
              </v-col>

              <template
                  v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
              >
                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Nacenená suma
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.printablePricing"
                          if-no="p"
                          if-yes="p"
                      >
                        {{ Item.priceEstimationOffers|currency('€') }}
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Odpracovaná suma
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.printableInvoicing"
                          if-no="p"
                          if-yes="p"
                      >
                        {{ Item.priceSpendReports|currency('€') }}
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>
              </template>

              <template
                  v-if="Item.showableAlert"
              >
                <template
                    v-if="Item.updatableAlert"
                >
                  <v-col
                      cols="12"
                  >
                    <v-divider/>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <FormAlert
                        :TicketItem="Item"
                        @refresh="refresh"
                    />
                  </v-col>
                </template>
              </template>

              <template
                  v-if="Item.showableAssignment"
              >
                <v-col
                    cols="12"
                >
                  <v-divider/>
                </v-col>

                <v-col
                    v-if="Item.updatableAssignment"
                    cols="12"
                >
                  <FormAssignment
                      :TicketItem="Item"
                      @refresh="refresh"
                  />
                </v-col>

                <template
                    v-else
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Užívatelia
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.users.length > 0"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.users.map((_User) => _User.name).join('\n') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Vedúci
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.Leader?.name"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.Leader?.name }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Pracovník
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.Worker?.name"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.Worker?.name }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </template>
            </v-row>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
            lg="9"
        >
          <v-tabs
              v-model="tabs"
          >
            <v-tab
                v-if="Item.showableTicketItemOffers"
            >
              Ponuky
            </v-tab>

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
            >
              <v-tab
                  v-if="Item.showablePricing"
              >
                Cenová ponuka

                &nbsp;

                <v-chip>
                  {{ Item.pricingSubtotal|currency('€') }}
                </v-chip>
              </v-tab>
            </template>

            <v-tab
                v-if="Item.showableTicketItemReports"
            >
              Výkazy
            </v-tab>

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
            >
              <v-tab
                  v-if="Item.showableInvoicing"
              >
                Fakturácia

                &nbsp;

                <v-chip>
                  {{ Item.invoicingSubtotal|currency('€') }}
                </v-chip>
              </v-tab>
            </template>
          </v-tabs>

          <v-tabs-items
              v-model="tabs"
          >
            <v-tab-item
                v-if="Item.showableTicketItemOffers"
            >
              <TicketItemOffers
                  :TicketItem="Item"
                  @refresh="refresh"
              />
            </v-tab-item>

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
            >
              <v-tab-item
                  v-if="Item.showablePricing"
              >
                <CardPart
                    maxWidth="100%"
                    title="Cenová ponuka"
                >
                  <template
                      v-slot:default
                  >
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <TariffInfo
                            :TicketItem="Item"
                        />
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-divider/>
                      </v-col>

                      <v-col
                          v-if="Item.updatablePricing"
                          cols="12"
                      >
                        <FormPricing
                            :TicketItem="Item"
                            @refresh="refresh"
                        />
                      </v-col>

                      <template
                          v-else
                      >
                        <v-col
                            cols="12"
                            lg="4"
                        >
                          <h3>
                            Množstvo
                          </h3>

                          <p>
                            {{ Item.pricingQuantity|number }} x
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                            lg="4"
                        >
                          <h3>
                            Prirážka
                          </h3>

                          <p>
                            {{ Item.pricingIncrease|currency('€') }}
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                          <h3>
                            Popis
                          </h3>

                          <IsEmptyHelper
                              :value="Item.pricingDescription"
                              if-no="p"
                              if-yes="p"
                          >
                            {{ Item.pricingDescription }}
                          </IsEmptyHelper>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </CardPart>
              </v-tab-item>
            </template>

            <v-tab-item
                v-if="Item.showableTicketItemReports"
            >
              <TicketItemReports
                  :TicketItem="Item"
                  @refresh="refresh"
              />
            </v-tab-item>

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
            >
              <v-tab-item
                  v-if="Item.showableInvoicing"
              >
                <CardPart
                    maxWidth="100%"
                    title="Fakturácia"
                >
                  <template
                      v-slot:default
                  >
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <TariffInfo
                            :TicketItem="Item"
                        />
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-divider/>
                      </v-col>

                      <v-col
                          v-if="Item.updatableInvoicing"
                          cols="12"
                      >
                        <FormInvoicing
                            :TicketItem="Item"
                            @refresh="refresh"
                        />
                      </v-col>

                      <template
                          v-else
                      >
                        <v-col
                            cols="12"
                            lg="4"
                        >
                          <h3>
                            Množstvo
                          </h3>

                          <p>
                            {{ Item.invoicingQuantity|number }} x
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                            lg="4"
                        >
                          <h3>
                            Prirážka
                          </h3>

                          <p>
                            {{ Item.invoicingIncrease|currency('€') }}
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                          <h3>
                            Popis
                          </h3>

                          <IsEmptyHelper
                              :value="Item.invoicingDescription"
                              if-no="p"
                              if-yes="p"
                          >
                            {{ Item.invoicingDescription }}
                          </IsEmptyHelper>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </CardPart>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import Update from '@/components/TicketItems/Update'
  import UpdateTask from '@/components/TicketItems/UpdateTask'
  import Delete from '@/components/TicketItems/Delete'
  import TicketItemComments from '@/components/TicketItem/TicketItemComments'
  import TicketItemTasks from '@/components/TicketItem/TicketItemTasks'
  import TicketItemOffers from '@/components/TicketItem/TicketItemOffers'
  import TicketItemReports from '@/components/TicketItem/TicketItemReports'
  import TariffInfo from '@/components/TicketItem/TariffInfo'
  import FormStatus from '@/components/TicketItem/FormStatus'
  import FormSettings from '@/components/TicketItem/FormSettings'
  import FormAlert from '@/components/TicketItem/FormAlert'
  import FormAssignment from '@/components/TicketItem/FormAssignment'
  import FormPricing from '@/components/TicketItem/FormPricing'
  import FormInvoicing from '@/components/TicketItem/FormInvoicing'

  import UpdateNoteProjectAuth from '@/components/ProjectsAuth/UpdateNote'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import PanelPart from '@/components/_Part/Panel'
  import CardPart from '@/components/_Part/Card'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItem',
    components: {
      Update,
      UpdateTask,
      Delete,
      TicketItemComments,
      TicketItemTasks,
      TicketItemOffers,
      TicketItemReports,
      TariffInfo,
      FormStatus,
      FormSettings,
      FormAlert,
      FormAssignment,
      FormPricing,
      FormInvoicing,
      UpdateNoteProjectAuth,
      NotBlankPage,
      PanelPart,
      CardPart,
      IsEmptyHelper
    },
    data() {
      return {
        ticketId: null,
        tabs: 0
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItem?.status
      }
    },
    methods: {
      fetchData() {
        return TicketItems
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setBreadcrumbs(Item) {
        this.ticketId = Item.Ticket.id

        return [
          {
            text: 'Tikety',
            to: '/tickets'
          },
          {
            text: Item.Ticket.title,
            to: '/ticket/' + Item.Ticket.id
          }
        ]
      },
      onDelete() {
        setTimeout(() => {
          this.$router.push('/ticket/' + this.ticketId)
        }, 100)
      }
    }
  }
</script>
