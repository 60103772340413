<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="Index"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestIndexGroups
              v-model="ItemForm.IndexGroup"
              :error-messages="errors.IndexGroup"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Skupina filtrov"
              :createButton="true"
          />
        </v-col>

        <v-col
            cols="12"
            lg="9"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
            lg="3"
        >
          <v-text-field
              v-model="ItemForm.sort"
              :error-messages="errors.sort"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poradie"
              type="number"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Indexes from '@/services/indexes'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestIndexGroups from '@/components/IndexGroups/Suggest'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestIndexGroups
    },
    methods: {
      fetchData() {
        return Indexes
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť číselník "' + Item.title + '"'
      },
      setItemForm(Item) {
        return {
          IndexGroup: Item.IndexGroup.id,
          title: Item.title,
          sort: Item.sort
        }
      },
      updateData(ItemForm) {
        return Indexes
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
