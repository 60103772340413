<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Project"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestClients
              v-model="ItemForm.Client"
              :error-messages="errors.Client"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Klient"
              :createButton="true"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <vue-editor
              v-model="ItemForm.note"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Projects from '@/services/projects'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestClients from '@/components/Clients/Suggest'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestClients
    },
    methods: {
      fetchData() {
        return Projects
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť projekt "' + Item.title + '"'
      },
      setItemForm(Item) {
        return {
          Client: Item.Client.id,
          title: Item.title,
          note: Item.note
        }
      },
      updateData(ItemForm) {
        return Projects
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
