<template>
  <a
      @click="setOrder(orderList[orderKey]?.value)"
  >
    <v-icon
        v-if="order === orderList[orderKey]?.value"
    >
      <template
          v-if="by === byList.ASC?.value"
      >
        mdi-chevron-up
      </template>

      <template
          v-else
      >
        mdi-chevron-down
      </template>
    </v-icon>

    {{ orderList[orderKey]?.text }}
  </a>
</template>

<script>
  export default {
    name: 'Order',
    props: [
      'orderKey',
      'order',
      'by',
      'orderList',
      'byList'
    ],
    emits: [
      'setOrderBy'
    ],
    methods: {
      setOrder(order) {
        let by = this.byList.ASC?.value

        if (this.order === order) {
          by = (
            this.by === this.byList.ASC?.value
              ? this.byList.DESC?.value
              : this.byList.ASC?.value
          )
        }

        this.$emit('setOrderBy', order, by)
      }
    }
  }
</script>
