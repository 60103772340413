<template>
  <PanelPart
      maxWidth="calc(100% - 40px)"
      color="primary"
      icon="mdi-comment"
      :title="'Úlohy (' + TicketItem.ticketItemTasks.length + ')'"
  >
    <template
        v-slot:action
    >
      <template
          v-if="[TicketItem.Ticket?.Seller?.id, TicketItem.Ticket?.Manager?.id].includes($store.state.auth.User.id)"
      >
        <template
            v-if="TicketItem.ticketItemTaskCreatable"
        >
          <v-spacer/>

          <CreateTicketItemTicketItemTaskAuth
              :small="true"
              :ticketItemId="TicketItem.id"
              @onCreateTicketItem="$emit('refresh')"
          />
        </template>
      </template>
    </template>

    <template
        v-slot:default
    >
      <IsEmptyHelper
          :value="TicketItem.ticketItemTasks.length > 0"
          if-no="v-row"
          if-yes="p"
          empty="žiadne"
      >
        <v-col
            v-for="(_TicketItemTask) in TicketItem.ticketItemTasks"
            :key="_TicketItemTask.id"
            cols="12"
        >
          <p>
            <span
                v-if="_TicketItemTask.status === statusList.done?.value"
                class="success--text text-decoration-line-through"
            >
              <v-icon
                  :small="true"
                  color="success"
              >
                mdi-check
              </v-icon>

              {{ _TicketItemTask.message }}
            </span>

            <template
                v-if="_TicketItemTask.status === statusList.undone?.value"
            >
              {{ _TicketItemTask.message }}
            </template>

            <v-divider/>

            <small>
              {{ _TicketItemTask.createdAt|moment('DD.MM.YYYY HH:mm') }} od {{ _TicketItemTask.Creator.name }}

              <template
                  v-if="_TicketItemTask.updatable"
              >
                &nbsp;

                <UpdateStatusUndoneTicketItemTaskAuth
                    v-if="_TicketItemTask.status === statusList.done?.value"
                    :x-small="true"
                    :id="_TicketItemTask.id"
                    @onUpdateStatusUndone="$emit('refresh')"
                />

                <UpdateStatusDoneTicketItemTaskAuth
                    v-if="_TicketItemTask.status === statusList.undone?.value"
                    :x-small="true"
                    :id="_TicketItemTask.id"
                    @onUpdateStatusDone="$emit('refresh')"
                />
              </template>

              <template
                  v-if="_TicketItemTask.deletable"
              >
                &nbsp;

                <Delete
                    :x-small="true"
                    :id="_TicketItemTask.id"
                    @onDelete="$emit('refresh')"
                />
              </template>
            </small>
          </p>
        </v-col>
      </IsEmptyHelper>
    </template>
  </PanelPart>
</template>

<script>
  import Delete from '@/components/TicketItemTasks/Delete'

  import CreateTicketItemTicketItemTaskAuth from '@/components/TicketItemTasksAuth/CreateTicketItem'
  import UpdateStatusDoneTicketItemTaskAuth from '@/components/TicketItemTasksAuth/UpdateStatusDone'
  import UpdateStatusUndoneTicketItemTaskAuth from '@/components/TicketItemTasksAuth/UpdateStatusUndone'

  import PanelPart from '@/components/_Part/Panel'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItemTasks',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      Delete,
      CreateTicketItemTicketItemTaskAuth,
      UpdateStatusDoneTicketItemTaskAuth,
      UpdateStatusUndoneTicketItemTaskAuth,
      PanelPart,
      IsEmptyHelper
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItemTask?.status
      }
    }
  }
</script>
