import Vue from 'vue'

class TicketPricing {
  async send({data}) {
    return await Vue.axios
      .post('app/ticket/pricing/send', data)
  }

  async exist({pricingToken}) {
    return await Vue.axios
      .get('app/ticket/pricing/exist/' + pricingToken)
  }

  async accept({pricingToken, data}) {
    return await Vue.axios
      .put('app/ticket/pricing/accept/' + pricingToken, data)
  }

  async reject({pricingToken, data}) {
    return await Vue.axios
      .put('app/ticket/pricing/reject/' + pricingToken, data)
  }

  async uploadSignature({pricingToken, data}) {
    return await Vue.axios
      .post('app/ticket/pricing/upload/signature/' + pricingToken, data)
  }
}

const ticketPricing = new TicketPricing()

export default ticketPricing
