<template>
  <GridPart
      maxWidth="calc(100% - 40px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="true"
      :hasExtra="false"
      :standardClass="(Item) => {
        return statusList[Item.status]?.class
      }"
      :extraClass="() => {}"
      :colspan="9"
      empty="Nenašli sa žiadne položky."
      itemsKey="ticketItems"
      filterKey="TicketItem"
      :defaultFilters="{
        type: typeList['to-work']?.value
      }"
      defaultOrder="custom"
      defaultBy="CUSTOM"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:filter="{
          params,
          errors,
          setFilter
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="3"
        >
          <v-select
              v-model="params.Filters.type"
              :error-messages="errors.Filters?.type"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Typ"
              :items="types"
              :clearable="true"
              @change="setFilter"
          />
        </v-col>
      </v-row>
    </template>

    <template
        v-slot:list-head
    >
      <th>
        Projekt
      </th>

      <th
          class="fixed"
      >
        Názov
      </th>

      <th>
        Stav
      </th>

      <th>
        Dátum dokončenia
      </th>

      <th>
        Priorita
      </th>

      <th>
        Zostávajúci čas
      </th>

      <th>
        Vedúci
      </th>

      <th>
        Pracovník
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td>
        <IsEmptyHelper
            :value="Item.Ticket.currentProjectTitle"
            if-no="span"
            if-yes="span"
        >
          <TruncateHelper
              :title="Item.Ticket.currentProjectTitle"
              maxWidth="300px"
          >
            {{ Item.Ticket.currentProjectTitle }}
          </TruncateHelper>
        </IsEmptyHelper>
      </td>

      <td
          class="fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.status"
            if-no="span"
            if-yes="span"
        >
          {{ statusList[Item.status]?.text }}
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.deadlineAt"
            if-no="span"
            if-yes="span"
        >
          <v-icon
              v-if="Item.alertDeadlineAt && Item.printableAlert"
              color="error"
          >
            mdi-alert
          </v-icon>

          {{ Item.deadlineAt|moment('DD.MM.YYYY') }}
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.priority"
            if-no="span"
            if-yes="span"
        >
          {{ Item.priority }} úroveň
        </IsEmptyHelper>
      </td>

      <td>
        <v-icon
            v-if="Item.alertTimeRemain && Item.printableAlert"
            color="error"
        >
          mdi-alert
        </v-icon>

        {{ Item.timeRemainReports|number }} h
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.Leader?.name"
            if-no="span"
            if-yes="span"
        >
          <TruncateHelper
              :title="Item.Leader?.name"
              maxWidth="300px"
          >
            {{ Item.Leader?.name }}
          </TruncateHelper>
        </IsEmptyHelper>
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.Worker?.name"
            if-no="span"
            if-yes="span"
        >
          <TruncateHelper
              :title="Item.Worker?.name"
              maxWidth="300px"
          >
            {{ Item.Worker?.name }}
          </TruncateHelper>
        </IsEmptyHelper>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/ticket-item-auth/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>

          <v-badge
              color="error"
              :value="Item.alertTasks"
              :content="Item.undoneTasks"
          />
        </v-btn>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import TicketItemsAuth from '@/services/ticket-items-auth'

  import GridPart from '@/components/_Part/Grid'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItemsAuth',
    components: {
      GridPart,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Položky'
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItem?.status
      },
      typeList: function () {
        return this.$store.state.core.Entity.TicketItem?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      fetchData(params) {
        return TicketItemsAuth
          .index({
            params: params
          })
      }
    }
  }
</script>
