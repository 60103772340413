<template>
  <DuplicateSave
      :maxWidth="500"
      itemKey="Mail"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :duplicateData="duplicateData"
      @onDuplicate="onDuplicate"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.subject"
              :error-messages="errors.subject"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Predmet"
          />
        </v-col>
      </v-row>
    </template>
  </DuplicateSave>
</template>

<script>
  import Mails from '@/services/mails'

  import DuplicateSave from '@/components/_Part/_Modal/_Save/Duplicate'

  export default {
    name: 'Duplicate',
    props: [
      'id'
    ],
    components: {
      DuplicateSave
    },
    methods: {
      fetchData() {
        return Mails
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Duplikovať mail #' + Item.id
      },
      setItemForm(Item) {
        return {
          subject: Item.subject
        }
      },
      duplicateData(ItemForm) {
        return Mails
          .duplicate({
            id: this.id,
            data: ItemForm
          })
      },
      onDuplicate(response) {
        setTimeout(() => {
          window.location.assign('/mail/' + response.data.data.id)
        }, 100)
      }
    }
  }
</script>
