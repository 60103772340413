<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="true"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="4"
      empty="Nenašli sa žiadne bonusy."
      itemsKey="ticketBonuses"
      filterKey="TicketBonus"
      defaultOrder="ticbon.workedAt"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
      >
        <template
            v-if="Ticket.ticketBonusCreatable"
        >
          <CreateTicket
              :ticketId="Ticket.id"
              @onCreateTicket="(response) => {
                init()

                $emit('refresh', response)
              }"
          />

          &nbsp;
        </template>
      </template>
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="ticbon.workedAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Užívateľ
      </th>

      <th>
        Suma
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        {{ Item.workedAt|moment('DD.MM.YYYY') }}
      </td>

      <td>
        <TruncateHelper
            :title="Item.User.name"
            maxWidth="300px"
        >
          {{ Item.User.name }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.price|currency('€') }}
      </td>

      <td
          class="text-right fixed"
      >
        <template
            v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
        >
          <template
              v-if="Item.updatable"
          >
            <Update
                :id="Item.id"
                @onUpdate="(response) => {
                  init()

                  $emit('refresh', response)
                }"
            />
          </template>

          <template
              v-if="Item.deletable"
          >
            &nbsp;

            <Delete
                :id="Item.id"
                @onDelete="(response) => {
                  init()

                  $emit('refresh', response)
                }"
            />
          </template>
        </template>
      </td>
    </template>

    <template
        v-slot:list-body-extra="{
          Item
        }"
    >
      <td
          :colspan="4"
      >
        <IsEmptyHelper
            :value="Item.note"
            if-no="i"
            if-yes="i"
            empty="bez poznámky"
        >
          {{ Item.note }}
        </IsEmptyHelper>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import TicketBonuses from '@/services/ticket-bonuses'

  import CreateTicket from '@/components/TicketBonuses/CreateTicket'
  import Update from '@/components/TicketBonuses/Update'
  import Delete from '@/components/TicketBonuses/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketBonuses',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateTicket,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Bonusy'
      }
    },
    methods: {
      fetchData(params) {
        return TicketBonuses
          .indexTicket({
            ticketId: this.Ticket.id,
            params: params
          })
      }
    }
  }
</script>
