import Vue from 'vue'

import Qs from 'qs'

class Projects {
  async suggestClient({clientId}) {
    return await Vue.axios
      .get('app/projects/suggest/by-client/' + clientId)
  }

  async indexClient({clientId, params}) {
    return await Vue.axios
      .get('app/projects/by-client/' + clientId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createClient({clientId, data}) {
    return await Vue.axios
      .post('app/projects/by-client/' + clientId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/projects/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/projects/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/projects/' + id)
  }
}

const projects = new Projects()

export default projects
