<template>
  <NotBlankPage
      maxWidth="calc(100% - 40px)"
      :hasBackLink="false"
      failLink="/home"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <iframe
              :src="$global.axiosUrl() + 'app/ticket/pricing/preview/' + Item.pricingToken"
              width="100%"
              height="1000px"
          />
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-btn
              color="success"
              :large="true"
              :block="true"
              @click="$refs.accept.$el.click()"
          >
            <v-icon>
              mdi-check
            </v-icon>

            Prijať
          </v-btn>

          <Accept
              ref="accept"
              :x-small="true"
              :pricingToken="Item.pricingToken"
              class="d-none"
          />
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-btn
              color="error"
              :large="true"
              :block="true"
              @click="$refs.reject.$el.click()"
          >
            <v-icon>
              mdi-close
            </v-icon>

            Odmietnuť
          </v-btn>

          <Reject
              ref="reject"
              :x-small="true"
              :pricingToken="Item.pricingToken"
              class="d-none"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import TicketPricing from '@/services/ticket-pricing'

  import Accept from '@/components/Pricing/Accept'
  import Reject from '@/components/Pricing/Reject'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'Pricing',
    components: {
      Accept,
      Reject,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return TicketPricing
          .exist({
            pricingToken: this.$route.params.pricingToken
          })
      },
      setTitle(Item) {
        return 'Cenová ponuka č.' + Item.id
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
