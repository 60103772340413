<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="true"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="3"
      empty="Nenašli sa žiadne podiely."
      itemsKey="ticketShares"
      filterKey="TicketShare"
      defaultOrder="ticsha.amount"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="Ticket.ticketShareCreatable"
      >
        <CreateTicket
            :ticketId="Ticket.id"
            @onCreateTicket="(response) => {
              init()

              $emit('refresh', response)
            }"
        />

        &nbsp;
      </template>
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        Užívateľ
      </th>

      <th>
        <OrderList
            orderKey="ticsha.amount"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        <TruncateHelper
            :title="Item.User.name"
            maxWidth="300px"
        >
          {{ Item.User.name }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.amount|percent }}
      </td>

      <td
          class="text-right fixed"
      >
        <template
            v-if="Item.updatable"
        >
          <Update
              :id="Item.id"
              @onUpdate="(response) => {
                init()

                $emit('refresh', response)
              }"
          />
        </template>

        <template
            v-if="Item.deletable"
        >
          &nbsp;

          <Delete
              :id="Item.id"
              @onDelete="(response) => {
                init()

                $emit('refresh', response)
              }"
          />
        </template>
      </td>
    </template>

    <template
        v-slot:list-body-extra="{
          Item
        }"
    >
      <td
          :colspan="3"
      >
        <IsEmptyHelper
            :value="Item.note"
            if-no="i"
            if-yes="i"
            empty="bez poznámky"
        >
          {{ Item.note }}
        </IsEmptyHelper>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import TicketShares from '@/services/ticket-shares'

  import CreateTicket from '@/components/TicketShares/CreateTicket'
  import Update from '@/components/TicketShares/Update'
  import Delete from '@/components/TicketShares/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketShares',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateTicket,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Podiely'
      }
    },
    methods: {
      fetchData(params) {
        return TicketShares
          .indexTicket({
            ticketId: this.Ticket.id,
            params: params
          })
      }
    }
  }
</script>
