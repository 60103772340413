<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="true"
          :hasExtra="false"
          :standardClass="(Item) => {
            return statusList[Item.status]?.class
          }"
          :extraClass="() => {}"
          :colspan="18"
          empty="Nenašli sa žiadne tikety."
          itemsKey="tickets"
          filterKey="Ticket"
          :defaultFilters="{
            status: null
          }"
          defaultOrder="tic.deliveredAt"
          defaultBy="DESC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <Create
                @onCreate="init"
            />

            &nbsp;
          </template>
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-row>
            <v-col
                cols="12"
                lg="3"
            >
              <v-select
                  v-model="params.Filters.status"
                  :error-messages="errors.Filters?.status"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Stav"
                  :items="statusesItems"
                  :clearable="true"
                  @change="setFilter"
              />
            </v-col>
          </v-row>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th>
            <OrderList
                orderKey="tic.deliveredAt"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="fixed"
          >
            Názov
          </th>

          <th>
            Stav
          </th>

          <th>
            <OrderList
                orderKey="tic.deadlineAtItems"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Nacenený čas
          </th>

          <th>
            Odpracovaný čas
          </th>

          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <th>
              Nacenená suma
            </th>

            <th>
              Odpracovaná suma
            </th>

            <th>
              Aktuálny podiel
            </th>

            <th>
              Odhadovaný podiel
            </th>
          </template>

          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <th>
              Cenová ponuka
            </th>

            <th>
              Fakturácia
            </th>

            <th>
              Zhrnutie
            </th>

            <th>
              Vlastník
            </th>
          </template>

          <th>
            Obchodník
          </th>

          <th>
            Manažér
          </th>

          <th
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            Klient
          </th>

          <th>
            Projekt
          </th>

          <th
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            Účet
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td>
            {{ Item.deliveredAt|moment('DD.MM.YYYY HH:mm') }}
          </td>

          <td
              class="fixed"
          >
            <TruncateHelper
                :title="Item.title"
                maxWidth="300px"
            >
              {{ Item.title }}
            </TruncateHelper>
          </td>

          <td>
            {{ statusList[Item.status]?.text }}
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.deadlineAtItems"
                if-no="span"
                if-yes="span"
            >
              <v-icon
                  v-if="Item.alertDeadlineAtItems && Item.printableAlert"
                  color="error"
              >
                mdi-alert
              </v-icon>

              {{ Item.deadlineAtItems|moment('DD.MM.YYYY') }}
            </IsEmptyHelper>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.printablePricing"
                if-no="span"
                if-yes="span"
            >
              {{ Item.timeEstimationItems|number }} h
            </IsEmptyHelper>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.printableInvoicing"
                if-no="span"
                if-yes="span"
            >
              <v-icon
                  v-if="Item.alertTimeSpendItems && Item.printableAlert"
                  color="error"
              >
                mdi-alert
              </v-icon>

              {{ Item.timeSpendItems|number }} h / {{ Item.maxTimeSpendItems|number }} h
            </IsEmptyHelper>
          </td>

          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <td>
              <IsEmptyHelper
                  :value="Item.printablePricing"
                  if-no="span"
                  if-yes="span"
              >
                {{ Item.priceEstimationItems|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.printableInvoicing"
                  if-no="span"
                  if-yes="span"
              >
                <v-icon
                    v-if="Item.alertPriceSpend && Item.printableAlert"
                    color="error"
                >
                  mdi-alert
                </v-icon>

                {{ Item.priceSpendItems|currency('€') }} / {{ Item.maxPriceSpend|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.printablePricing || Item.printableInvoicing"
                  if-no="span"
                  if-yes="span"
              >
                {{ Item.shareTotal|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.printablePricing || Item.printableInvoicing"
                  if-no="span"
                  if-yes="span"
              >
                {{ Item.shareTotalProbable|currency('€') }}
              </IsEmptyHelper>
            </td>
          </template>

          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <td>
              <IsEmptyHelper
                  :value="Item.printablePricing"
                  if-no="span"
                  if-yes="span"
              >
                {{ Item.pricingTotal|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.printableInvoicing"
                  if-no="span"
                  if-yes="span"
              >
                {{ Item.invoicingTotal|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.printableInvoicing"
                  if-no="v-chip"
                  if-yes="span"
                  :small="true"
                  :color="(
                    Item.balance >= 0
                      ? 'green'
                      : 'red'
                  )"
              >
                {{ Item.balance|currency('€') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="Item.Owner?.name"
                  if-no="span"
                  if-yes="span"
              >
                <TruncateHelper
                    :title="Item.Owner?.name"
                    maxWidth="300px"
                >
                  {{ Item.Owner?.name }}
                </TruncateHelper>
              </IsEmptyHelper>
            </td>
          </template>

          <td>
            <IsEmptyHelper
                :value="Item.Seller?.name"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.Seller?.name"
                  maxWidth="300px"
              >
                {{ Item.Seller?.name }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.Manager?.name"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.Manager?.name"
                  maxWidth="300px"
              >
                {{ Item.Manager?.name }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <IsEmptyHelper
                :value="Item.currentClientBillingName"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.currentClientBillingName"
                  maxWidth="300px"
              >
                {{ Item.currentClientBillingName }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td>
            <IsEmptyHelper
                :value="Item.currentProjectTitle"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.currentProjectTitle"
                  maxWidth="300px"
              >
                {{ Item.currentProjectTitle }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <IsEmptyHelper
                :value="Item.currentAccountBillingName"
                if-no="span"
                if-yes="span"
            >
              <TruncateHelper
                  :title="Item.currentAccountBillingName"
                  maxWidth="300px"
              >
                {{ Item.currentAccountBillingName }}
              </TruncateHelper>
            </IsEmptyHelper>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/ticket/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>

              <v-badge
                  color="error"
                  :value="Item.alertItems"
                  :content="Item.toWorkItems"
              />
            </v-btn>

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
            >
              <template
                  v-if="Item.updatable"
              >
                &nbsp;

                <Update
                    :id="Item.id"
                    @onUpdate="init"
                />
              </template>

              <template
                  v-if="Item.deletable"
              >
                &nbsp;

                <Delete
                  :id="Item.id"
                  @onDelete="init"
                />
              </template>
            </template>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Tickets from '@/services/tickets'

  import Create from '@/components/Tickets/Create'
  import Update from '@/components/Tickets/Update'
  import Delete from '@/components/Tickets/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Tickets',
    components: {
      Create,
      Update,
      Delete,
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.Ticket?.status
      },
      statusesItems: function () {
        return Object.values(this.statusList)
      }
    },
    methods: {
      fetchData(params) {
        return Tickets
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Tikety'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
