import Vue from 'vue'

import Qs from 'qs'

class Mails {
  async index({params}) {
    return await Vue.axios
      .get('app/mails', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/mails/' + id)
  }

  async duplicate({id, data}) {
    return await Vue.axios
      .post('app/mails/' + id + '/duplicate', data)
  }
}

const mails = new Mails()

export default mails
