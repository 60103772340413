<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Account"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.billingOnCompany"
              :error-messages="errors.billingOnCompany"
              :dense="true"
              hide-details="auto"
              label="Fakturovať na firmu"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.billingName"
              :error-messages="errors.billingName"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Spoločnosť"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.billingSign"
              :error-messages="errors.billingSign"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="V zastúpení"
          />
        </v-col>

        <template
            v-if="ItemForm.billingOnCompany"
        >
          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingBusinessId"
                :error-messages="errors.billingBusinessId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="IČO"
            />
          </v-col>

          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingTaxId"
                :error-messages="errors.billingTaxId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="DIČ"
            />
          </v-col>

          <v-col
              cols="12"
              lg="4"
          >
            <v-text-field
                v-model="ItemForm.billingVatId"
                :error-messages="errors.billingVatId"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="IČ DPH"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.billingStreet"
              :error-messages="errors.billingStreet"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Ulica"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.billingCity"
              :error-messages="errors.billingCity"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Mesto"
          />
        </v-col>

        <v-col
            cols="12"
            lg="2"
        >
          <v-text-field
              v-model="ItemForm.billingZip"
              :error-messages="errors.billingZip"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="PSČ"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <SuggestIndexesModule
              v-model="ItemForm.billingCountry"
              :error-messages="errors.billingCountry"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Krajina"
              :clearable="true"
              module="general_billing_country"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.Signature"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Podpis"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :dense="true"
                  align="center"
              >
                <v-col
                    v-if="signature"
                    cols="auto"
                >
                  <v-img
                      :src="$global.axiosUrl() + 'files/' + signature + '/generate/original'"
                      alt="Podpis"
                      max-width="300px"
                      max-height="100px"
                  />
                </v-col>

                <v-col
                    cols="auto"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <Signature
                          :small="true"
                          :id="Item.id"
                          @onSignature="(response) => {
                            ItemForm.Signature = response.data.data.id

                            signature = response.data.data.token
                          }"
                      />
                    </v-col>

                    <v-col
                        v-if="ItemForm.Signature && signature"
                        cols="12"
                    >
                      <v-btn
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="error"
                          @click="() => {
                            ItemForm.Signature = null

                            signature = null
                          }"
                      >
                        <v-icon>
                          mdi-auto-fix
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Accounts from '@/services/accounts'

  import Signature from '@/components/Accounts/Signature'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      Signature,
      UpdateSave,
      SuggestIndexesModule
    },
    data() {
      return {
        signature: null
      }
    },
    methods: {
      fetchData() {
        return Accounts
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť účet "' + Item.billingName + '"'
      },
      setItemForm(Item) {
        this.signature = Item.Signature?.token

        return {
          billingOnCompany: Item.billingOnCompany,
          billingName: Item.billingName,
          billingSign: Item.billingSign,
          billingBusinessId: Item.billingBusinessId,
          billingTaxId: Item.billingTaxId,
          billingVatId: Item.billingVatId,
          billingStreet: Item.billingStreet,
          billingCity: Item.billingCity,
          billingZip: Item.billingZip,
          billingCountry: Item.billingCountry,
          Signature: Item.Signature?.id
        }
      },
      updateData(ItemForm) {
        return Accounts
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
