<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Project"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdateNote', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <vue-editor
              v-model="ItemForm.note"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import ProjectsAuth from '@/services/projects-auth'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'UpdateNote',
    props: [
      'id'
    ],
    emits: [
      'onUpdateNote'
    ],
    components: {
      UpdateSave
    },
    methods: {
      fetchData() {
        return ProjectsAuth
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť projekt "' + Item.title + '"'
      },
      setItemForm(Item) {
        return {
          note: Item.note
        }
      },
      updateData(ItemForm) {
        return ProjectsAuth
          .updateNote({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
