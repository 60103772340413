var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 40px)","title":title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":7,"empty":"Nenašli sa žiadne súbory.","itemsKey":"files","filterKey":"File","defaultFilters":{
          dir: null,
          type: null,
          status: null
        },"defaultOrder":"fil.createdAt","defaultBy":"DESC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.dir,"outlined":true,"dense":true,"hide-details":"auto","label":"Priečinok","clearable":true,"module":"file_dir"},on:{"change":setFilter},model:{value:(params.Filters.dir),callback:function ($$v) {_vm.$set(params.Filters, "dir", $$v)},expression:"params.Filters.dir"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.type,"outlined":true,"dense":true,"hide-details":"auto","label":"Typ","clearable":true,"module":"file_type"},on:{"change":setFilter},model:{value:(params.Filters.type),callback:function ($$v) {_vm.$set(params.Filters, "type", $$v)},expression:"params.Filters.type"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","items":_vm.statuses,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',[_vm._v(" Priečinok ")]),_c('th',[_vm._v(" Názov ")]),_c('th',[_vm._v(" Typ ")]),_c('th',[_vm._v(" Stav ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"fil.createdAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_c('OrderList',{attrs:{"orderKey":"fil.updatedAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item
          }){return [_c('td',[_vm._v(" "+_vm._s(Item.dir)+" ")]),_c('td',[_c('a',{attrs:{"href":_vm.$global.axiosUrl() + 'files/' + Item.token + '/generate/original',"target":"_blank"}},[_c('TruncateHelper',{attrs:{"title":Item.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.name)+" ")])],1)]),_c('td',[_vm._v(" "+_vm._s(Item.type)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.statusList[Item.status]?.text)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.updatedAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/file/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }