<template>
  <v-row>
    <v-col
        cols="12"
    >
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-simple-table>
        <template
            v-slot:default
        >
          <thead>
            <tr>
              <slot
                  name="head"
                  :setOrderBy="setOrderBy"
              />
            </tr>
          </thead>

          <tbody>
            <template
                v-if="items.length > 0"
            >
              <template
                  v-for="(_Item) in items"
              >
                <tr
                    :key="_Item.id + 'standard'"
                    :class="standardClass(_Item)"
                >
                  <slot
                      name="body-standard"
                      :Item="_Item"
                  />
                </tr>

                <tr
                    v-if="hasExtra"
                    :key="_Item.id + 'extra'"
                    :class="'extra' + extraClass(_Item)"
                >
                  <slot
                      name="body-extra"
                      :Item="_Item"
                  />
                </tr>
              </template>
            </template>

            <tr
                v-else
            >
              <td
                  :colspan="colspan"
                  class="text-center"
              >
                {{ empty }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'List',
    props: [
      'hasExtra',
      'standardClass',
      'extraClass',
      'colspan',
      'empty',
      'loading',
      'items'
    ],
    emits: [
      'setParams'
    ],
    methods: {
      setOrderBy(order, by) {
        this.$emit('setParams', {
          order: order,
          by: by
        })
      }
    }
  }
</script>
