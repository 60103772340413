<template>
  <NotBlankPage
      maxWidth="calc(100% - 160px)"
      :hasBackLink="true"
      failLink="/index-groups"
      itemKey="IndexGroup"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="refresh"
      />

      &nbsp;

      <Delete
          :id="Item.id"
          @onDelete="onDelete"
      />

      &nbsp;
    </template>

    <template
        v-slot:default="{
          Item,
          refresh
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <Indexes
              :IndexGroup="Item"
              @refresh="refresh"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import IndexGroups from '@/services/index-groups'

  import Update from '@/components/IndexGroups/Update'
  import Delete from '@/components/IndexGroups/Delete'
  import Indexes from '@/components/IndexGroup/Indexes'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'IndexGroup',
    components: {
      Update,
      Delete,
      Indexes,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return IndexGroups
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Skupiny číselníkov',
            to: '/index-groups'
          }
        ]
      },
      onDelete() {
        setTimeout(() => {
          this.$router.push('/index-groups')
        }, 100)
      }
    }
  }
</script>
