<template>
  <FormPart
      :Item="Ticket"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.maxPriceSpend"
              :error-messages="errors.maxPriceSpend"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Maximálna odpracovaná suma"
              type="number"
              suffix="€"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Tickets from '@/services/tickets'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormAlert',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          maxPriceSpend: Item.maxPriceSpend
        }
      },
      submitData(ItemForm) {
        return Tickets
          .updateAlert({
            id: this.Ticket.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/tickets')
      }
    }
  }
</script>
