var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":_vm.title,"hasSearch":true,"hasFilter":false,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":3,"empty":"Nenašli sa žiadne číselníky.","itemsKey":"indexes","filterKey":"Index","defaultOrder":"ind.sort","defaultBy":"ASC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
        init
      }){return [_c('CreateIndexGroup',{attrs:{"indexGroupId":_vm.IndexGroup.id},on:{"onCreateIndexGroup":(response) => {
          init()

          _vm.$emit('refresh', response)
        }}}),_vm._v("   ")]}},{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"list-head",fn:function({
        order,
        by,
        orderList,
        byList,
        setOrderBy
      }){return [_c('th',{staticClass:"fixed"},[_vm._v(" Názov ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"ind.sort","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
        Item,
        init
      }){return [_c('td',{staticClass:"fixed"},[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(Item.sort)+" ")]),_c('td',{staticClass:"text-right fixed"},[_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":(response) => {
            init()

            _vm.$emit('refresh', response)
          }}}),_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":(response) => {
            init()

            _vm.$emit('refresh', response)
          }}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }