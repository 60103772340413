import Vue from 'vue'

import Qs from 'qs'

class NotificationsAuth {
  async index({params}) {
    return await Vue.axios
      .get('app/notifications/auth', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }
}

const notificationsAuth = new NotificationsAuth()

export default notificationsAuth
