import store from '@/plugins/store'

const Home = function (to, from, next) {
  store.dispatch('auth/check')
    .finally(() => {
      if (!(store.getters['auth/isLogged'] && store.getters['auth/hasRole'](to.meta.role))) {
        next('/login')
      }

      if (!store.getters['auth/isUnLogged']) {
        next('/dashboard')
      }
    })
}

export default Home
