<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="false"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="2"
      empty="Nenašli sa žiadne projekty."
      itemsKey="projects"
      filterKey="Project"
      defaultOrder="pro.title"
      defaultBy="ASC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <CreateClient
          :clientId="Client.id"
          @onCreateClient="(response) => {
            init()

            $emit('refresh', response)
          }"
      />

      &nbsp;
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="pro.title"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td
          class="text-right fixed"
      >
        <Update
            :id="Item.id"
            @onUpdate="(response) => {
              init()

              $emit('refresh', response)
            }"
        />

        <template
            v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
        >
          &nbsp;

          <Delete
              :id="Item.id"
              @onDelete="(response) => {
                init()

                $emit('refresh', response)
              }"
          />
        </template>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Projects from '@/services/projects'

  import CreateClient from '@/components/Projects/CreateClient'
  import Update from '@/components/Projects/Update'
  import Delete from '@/components/Projects/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Projects',
    props: [
      'Client'
    ],
    emits: [
      'refresh'
    ],
    components: {
      CreateClient,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper
    },
    data() {
      return {
        title: 'Projekty'
      }
    },
    methods: {
      fetchData(params) {
        return Projects
          .indexClient({
            clientId: this.Client.id,
            params: params
          })
      }
    }
  }
</script>
