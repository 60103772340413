var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalPart',_vm._b({attrs:{"color":_vm.color,"icon":_vm.icon,"maxWidth":_vm.maxWidth,"itemKey":_vm.itemKey,"fetchData":_vm.fetchData,"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        closeDialog
      }){return [_c('ConsentPart',{attrs:{"Item":Item,"setQuestion":_vm.setQuestion,"acceptData":_vm.confirmData},on:{"onSuccess":(response) => {
          _vm.$emit('onConfirm', response)

          closeDialog()
        },"onFail":closeDialog},scopedSlots:_vm._u([{key:"default",fn:function({
            loading,
            accept
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"rounded":true,"outlined":true,"loading":loading,"disabled":loading,"color":_vm.color},on:{"click":accept}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_vm._v(" Áno ")],1),_vm._v("   "),_c('v-btn',{attrs:{"rounded":true,"outlined":true,"color":"default"},on:{"click":closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")]),_vm._v(" Nie ")],1)],1)],1)]}}],null,true)})]}}])},'ModalPart',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }