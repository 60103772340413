<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="User"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="2"
        >
          <v-select
              v-model="ItemForm.gender"
              :error-messages="errors.gender"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Pohlavie"
              :items="genders"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.name"
              :error-messages="errors.name"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Meno"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.username"
              :error-messages="errors.username"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Užívateľské meno"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.emailBusiness"
              :error-messages="errors.emailBusiness"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail firemný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.emailPersonal"
              :error-messages="errors.emailPersonal"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail osobný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.phone"
              :error-messages="errors.phone"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Telefón"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-select
              v-model="ItemForm.type"
              :error-messages="errors.type"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Typ"
              :items="types"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.basePriceHour"
              :error-messages="errors.basePriceHour"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Základný plat"
              type="number"
              suffix="€ / h"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.minPriceHour"
              :error-messages="errors.minPriceHour"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Minimálny plat"
              type="number"
              suffix="€ / h"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Users from '@/services/users'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave
    },
    computed: {
      genderList: function () {
        return this.$store.state.core.Entity.User?.gender
      },
      genders: function () {
        return Object.values(this.genderList)
      },
      typeList: function () {
        return this.$store.state.core.Entity.User?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      fetchData() {
        return Users
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť užívateľa "' + Item.name + '"'
      },
      setItemForm(Item) {
        return {
          gender: Item.gender,
          name: Item.name,
          username: Item.username,
          emailBusiness: Item.emailBusiness,
          emailPersonal: Item.emailPersonal,
          phone: Item.phone,
          type: Item.type,
          basePriceHour: Item.basePriceHour,
          minPriceHour: Item.minPriceHour
        }
      },
      updateData(ItemForm) {
        return Users
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
