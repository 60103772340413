<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/audit-logs"
      itemKey="AuditLog"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="9"
        >
          <h3>
            Uri
          </h3>

          <p>
            <a
                :href="Item.uri"
                target="_blank"
            >
              {{ Item.uri }}
            </a>
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="3"
        >
          <h3>
            Užívateľ
          </h3>

          <IsEmptyHelper
              :value="Item.User?.name"
              if-no="p"
              if-yes="p"
          >
            {{ Item.User?.name }}
          </IsEmptyHelper>
        </v-col>

        <v-col
            cols="12"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-server"
              title="Server"
          >
            <template
                v-slot:default
            >
              <vue-json-pretty
                  :data="Item.server"
                  :deep="0"
              />
            </template>
          </PanelPart>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-row>
            <v-col
                cols="12"
            >
              <h3>
                Dátum a čas požiadavky
              </h3>

              <p>
                {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
              </p>
            </v-col>

            <v-col
                cols="12"
            >
              <h3>
                Metóda
              </h3>

              <p>
                {{ Item.method }}
              </p>
            </v-col>

            <v-col
                cols="12"
            >
              <PanelPart
                  maxWidth="100%"
                  color="primary"
                  icon="mdi-share"
                  title="Požiadavka"
              >
                <template
                    v-slot:default
                >
                  <IsEmptyHelper
                      :value="Item.request"
                      if-no="vue-json-pretty"
                      if-yes="p"
                      :data="Item.request"
                      :deep="0"
                  />
                </template>
              </PanelPart>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-row>
            <v-col
                cols="12"
            >
              <h3>
                Dátum a čas odpovede
              </h3>

              <IsEmptyHelper
                  :value="Item.updatedAt"
                  if-no="p"
                  if-yes="p"
              >
                {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
              </IsEmptyHelper>
            </v-col>

            <v-col
                cols="12"
            >
              <h3>
                Stavový kód
              </h3>

              <IsEmptyHelper
                  :value="Item.statusCode"
                  if-no="p"
                  if-yes="p"
              >
                {{ Item.statusCode }}
              </IsEmptyHelper>
            </v-col>

            <v-col
                cols="12"
            >
              <PanelPart
                  maxWidth="100%"
                  color="primary"
                  icon="mdi-reply"
                  title="Odpoveď"
              >
                <template
                    v-slot:default
                >
                  <IsEmptyHelper
                      :value="Item.response"
                      if-no="vue-json-pretty"
                      if-yes="p"
                      :data="Item.response"
                      :deep="0"
                  />
                </template>
              </PanelPart>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import AuditLogs from '@/services/audit-logs'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import PanelPart from '@/components/_Part/Panel'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'AuditLog',
    components: {
      NotBlankPage,
      PanelPart,
      IsEmptyHelper
    },
    methods: {
      fetchData() {
        return AuditLogs
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Audit logy',
            to: '/audit-logs'
          }
        ]
      }
    }
  }
</script>
