<template>
  <ConfirmModal
      color="success"
      icon="mdi-check"
      :maxWidth="500"
      itemKey="TicketItemTask"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="updateStatusDoneData"
      @onConfirm="(response) => $emit('onUpdateStatusDone', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import TicketItemTasksAuth from '@/services/ticket-item-tasks-auth'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'UpdateStatusDone',
    props: [
      'id'
    ],
    emits: [
      'onUpdateStatusDone'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return TicketItemTasksAuth
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Označiť úlohu z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name + ' ako dokončenú'
      },
      setQuestion(Item) {
        return 'Naozaj chcete označiť úlohu z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name + ' ako dokončenú?'
      },
      updateStatusDoneData() {
        return TicketItemTasksAuth
          .updateStatusDone({
            id: this.id
          })
      }
    }
  }
</script>
