<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="false"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="2"
          empty="Nenašli sa žiadne účty."
          itemsKey="accounts"
          filterKey="Account"
          defaultOrder="acc.billingName"
          defaultBy="ASC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          &nbsp;
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th
              class="fixed"
          >
            <OrderList
                orderKey="acc.billingName"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="fixed"
          >
            <v-icon>
              <template
                  v-if="Item.billingOnCompany"
              >
                mdi-domain
              </template>

              <template
                  v-else
              >
                mdi-account
              </template>
            </v-icon>

            &nbsp;

            <TruncateHelper
                :title="Item.billingName"
                maxWidth="300px"
            >
              {{ Item.billingName }}
            </TruncateHelper>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/finance/' + Item.id"
            >
              <v-icon>
                mdi-cash
              </v-icon>
            </v-btn>

            &nbsp;

            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            <template
                v-if="Item.AccountData.deletable"
            >
              &nbsp;

              <Delete
                  :id="Item.id"
                  @onDelete="init"
              />
            </template>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Accounts from '@/services/accounts'

  import Create from '@/components/Accounts/Create'
  import Update from '@/components/Accounts/Update'
  import Delete from '@/components/Accounts/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Accounts',
    components: {
      Create,
      Update,
      Delete,
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper
    },
    methods: {
      fetchData(params) {
        return Accounts
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Účty'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
