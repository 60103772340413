<template>
  <GridPart
      maxWidth="calc(100% - 80px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="true"
      :hasExtra="true"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="6"
      empty="Nenašli sa žiadne príjmy."
      itemsKey="revenues"
      filterKey="Revenue"
      :defaultFilters="{
        pay: null
      }"
      defaultOrder="rev.paidAt"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <CreateAccount
          :accountId="Account.id"
          @onCreateAccount="init"
      />

      &nbsp;
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:filter="{
          params,
          errors,
          setFilter
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="3"
        >
          <v-select
              v-model="params.Filters.pay"
              :error-messages="errors.Filters?.pay"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Platba"
              :items="pays"
              :clearable="true"
              @change="setFilter"
          />
        </v-col>
      </v-row>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="rev.paidAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Názov
      </th>

      <th>
        Suma
      </th>

      <th>
        Zostatok
      </th>

      <th>
        Platba
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="fixed"
      >
        {{ Item.paidAt|moment('DD.MM.YYYY') }}
      </td>

      <td>
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.price|currency('€') }}
      </td>

      <td>
        <v-icon
            v-if="Item.alertBalance"
            color="error"
        >
          mdi-alert
        </v-icon>

        {{ Item.balance|currency('€') }}
      </td>

      <td>
        {{ payList[Item.pay].text }}
      </td>

      <td
          class="text-right fixed"
      >
        <Update
            :id="Item.id"
            @onUpdate="init"
        />

        <template
            v-if="Item.deletable"
        >
          &nbsp;

          <Delete
              :id="Item.id"
              @onDelete="init"
          />
        </template>
      </td>
    </template>

    <template
        v-slot:list-body-extra="{
          Item
        }"
    >
      <td
          :colspan="6"
      >
        <IsEmptyHelper
            :value="Item.note"
            if-no="i"
            if-yes="i"
            empty="bez poznámky"
        >
          {{ Item.note }}
        </IsEmptyHelper>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Revenues from '@/services/revenues'

  import CreateAccount from '@/components/Revenues/CreateAccount'
  import Update from '@/components/Revenues/Update'
  import Delete from '@/components/Revenues/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Revenues',
    props: [
      'Account'
    ],
    components: {
      CreateAccount,
      Update,
      Delete,
      GridPart,
      OrderList,
      TruncateHelper,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Príjmy'
      }
    },
    computed: {
      payList: function () {
        return this.$store.state.core.Entity.Revenue?.pay
      },
      pays: function () {
        return Object.values(this.payList)
      }
    },
    methods: {
      fetchData(params) {
        return Revenues
          .indexAccount({
            accountId: this.Account.id,
            params: params
          })
      }
    }
  }
</script>
