<template>
  <FormPart
      :Item="Ticket"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestClients
              v-model="Client"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Klient"
              :clearable="true"
              :createButton="true"
              @change="() => {
                ItemForm.Project = null

                submit()
              }"
          />
        </v-col>

        <v-col
            v-if="Client"
            :key="Client"
            cols="12"
        >
          <SuggestProjectsClient
              v-model="ItemForm.Project"
              :error-messages="errors.Project"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Projekt"
              :clearable="true"
              :clientId="Client"
              :createButton="true"
              @change="submit"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <SuggestAccounts
              v-model="ItemForm.Account"
              :error-messages="errors.Account"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Účet"
              :clearable="true"
              :createButton="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Tickets from '@/services/tickets'

  import FormPart from '@/components/_Part/Form'

  import SuggestClients from '@/components/Clients/Suggest'
  import SuggestProjectsClient from '@/components/Projects/SuggestClient'
  import SuggestAccounts from '@/components/Accounts/Suggest'

  export default {
    name: 'FormSettings',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart,
      SuggestClients,
      SuggestProjectsClient,
      SuggestAccounts
    },
    data() {
      return {
        Client: null
      }
    },
    methods: {
      setItemForm(Item) {
        this.Client = Item.Project?.Client?.id

        return {
          Project: Item.Project?.id,
          Account: Item.Account?.id
        }
      },
      submitData(ItemForm) {
        return Tickets
          .updateSettings({
            id: this.Ticket.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/tickets')
      }
    }
  }
</script>
