<template>
  <DeleteConfirm
      itemKey="TicketExpense"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :deleteData="deleteData"
      @onDelete="(response) => $emit('onDelete', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import TicketExpenses from '@/services/ticket-expenses'

  import DeleteConfirm from '@/components/_Part/_Modal/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: [
      'id'
    ],
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    methods: {
      fetchData() {
        return TicketExpenses
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Odstrániť výdaj "' + Item.Expense.title + '" z ' + this.$moment(Item.Expense.paidAt).format('DD.MM.YYYY')
      },
      setQuestion(Item) {
        return 'Naozaj chcete odstrániť výdaj "' + Item.Expense.title + '" z ' + this.$moment(Item.Expense.paidAt).format('DD.MM.YYYY') + '?'
      },
      deleteData() {
        return TicketExpenses
          .delete({
            id: this.id
          })
      }
    }
  }
</script>
