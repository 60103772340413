<template>
  <SuggestPart
      item-value="id"
      :item-text="(Item) => {
        if (Item.closedAt) {
          return this.$moment(Item.closedAt).format('MM/YYYY')
        } else {
          return 'Neuzavreté'
        }
      }"
      itemsKey="closings"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import Closings from '@/services/closings'

  import SuggestPart from '@/components/_Part/Suggest'

  export default {
    name: 'Suggest',
    components: {
      SuggestPart
    },
    methods: {
      fetchData() {
        return Closings
          .suggest()
      }
    }
  }
</script>
