<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="Expense"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestAccounts
              v-model="ItemForm.Account"
              :error-messages="errors.Account"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Účet"
              :clearable="true"
              :createButton="true"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.paidAt"
              :error-messages="errors.paidAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum uhradenia"
              type="date"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.price"
              :error-messages="errors.price"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Suma"
              type="number"
              suffix="€"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-select
              v-model="ItemForm.pay"
              :error-messages="errors.pay"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Platba"
              :items="pays"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.note"
              :error-messages="errors.note"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Expenses from '@/services/expenses'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestAccounts from '@/components/Accounts/Suggest'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestAccounts
    },
    computed: {
      payList: function () {
        return this.$store.state.core.Entity.Expense?.pay
      },
      pays: function () {
        return Object.values(this.payList)
      }
    },
    methods: {
      fetchData() {
        return Expenses
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť výdaj "' + Item.title + '" z ' + this.$moment(Item.paidAt).format('DD.MM.YYYY')
      },
      setItemForm(Item) {
        return {
          Account: Item.Account.id,
          paidAt: this.$moment(Item.paidAt).format('YYYY-MM-DD'),
          title: Item.title,
          price: Item.price,
          pay: Item.pay,
          note: Item.note
        }
      },
      updateData(ItemForm) {
        return Expenses
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
