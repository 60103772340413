<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/new-password/restore"
      itemKey="User"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <Form
          :User="Item"
      />
    </template>
  </NotBlankPage>
</template>

<script>
  import UserNewPassword from '@/services/user-new-password'

  import Form from '@/components/NewPasswordSet/Form'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'NewPasswordSet',
    components: {
      Form,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return UserNewPassword
          .exist({
            newPasswordToken: this.$route.params.newPasswordToken
          })
      },
      setTitle() {
        return 'Nastaviť nové heslo'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Vyžiadať nové heslo',
            to: '/new-password/restore'
          }
        ]
      }
    }
  }
</script>
