<template>
  <DeleteConfirm
      itemKey="Client"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :deleteData="deleteData"
      @onDelete="(response) => $emit('onDelete', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Clients from '@/services/clients'

  import DeleteConfirm from '@/components/_Part/_Modal/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: [
      'id'
    ],
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    methods: {
      fetchData() {
        return Clients
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Odstrániť klienta "' + Item.billingName + '"'
      },
      setQuestion(Item) {
        return 'Naozaj chcete odstrániť klienta "' + Item.billingName + '"?'
      },
      deleteData() {
        return Clients
          .delete({
            id: this.id
          })
      }
    }
  }
</script>
