import Vue from 'vue'

class TicketItemCommentsAuth {
  async createTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .post('app/ticket-item-comments/auth/by-ticket-item/' + ticketItemId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-comments/auth/' + id)
  }

  async updateStatusImportant({id}) {
    return await Vue.axios
      .put('app/ticket-item-comments/auth/' + id + '/status/important')
  }

  async updateStatusUnimportant({id}) {
    return await Vue.axios
      .put('app/ticket-item-comments/auth/' + id + '/status/unimportant')
  }

  async uploadFile({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/ticket-item-comments/auth/' + id + '/upload/file', data)
    } else {
      return await Vue.axios
        .post('app/ticket-item-comments/auth/upload/file', data)
    }
  }
}

const ticketItemCommentsAuth = new TicketItemCommentsAuth()

export default ticketItemCommentsAuth
