<template>
  <DeleteConfirm
      itemKey="TicketItem"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :deleteData="deleteData"
      @onDelete="(response) => $emit('onDelete', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import DeleteConfirm from '@/components/_Part/_Modal/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: [
      'id'
    ],
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    methods: {
      fetchData() {
        return TicketItems
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Odstrániť položku "' + Item.title + '"'
      },
      setQuestion(Item) {
        return 'Naozaj chcete odstrániť položku "' + Item.title + '"?'
      },
      deleteData() {
        return TicketItems
          .delete({
            id: this.id
          })
      }
    }
  }
</script>
