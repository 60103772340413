<template>
  <v-row>
    <v-col
        cols="12"
    >
      <h3>
        Provízie
      </h3>

      <p>
        {{ Wage.priceCommissions|currency('€') }}

        <template
            v-if="Wage.priceCommissionsBank || Wage.priceCommissionsCash"
        >
          <br/>

          <small>
            <template
                v-if="Wage.priceCommissionsBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Wage.priceCommissionsBank|currency('€') }}
            </template>

            <template
                v-if="Wage.priceCommissionsCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Wage.priceCommissionsCash|currency('€') }}
            </template>
          </small>
        </template>
      </p>

      <IsEmptyHelper
          :value="Wage.wageTicketCommissions.length > 0"
          if-no="table"
          if-yes="p"
          :no-gutters="true"
      >
        <thead>
          <tr>
            <th
                width="2.5%"
            >
              <v-checkbox
                  v-model="all"
                  :dense="true"
                  hide-details="auto"
                  @change="(value) => {
                    ids = []

                    total = 0

                    if (value) {
                      Wage.wageTicketCommissions.forEach((_WageTicketCommission) => {
                        ids.push(_WageTicketCommission.TicketCommission.id)

                        total += _WageTicketCommission.price
                      })
                    }
                  }"
              />
            </th>

            <th
                width="10%"
                align="left"
            >
              Vykonanie
            </th>

            <th
                width="17.5%"
                align="left"
            >
              Projekt
            </th>

            <th
                width="20%"
                align="left"
            />

            <th
                width="10%"
                align="left"
            >
              Suma
            </th>

            <th
                width="10%"
                align="left"
            >
              Stav
            </th>

            <th
                width="7.5%"
                align="left"
            >
              Uhradenie
            </th>

            <th
                width="7.5%"
                align="left"
            >
              Platba
            </th>

            <th
                width="15%"
                align="left"
            >
              Účet
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
              v-for="(_WageTicketCommission) in Wage.wageTicketCommissions"
              :key="_WageTicketCommission.TicketCommission.id"
              valign="top"
          >
            <td>
              <v-checkbox
                  v-model="ids"
                  :value="_WageTicketCommission.TicketCommission.id"
                  :dense="true"
                  hide-details="auto"
                  @change="() => {
                    if (ids.length === Wage.wageTicketCommissions.length) {
                      all = true
                    } else {
                      all = false
                    }

                    if (ids.includes(_WageTicketCommission.TicketCommission.id)) {
                      total += _WageTicketCommission.price
                    } else {
                      total -= _WageTicketCommission.price
                    }
                  }"
              />
            </td>

            <td>
              {{ _WageTicketCommission.workedAt|moment('DD.MM.YYYY') }}
            </td>

            <td>
              {{ _WageTicketCommission.currentProjectTitle }}
            </td>

            <td/>

            <td>
              {{ _WageTicketCommission.price|currency('€') }}
            </td>

            <td>
              {{ statusList[_WageTicketCommission.status]?.text }}
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketCommission.paidAt"
                  if-no="span"
                  if-yes="span"
              >
                {{ _WageTicketCommission.paidAt|moment('DD.MM.YYYY') }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketCommission.pay"
                  if-no="span"
                  if-yes="span"
              >
                {{ payList[_WageTicketCommission.pay]?.text }}
              </IsEmptyHelper>
            </td>

            <td>
              <IsEmptyHelper
                  :value="_WageTicketCommission.accountBillingName"
                  if-no="span"
                  if-yes="span"
              >
                {{ _WageTicketCommission.accountBillingName }}
              </IsEmptyHelper>
            </td>
          </tr>
        </tbody>

        <tfoot
            v-if="ids.length > 0"
        >
          <tr>
            <th
                align="left"
            >
              <UpdateMassTicketCommission
                  :x-small="true"
                  :closingId="Wage.Closing.id"
                  :ids="ids"
                  @onUpdateMass="$emit('refresh')"
              />
            </th>

            <th
                align="left"
                :colspan="3"
            />

            <th
                align="left"
            >
              {{ total|currency('€') }}
            </th>

            <th
                align="left"
                :colspan="4"
            />
          </tr>
        </tfoot>
      </IsEmptyHelper>
    </v-col>
  </v-row>
</template>

<script>
  import UpdateMassTicketCommission from '@/components/TicketCommissions/UpdateMass'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketCommissions',
    props: [
      'Wage'
    ],
    emits: [
      'refresh'
    ],
    components: {
      UpdateMassTicketCommission,
      IsEmptyHelper
    },
    data() {
      return {
        all: false,
        ids: [],
        total: 0
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.WageTicketCommission?.status
      },
      payList: function () {
        return this.$store.state.core.Entity.WageTicketCommission?.pay
      }
    }
  }
</script>
