<template>
  <SuggestPart
      item-value="title"
      item-text="title"
      itemsKey="indexes"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import Indexes from '@/services/indexes'

  import SuggestPart from '@/components/_Part/Suggest'

  export default {
    name: 'SuggestModule',
    props: [
      'module'
    ],
    components: {
      SuggestPart
    },
    methods: {
      fetchData() {
        return Indexes
          .suggestModule({
            module: this.module
          })
      }
    }
  }
</script>
