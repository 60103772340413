<template>
  <NotBlankPage
      maxWidth="calc(100% - 200px)"
      :hasBackLink="true"
      failLink="/tickets"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <template
          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
      >
        <Duplicate
            :id="Item.id"
        />

        &nbsp;

        <template
            v-if="Item.updatable"
        >
          <Update
              :id="Item.id"
              @onUpdate="refresh"
          />

          &nbsp;
        </template>

        <template
            v-if="Item.deletable"
        >
          <Delete
              :id="Item.id"
              @onDelete="onDelete"
          />

          &nbsp;
        </template>
      </template>
    </template>

    <template
        v-slot:default="{
          Item,
          refresh
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            E-mail
          </h3>

          <IsEmptyHelper
              :value="Item.email"
              if-no="p"
              if-yes="p"
          >
            <a
                :href="'mailto: ' + Item.email"
            >
              {{ Item.email }}

              <template
                  v-if="Item.name"
              >
                ({{ Item.name }})
              </template>
            </a>
          </IsEmptyHelper>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Predmet
          </h3>

          <IsEmptyHelper
              :value="Item.subject"
              if-no="p"
              if-yes="p"
          >
            {{ Item.subject }}
          </IsEmptyHelper>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Dátum a čas doručenia
          </h3>

          <p>
            {{ Item.deliveredAt|moment('DD.MM.YYYY HH:mm') }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-xml"
              title="HTML"
          >
            <template
                v-slot:default
            >
              <IsEmptyHelper
                  :value="Item.html"
                  if-no="iframe"
                  if-yes="p"
                  :srcdoc="Item.html"
                  width="100%"
                  height="500px"
              />
            </template>
          </PanelPart>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-text"
              title="Text"
          >
            <template
                v-slot:default
            >
              <IsEmptyHelper
                  :value="Item.text"
                  if-no="p"
                  if-yes="p"
              >
                {{ Item.text }}
              </IsEmptyHelper>
            </template>
          </PanelPart>
        </v-col>

        <template
            v-if="Item.showableTask"
        >
          <v-col
              cols="12"
          >
            <PanelPart
                maxWidth="calc(100% - 40px)"
                color="primary"
                icon="mdi-content-copy"
                title="Zadanie"
            >
              <template
                  v-slot:action
              >
                <template
                    v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
                >
                  <template
                      v-if="Item.updatableTask"
                  >
                    <v-spacer/>

                    <UpdateTask
                        :small="true"
                        :id="Item.id"
                        @onUpdateTask="refresh"
                    />
                  </template>
                </template>
              </template>

              <template
                  v-slot:default
              >
                <IsEmptyHelper
                    :value="Item.content"
                    if-no="div"
                    if-yes="p"
                >
                  <div
                      v-html="Item.content"
                  />
                </IsEmptyHelper>
              </template>
            </PanelPart>
          </v-col>

          <v-col
              cols="12"
          >
            <PanelPart
                maxWidth="calc(100% - 40px)"
                color="primary"
                icon="mdi-attachment"
                :title="'Prílohy (' + Item.files.length + ')'"
            >
              <template
                  v-slot:action
              >
                <template
                    v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
                >
                  <template
                      v-if="Item.updatableTask"
                  >
                    <v-spacer/>

                    <UpdateTask
                        :small="true"
                        :id="Item.id"
                        @onUpdateTask="refresh"
                    />
                  </template>
                </template>
              </template>

              <template
                  v-slot:default
              >
                <IsEmptyHelper
                    :value="Item.files.length > 0"
                    if-no="v-row"
                    if-yes="p"
                    empty="žiadne"
                >
                  <v-col
                      v-for="(_File) in Item.files"
                      :key="_File.id"
                      cols="auto"
                      align="center"
                  >
                    <a
                        :href="$global.axiosUrl() + 'files/'  + _File.token + '/generate/original'"
                        target="_blank"
                    >
                      <v-icon
                          :large="true"
                      >
                        mdi-file
                      </v-icon>

                      <br/>

                      {{ _File.name }}
                    </a>
                  </v-col>
                </IsEmptyHelper>
              </template>
            </PanelPart>
          </v-col>
        </template>

        <v-col
            cols="12"
            lg="3"
        >
          <CardPart
              maxWidth="100%"
              title="Informácie"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Stav
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <p>
                        {{ statusList[Item.status]?.text }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Dátum dokončenia
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.deadlineAtItems"
                          if-no="p"
                          if-yes="p"
                      >
                        <v-icon
                            v-if="Item.alertDeadlineAtItems && Item.printableAlert"
                            color="error"
                        >
                          mdi-alert
                        </v-icon>

                        {{ Item.deadlineAtItems|moment('DD.MM.YYYY') }}
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Nacenený čas
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.printablePricing"
                          if-no="p"
                          if-yes="p"
                      >
                        {{ Item.timeEstimationItems|number }} h
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Odpracovaný čas
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.printableInvoicing"
                          if-no="p"
                          if-yes="p"
                      >
                        <v-icon
                            v-if="Item.alertTimeSpendItems && Item.printableAlert"
                            color="error"
                        >
                          mdi-alert
                        </v-icon>

                        {{ Item.timeSpendItems|number }} h / {{ Item.maxTimeSpendItems|number }} h
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <template
                    v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Nacenená suma
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.printablePricing"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.priceEstimationItems|currency('€') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Odpracovaná suma
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.printableInvoicing"
                            if-no="p"
                            if-yes="p"
                        >
                          <v-icon
                              v-if="Item.alertPriceSpend && Item.printableAlert"
                              color="error"
                          >
                            mdi-alert
                          </v-icon>

                          {{ Item.priceSpendItems|currency('€') }} / {{ Item.maxPriceSpend|currency('€') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Aktuálny podiel
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.printablePricing || Item.printableInvoicing"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.shareTotal|currency('€') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Odhadovaný podiel
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.printablePricing || Item.printableInvoicing"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.shareTotalProbable|currency('€') }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>

                  <template
                      v-if="Item.showableAlert"
                  >
                    <template
                        v-if="Item.updatableAlert"
                    >
                      <v-col
                          cols="12"
                      >
                        <v-divider/>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <FormAlert
                            :Ticket="Item"
                            @refresh="refresh"
                        />
                      </v-col>
                    </template>
                  </template>

                  <template
                      v-if="Item.showableAssignment"
                  >
                    <v-col
                        cols="12"
                    >
                      <v-divider/>
                    </v-col>

                    <v-col
                        v-if="Item.updatableAssignment"
                        cols="12"
                    >
                      <FormAssignment
                          :Ticket="Item"
                          @refresh="refresh"
                      />
                    </v-col>

                    <template
                        v-else
                    >
                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Vlastník
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.Owner?.name"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Owner?.name }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Obchodník
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.Seller?.name"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Seller?.name }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Manažér
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.Manager?.name"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Manager?.name }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>
                    </template>
                  </template>

                  <template
                      v-if="Item.showableSettings"
                  >
                    <v-col
                        cols="12"
                    >
                      <v-divider/>
                    </v-col>

                    <v-col
                        v-if="Item.updatableSettings"
                        cols="12"
                    >
                      <FormSettings
                          :Ticket="Item"
                          @refresh="refresh"
                      />
                    </v-col>

                    <template
                        v-else
                    >
                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Klient
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.currentClientBillingName"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.currentClientBillingName }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Projekt
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.currentProjectTitle"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.currentProjectTitle }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-row
                            align="center"
                        >
                          <v-col
                              cols="6"
                          >
                            <h4>
                              Účet
                            </h4>
                          </v-col>

                          <v-col
                              cols="6"
                          >
                            <IsEmptyHelper
                                :value="Item.currentAccountBillingName"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.currentAccountBillingName }}
                            </IsEmptyHelper>
                          </v-col>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>

                <template
                    v-else
                >
                  <template
                      v-if="Item.showableAssignment"
                  >
                    <v-col
                        cols="12"
                    >
                      <v-divider/>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-row
                          align="center"
                      >
                        <v-col
                            cols="6"
                        >
                          <h4>
                            Obchodník
                          </h4>
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <IsEmptyHelper
                              :value="Item.Seller?.name"
                              if-no="p"
                              if-yes="p"
                          >
                            {{ Item.Seller?.name }}
                          </IsEmptyHelper>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-row
                          align="center"
                      >
                        <v-col
                            cols="6"
                        >
                          <h4>
                            Manažér
                          </h4>
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <IsEmptyHelper
                              :value="Item.Manager?.name"
                              if-no="p"
                              if-yes="p"
                          >
                            {{ Item.Manager?.name }}
                          </IsEmptyHelper>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>

                  <template
                      v-if="Item.showableSettings"
                  >
                    <v-col
                        cols="12"
                    >
                      <v-divider/>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-row
                          align="center"
                      >
                        <v-col
                            cols="6"
                        >
                          <h4>
                            Projekt
                          </h4>
                        </v-col>

                        <v-col
                            cols="6"
                        >
                          <IsEmptyHelper
                              :value="Item.currentProjectTitle"
                              if-no="p"
                              if-yes="p"
                          >
                            {{ Item.currentProjectTitle }}
                          </IsEmptyHelper>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
            lg="9"
        >
          <template
              v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
          >
            <template
                v-if="Item.showableType"
            >
              <v-row
                  v-if="Item.typeChoosable"
              >
                <v-col
                    cols="12"
                    lg="6"
                >
                  <CardPart
                      maxWidth="100%"
                      title="Cenová ponuka"
                  >
                    <template
                        v-slot:default
                    >
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            ...
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                          <FormTypeChoosePricing
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </CardPart>
                </v-col>

                <v-col
                    cols="12"
                    lg="6"
                >
                  <CardPart
                      maxWidth="100%"
                      title="Bez obmedzenia"
                  >
                    <template
                        v-slot:default
                    >
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <p>
                            ...
                          </p>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                          <FormTypeChooseUnlimited
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </CardPart>
                </v-col>
              </v-row>
            </template>
          </template>

          <template
              v-if="Item.showableOther"
          >
            <v-tabs
                v-model="tabs"
            >
              <v-tab
                  v-if="Item.showableTicketItems"
              >
                Položky

                &nbsp;

                <v-chip>
                  <v-icon
                      v-if="Item.alertItems && Item.printableAlert"
                      color="error"
                  >
                    mdi-alert
                  </v-icon>

                  {{ Item.inArchiveItems }} / {{ Item.totalItems }}
                </v-chip>
              </v-tab>

              <template
                  v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
              >
                <v-tab
                    v-if="Item.showableTicketShares"
                >
                  Podiely

                  &nbsp;

                  <v-chip>
                    {{ Item.amountShares|percent }}
                  </v-chip>
                </v-tab>

                <v-tab
                    v-if="Item.showablePricing"
                >
                  Cenová ponuka

                  &nbsp;

                  <v-chip>
                    {{ Item.pricingTotal|currency('€') }}
                  </v-chip>
                </v-tab>

                <v-tab
                    v-if="Item.showableInvoicing && Item.showableProtocol"
                >
                  Fakturácia & Protokol

                  &nbsp;

                  <v-chip>
                    {{ Item.invoicingTotal|currency('€') }}
                  </v-chip>
                </v-tab>

                <v-tab
                    v-if="Item.showableSum"
                >
                  Zhrnutie

                  &nbsp;

                  <v-chip
                      :small="true"
                      :color="(
                        Item.balance >= 0
                          ? 'green'
                          : 'red'
                      )"
                  >
                    {{ Item.balance|currency('€') }}
                  </v-chip>
                </v-tab>
              </template>
            </v-tabs>

            <v-tabs-items
                v-model="tabs"
            >
              <v-tab-item
                  v-if="Item.showableTicketItems"
              >
                <TicketItems
                    :Ticket="Item"
                    @refresh="refresh"
                />
              </v-tab-item>

              <template
                  v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_SELLER')"
              >
                <v-tab-item
                    v-if="Item.showableTicketShares"
                >
                  <TicketShares
                      :Ticket="Item"
                      @refresh="refresh"
                  />
                </v-tab-item>

                <v-tab-item
                    v-if="Item.showablePricing"
                >
                  <CardPart
                      maxWidth="calc(100% - 120px)"
                      title="Cenová ponuka"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="Item.pricingSendable || Item.pricingWaitable || Item.pricingGeneratable"
                      >
                        <v-spacer/>
                      </template>

                      <template
                          v-if="Item.pricingSendable"
                      >
                        <PricingSkipAndAccept
                            :id="Item.id"
                            @onPricingSkipAndAccept="refresh"
                        />
                      </template>

                      <template
                          v-if="Item.pricingWaitable"
                      >
                        <PricingAccept
                            :id="Item.id"
                            @onPricingAccept="refresh"
                        />

                        &nbsp;

                        <PricingReject
                            :id="Item.id"
                            @onPricingReject="refresh"
                        />
                      </template>

                      <template
                          v-if="Item.pricingGeneratable"
                      >
                        &nbsp;

                        <v-btn
                            :icon="true"
                            :outlined="true"
                            color="primary"
                            :href="$global.axiosUrl() + 'tickets/'  + Item.token + '/generate/pricing'"
                            target="_blank"
                        >
                          <v-icon>
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <v-row>
                        <template
                            v-if="Item.pricingSendable"
                        >
                          <v-col
                              cols="12"
                          >
                            <h3>
                              Projekt
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Project"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Project?.title }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dodávateľ
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Account"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Account?.billingName }}

                              <br/>

                              v zastúpení: {{ Item.Account?.billingSign }}

                              <template
                                  v-if="Item.Account?.billingOnCompany"
                              >
                                <template
                                    v-if="Item.Account?.billingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.Account?.billingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.Account?.billingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.Account?.billingTaxId }}
                                </template>

                                <template
                                    v-if="Item.Account?.billingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.Account?.billingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.Account?.billingStreet }}

                              <br/>

                              {{ Item.Account?.billingZip }} {{ Item.Account?.billingCity }}

                              <br/>

                              {{ Item.Account?.billingCountry }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Objednávateľ
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Project?.Client"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Project?.Client?.billingName }}

                              <br/>

                              v zastúpení: {{ Item.Project?.Client?.billingSign }}

                              <template
                                  v-if="Item.Project?.Client?.billingOnCompany"
                              >
                                <template
                                    v-if="Item.Project?.Client?.billingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.Project?.Client?.billingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.Project?.Client?.billingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.Project?.Client?.billingTaxId }}
                                </template>

                                <template
                                    v-if="Item.Project?.Client?.billingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.Project?.Client?.billingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.Project?.Client?.billingStreet }}

                              <br/>

                              {{ Item.Project?.Client?.billingZip }} {{ Item.Project?.Client?.billingCity }}

                              <br/>

                              {{ Item.Project?.Client?.billingCountry }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <PricingItemsInfo
                                :Ticket="Item"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <FormPricingSend
                                :Ticket="Item"
                                @refresh="refresh"
                            />
                          </v-col>
                        </template>

                        <template
                            v-else
                        >
                          <v-col
                              cols="12"
                          >
                            <h3>
                              Projekt
                            </h3>

                            <p>
                              {{ Item.pricingProjectTitle }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dodávateľ
                            </h3>

                            <p>
                              {{ Item.pricingAccountBillingName }}

                              <br/>

                              v zastúpení: {{ Item.pricingAccountBillingSign }}

                              <template
                                  v-if="Item.pricingAccountBillingOnCompany"
                              >
                                <template
                                    v-if="Item.pricingAccountBillingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.pricingAccountBillingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.pricingAccountBillingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.pricingAccountBillingTaxId }}
                                </template>

                                <template
                                    v-if="Item.pricingAccountBillingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.pricingAccountBillingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.pricingAccountBillingStreet }}

                              <br/>

                              {{ Item.pricingAccountBillingZip }} {{ Item.pricingAccountBillingCity }}

                              <br/>

                              {{ Item.pricingAccountBillingCountry }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Objednávateľ
                            </h3>

                            <p>
                              {{ Item.pricingClientBillingName }}

                              <br/>

                              v zastúpení: {{ Item.pricingClientBillingSign }}

                              <template
                                  v-if="Item.pricingClientBillingOnCompany"
                              >
                                <template
                                    v-if="Item.pricingClientBillingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.pricingClientBillingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.pricingClientBillingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.pricingClientBillingTaxId }}
                                </template>

                                <template
                                    v-if="Item.pricingClientBillingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.pricingClientBillingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.pricingClientBillingStreet }}

                              <br/>

                              {{ Item.pricingClientBillingZip }} {{ Item.pricingClientBillingCity }}

                              <br/>

                              {{ Item.pricingClientBillingCountry }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <PricingItemsInfo
                                :Ticket="Item"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas požiadavky
                            </h3>

                            <p>
                              {{ Item.pricingRequestedAt|moment('DD.MM.YYYY HH:mm') }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas odpovede
                            </h3>

                            <IsEmptyHelper
                                :value="Item.pricingResponsedAt"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.pricingResponsedAt|moment('DD.MM.YYYY HH:mm') }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas exspirácie
                            </h3>

                            <p>
                              {{ Item.pricingExpiredAt|moment('DD.MM.YYYY HH:mm') }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Stav
                            </h3>

                            <p>
                              {{ pricingStatusList[Item.pricingStatus].text }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              E-mail
                            </h3>

                            <p>
                              <a
                                  :href="'mailto:' + Item.pricingEmail"
                              >
                                {{ Item.pricingEmail }}
                              </a>
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Meno
                            </h3>

                            <p>
                              {{ Item.pricingName }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <h3>
                              Správa
                            </h3>

                            <p>
                              {{ Item.pricingMessage }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <h3>
                              Spätná väzba
                            </h3>

                            <IsEmptyHelper
                                :value="Item.pricingFeedback"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.pricingFeedback }}
                            </IsEmptyHelper>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </CardPart>
                </v-tab-item>

                <v-tab-item
                    v-if="Item.showableInvoicing && Item.showableProtocol"
                >
                  <CardPart
                      maxWidth="calc(100% - 80px)"
                      title="Fakturácia & Protokol"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="Item.protocolSendable || Item.protocolWaitable || Item.protocolGeneratable"
                      >
                        <v-spacer/>
                      </template>

                      <template
                          v-if="Item.protocolSendable"
                      >
                        <ProtocolSkipAndConfirm
                            :id="Item.id"
                            @onProtocolSkipAndConfirm="refresh"
                        />
                      </template>

                      <template
                          v-if="Item.protocolWaitable"
                      >
                        <ProtocolConfirm
                            :id="Item.id"
                            @onProtocolConfirm="refresh"
                        />
                      </template>

                      <template
                          v-if="Item.protocolGeneratable"
                      >
                        &nbsp;

                        <v-btn
                            :icon="true"
                            :outlined="true"
                            color="primary"
                            :href="$global.axiosUrl() + 'tickets/'  + Item.token + '/generate/protocol'"
                            target="_blank"
                        >
                          <v-icon>
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <v-row>
                        <template
                            v-if="Item.protocolSendable"
                        >
                          <v-col
                              cols="12"
                          >
                            <h3>
                              Projekt
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Project"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Project?.title }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Odovzdávajúci
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Account"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Account?.billingName }}

                              <br/>

                              v zastúpení: {{ Item.Account?.billingSign }}

                              <template
                                  v-if="Item.Account?.billingOnCompany"
                              >
                                <template
                                    v-if="Item.Account?.billingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.Account?.billingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.Account?.billingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.Account?.billingTaxId }}
                                </template>

                                <template
                                    v-if="Item.Account?.billingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.Account?.billingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.Account?.billingStreet }}

                              <br/>

                              {{ Item.Account?.billingZip }} {{ Item.Account?.billingCity }}

                              <br/>

                              {{ Item.Account?.billingCountry }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Preberajúci
                            </h3>

                            <IsEmptyHelper
                                :value="Item.Project?.Client"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.Project?.Client?.billingName }}

                              <br/>

                              v zastúpení: {{ Item.Project?.Client?.billingSign }}

                              <template
                                  v-if="Item.Project?.Client?.billingOnCompany"
                              >
                                <template
                                    v-if="Item.Project?.Client?.billingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.Project?.Client?.billingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.Project?.Client?.billingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.Project?.Client?.billingTaxId }}
                                </template>

                                <template
                                    v-if="Item.Project?.Client?.billingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.Project?.Client?.billingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.Project?.Client?.billingStreet }}

                              <br/>

                              {{ Item.Project?.Client?.billingZip }} {{ Item.Project?.Client?.billingCity }}

                              <br/>

                              {{ Item.Project?.Client?.billingCountry }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <InvoicingItemsInfo
                                :Ticket="Item"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <FormProtocolSend
                                :Ticket="Item"
                                @refresh="refresh"
                            />
                          </v-col>
                        </template>

                        <template
                            v-else
                        >
                          <v-col
                              cols="12"
                          >
                            <h3>
                              Projekt
                            </h3>

                            <p>
                              {{ Item.protocolProjectTitle }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Odovzdávajúci
                            </h3>

                            <p>
                              {{ Item.protocolAccountBillingName }}

                              <br/>

                              v zastúpení: {{ Item.protocolAccountBillingSign }}

                              <template
                                  v-if="Item.protocolAccountBillingOnCompany"
                              >
                                <template
                                    v-if="Item.protocolAccountBillingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.protocolAccountBillingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.protocolAccountBillingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.protocolAccountBillingTaxId }}
                                </template>

                                <template
                                    v-if="Item.protocolAccountBillingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.protocolAccountBillingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.protocolAccountBillingStreet }}

                              <br/>

                              {{ Item.protocolAccountBillingZip }} {{ Item.protocolAccountBillingCity }}

                              <br/>

                              {{ Item.protocolAccountBillingCountry }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Preberajúci
                            </h3>

                            <p>
                              {{ Item.protocolClientBillingName }}

                              <br/>

                              v zastúpení: {{ Item.protocolClientBillingSign }}

                              <template
                                  v-if="Item.protocolClientBillingOnCompany"
                              >
                                <template
                                    v-if="Item.protocolClientBillingBusinessId"
                                >
                                  <br/>

                                  IČO: {{ Item.protocolClientBillingBusinessId }}
                                </template>

                                <template
                                    v-if="Item.protocolClientBillingTaxId"
                                >
                                  <br/>

                                  DIČ: {{ Item.protocolClientBillingTaxId }}
                                </template>

                                <template
                                    v-if="Item.protocolClientBillingVatId"
                                >
                                  <br/>

                                  IČ DPH: {{ Item.protocolClientBillingVatId }}
                                </template>
                              </template>

                              <br/>

                              {{ Item.protocolClientBillingStreet }}

                              <br/>

                              {{ Item.protocolClientBillingZip }} {{ Item.protocolClientBillingCity }}

                              <br/>

                              {{ Item.protocolClientBillingCountry }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <InvoicingItemsInfo
                                :Ticket="Item"
                            />
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas požiadavky
                            </h3>

                            <p>
                              {{ Item.protocolRequestedAt|moment('DD.MM.YYYY HH:mm') }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas odpovede
                            </h3>

                            <IsEmptyHelper
                                :value="Item.protocolResponsedAt"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.protocolResponsedAt|moment('DD.MM.YYYY HH:mm') }}
                            </IsEmptyHelper>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Dátum a čas exspirácie
                            </h3>

                            <p>
                              {{ Item.protocolExpiredAt|moment('DD.MM.YYYY HH:mm') }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Stav
                            </h3>

                            <p>
                              {{ protocolStatusList[Item.protocolStatus].text }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <v-divider/>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              E-mail
                            </h3>

                            <p>
                              <a
                                  :href="'mailto:' + Item.protocolEmail"
                              >
                                {{ Item.protocolEmail }}
                              </a>
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                              lg="6"
                          >
                            <h3>
                              Meno
                            </h3>

                            <p>
                              {{ Item.protocolName }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <h3>
                              Správa
                            </h3>

                            <p>
                              {{ Item.protocolMessage }}
                            </p>
                          </v-col>

                          <v-col
                              cols="12"
                          >
                            <h3>
                              Spätná väzba
                            </h3>

                            <IsEmptyHelper
                                :value="Item.protocolFeedback"
                                if-no="p"
                                if-yes="p"
                            >
                              {{ Item.protocolFeedback }}
                            </IsEmptyHelper>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </CardPart>
                </v-tab-item>

                <v-tab-item
                    v-if="Item.showableSum"
                >
                  <CardPart
                      maxWidth="calc(100% - 40px)"
                      title="Zhrnutie"
                  >
                    <template
                        v-slot:action
                    >
                      <template
                          v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
                      >
                        <template
                            v-if="Item.closable || Item.restorable"
                        >
                          <v-spacer/>
                        </template>

                        <template
                            v-if="Item.closable"
                        >
                          <Close
                              :id="Item.id"
                              @onClose="refresh"
                          />
                        </template>

                        <template
                            v-if="Item.restorable"
                        >
                          &nbsp;

                          <Restore
                              :id="Item.id"
                              @onRestore="refresh"
                          />
                        </template>
                      </template>
                    </template>

                    <template
                        v-slot:default
                    >
                      <v-tabs
                          v-model="tabsSum"
                      >
                        <v-tab>
                          Príjmy

                          &nbsp;

                          <v-chip>
                            {{ Item.pricePartRevenues|currency('€') }}
                          </v-chip>
                        </v-tab>

                        <v-tab>
                          Výkazy

                          &nbsp;

                          <v-chip>
                            {{ Item.priceSpendItems|currency('€') }}
                          </v-chip>
                        </v-tab>

                        <v-tab>
                          Výdaje

                          &nbsp;

                          <v-chip>
                            {{ Item.pricePartExpenses|currency('€') }}
                          </v-chip>
                        </v-tab>

                        <v-tab>
                          Bonusy

                          &nbsp;

                          <v-chip>
                            {{ Item.priceBonuses|currency('€') }}
                          </v-chip>
                        </v-tab>

                        <v-tab>
                          Provízie

                          &nbsp;

                          <v-chip>
                            {{ Item.priceCommissions|currency('€') }}
                          </v-chip>
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items
                          v-model="tabsSum"
                      >
                        <v-tab-item>
                          <TicketRevenues
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <TicketItemReports
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <TicketExpenses
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <TicketBonuses
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <TicketCommissions
                              :Ticket="Item"
                              @refresh="refresh"
                          />
                        </v-tab-item>
                      </v-tabs-items>
                    </template>
                  </CardPart>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </template>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Tickets from '@/services/tickets'

  import Duplicate from '@/components/Tickets/Duplicate'
  import Update from '@/components/Tickets/Update'
  import UpdateTask from '@/components/Tickets/UpdateTask'
  import PricingSkipAndAccept from '@/components/Tickets/PricingSkipAndAccept'
  import PricingAccept from '@/components/Tickets/PricingAccept'
  import PricingReject from '@/components/Tickets/PricingReject'
  import ProtocolSkipAndConfirm from '@/components/Tickets/ProtocolSkipAndConfirm'
  import ProtocolConfirm from '@/components/Tickets/ProtocolConfirm'
  import Close from '@/components/Tickets/Close'
  import Restore from '@/components/Tickets/Restore'
  import Delete from '@/components/Tickets/Delete'
  import TicketItems from '@/components/Ticket/TicketItems'
  import TicketShares from '@/components/Ticket/TicketShares'
  import TicketRevenues from '@/components/Ticket/TicketRevenues'
  import TicketItemReports from '@/components/Ticket/TicketItemReports'
  import TicketExpenses from '@/components/Ticket/TicketExpenses'
  import TicketBonuses from '@/components/Ticket/TicketBonuses'
  import TicketCommissions from '@/components/Ticket/TicketCommissions'
  import PricingItemsInfo from '@/components/Ticket/PricingItemsInfo'
  import InvoicingItemsInfo from '@/components/Ticket/InvoicingItemsInfo'
  import FormTypeChoosePricing from '@/components/Ticket/FormTypeChoosePricing'
  import FormTypeChooseUnlimited from '@/components/Ticket/FormTypeChooseUnlimited'
  import FormAlert from '@/components/Ticket/FormAlert'
  import FormAssignment from '@/components/Ticket/FormAssignment'
  import FormSettings from '@/components/Ticket/FormSettings'
  import FormPricingSend from '@/components/Ticket/FormPricingSend'
  import FormProtocolSend from '@/components/Ticket/FormProtocolSend'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import PanelPart from '@/components/_Part/Panel'
  import CardPart from '@/components/_Part/Card'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Ticket',
    components: {
      Duplicate,
      Update,
      UpdateTask,
      ProtocolSkipAndConfirm,
      PricingAccept,
      PricingReject,
      PricingSkipAndAccept,
      ProtocolConfirm,
      Close,
      Restore,
      Delete,
      TicketItems,
      TicketShares,
      TicketRevenues,
      TicketItemReports,
      TicketExpenses,
      TicketBonuses,
      TicketCommissions,
      PricingItemsInfo,
      InvoicingItemsInfo,
      FormTypeChoosePricing,
      FormTypeChooseUnlimited,
      FormAlert,
      FormAssignment,
      FormSettings,
      FormPricingSend,
      FormProtocolSend,
      NotBlankPage,
      PanelPart,
      CardPart,
      IsEmptyHelper
    },
    data() {
      return {
        tabs: 0,
        tabsSum: 0
      }
    },
    computed: {
      typeList: function () {
        return this.$store.state.core.Entity.Ticket?.type
      },
      statusList: function () {
        return this.$store.state.core.Entity.Ticket?.status
      },
      pricingStatusList: function () {
        return this.$store.state.core.Entity.Ticket?.pricingStatus
      },
      protocolStatusList: function () {
        return this.$store.state.core.Entity.Ticket?.protocolStatus
      }
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Tikety',
            to: '/tickets'
          }
        ]
      },
      onDelete() {
        setTimeout(() => {
          this.$router.push('/tickets')
        }, 100)
      }
    }
  }
</script>
