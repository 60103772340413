var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.params.accountId)?_c('NotBlankPage',{key:_vm.$route.params.accountId,attrs:{"maxWidth":"calc(100% - 80px)","hasBackLink":true,"failLink":"/finance","itemKey":"Account","fetchData":_vm.fetchData,"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        Item
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SuggestAccounts',{attrs:{"outlined":true,"dense":true,"hide-details":"auto","label":"Účet","createButton":true},on:{"change":(value) => {
              _vm.$router.push('/finance/' + value)
            }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v(" Príjmy ")]),_c('v-tab',[_vm._v(" Výdaje ")]),_c('v-tab',[_vm._v(" Bilancia ")])],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('Revenues',{attrs:{"Account":Item}})],1),_c('v-tab-item',[_c('Expenses',{attrs:{"Account":Item}})],1),_c('v-tab-item',[_c('Bilances',{attrs:{"Account":Item}})],1)],1)],1)],1)]}}],null,false,4074006743)}):_c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('CardPart',{attrs:{"maxWidth":"100%","title":title},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SuggestAccounts',{attrs:{"outlined":true,"dense":true,"hide-details":"auto","label":"Účet","createButton":true},on:{"change":(value) => {
                  _vm.$router.push('/finance/' + value)
                }}})],1)],1)]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }