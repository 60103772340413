<template>
  <GridPart
      maxWidth="calc(100% - 40px)"
      :title="title"
      :hasSearch="true"
      :hasFilter="false"
      :hasExtra="false"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="4"
      empty="Nenašli sa žiadne notifikácie."
      itemsKey="notifications"
      filterKey="Notification"
      defaultOrder="custom"
      defaultBy="CUSTOM"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head
    >
      <th
          class="fixed"
      >
        Správa
      </th>

      <th>
        Stav
      </th>

      <th>
        Dátum a čas upozornenia
      </th>

      <th>
        Dátum a čas navštívenia
      </th>
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td
          class="fixed"
      >
        {{ Item.message }}
      </td>

      <td>
        {{ statusList[Item.status]?.text }}
      </td>

      <td>
        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
      </td>

      <td>
        <IsEmptyHelper
            :value="Item.updatedAt"
            if-no="span"
            if-yes="span"
        >
          {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
        </IsEmptyHelper>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Notifications from '@/services/notifications'

  import GridPart from '@/components/_Part/Grid'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Notifications',
    props: [
      'User'
    ],
    components: {
      GridPart,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Notifikácie'
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.Notification?.status
      }
    },
    methods: {
      fetchData(params) {
        return Notifications
          .indexUser({
            userId: this.User.id,
            params: params
          })
      }
    }
  }
</script>
