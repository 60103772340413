<template>
  <GridPart
      maxWidth="calc(100% - 40px)"
      :title="title"
      :hasSearch="false"
      :hasFilter="false"
      :hasExtra="false"
      :standardClass="() => {}"
      :extraClass="() => {}"
      :colspan="6"
      empty="Nenašli sa žiadne bilancie."
      itemsKey="bilances"
      filterKey="Bilance"
      defaultOrder="clo.closedAt"
      defaultBy="DESC"
      :defaultLimit="25"
      :fetchData="fetchData"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-text-field
          v-model="params.query"
          :error-messages="errors.query"
          :outlined="true"
          :dense="true"
          hide-details="auto"
          label="Vyhľadávanie"
          :clearable="true"
          @change="setSearch"
      />
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          orderList,
          byList,
          setOrderBy
        }"
    >
      <th
          class="fixed"
      >
        <OrderList
            orderKey="clo.closedAt"
            :order="order"
            :by="by"
            :orderList="orderList"
            :byList="byList"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Plusy
      </th>

      <th>
        Mínusy
      </th>

      <th>
        Výplaty
      </th>

      <th>
        Celkovo
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td
          class="fixed"
      >
        <IsEmptyHelper
            :value="Item.Closing.closedAt"
            if-no="span"
            if-yes="span"
            empty="Neuzavreté"
        >
          {{ Item.Closing?.closedAt|moment('MM/YYYY') }}
        </IsEmptyHelper>
      </td>

      <td>
        {{ Item.totalPluses|currency('€') }}

        <template
            v-if="Item.totalPlusesBank || Item.totalPlusesCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.totalPlusesBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.totalPlusesBank|currency('€') }}
            </template>

            <template
                v-if="Item.totalPlusesCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.totalPlusesCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.totalMinuses|currency('€') }}

        <template
            v-if="Item.totalMinusesBank || Item.totalMinusesCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.totalMinusesBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.totalMinusesBank|currency('€') }}
            </template>

            <template
                v-if="Item.totalMinusesCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.totalMinusesCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.totalWages|currency('€') }}

        <template
            v-if="Item.totalWagesBank || Item.totalWagesCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.totalWagesBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.totalWagesBank|currency('€') }}
            </template>

            <template
                v-if="Item.totalWagesCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.totalWagesCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td>
        {{ Item.total|currency('€') }}

        <template
            v-if="Item.totalBank || Item.totalCash"
        >
          <br/>

          <small>
            <template
                v-if="Item.totalBank"
            >
              <v-icon
                  color="primary"
              >
                mdi-bank-transfer
              </v-icon>

              {{ Item.totalBank|currency('€') }}
            </template>

            <template
                v-if="Item.totalCash"
            >
              <v-icon
                  color="primary"
              >
                mdi-cash
              </v-icon>

              {{ Item.totalCash|currency('€') }}
            </template>
          </small>
        </template>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/bilance/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </td>
    </template>
  </GridPart>
</template>

<script>
  import Bilances from '@/services/bilances'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Bilances',
    props: [
      'Account'
    ],
    components: {
      GridPart,
      OrderList,
      IsEmptyHelper
    },
    data() {
      return {
        title: 'Bilancie'
      }
    },
    methods: {
      fetchData(params) {
        return Bilances
          .indexAccount({
            accountId: this.Account.id,
            params: params
          })
      }
    }
  }
</script>
