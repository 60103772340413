<template>
  <ConfirmModal
      color="primary"
      icon="mdi-cached"
      :maxWidth="500"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="clearCacheData"
      @onConfirm="onClearCache"
      v-bind="$attrs"
  />
</template>

<script>
  import System from '@/services/system'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'ClearCache',
    components: {
      ConfirmModal
    },
    methods: {
      setTitle() {
        return 'Vyčistiť medzipamäť'
      },
      setQuestion() {
        return 'Naozaj chcete vyčistiť medzipamäť?'
      },
      clearCacheData() {
        return System
          .clearCache()
      },
      onClearCache() {
        setTimeout(() => {
          window.location.reload()
        }, 100)
      }
    }
  }
</script>
