<template>
  <PanelPart
      maxWidth="calc(100% - 40px)"
      color="primary"
      icon="mdi-comment"
      :title="'Komentáre (' + TicketItem.ticketItemComments.length + ')'"
  >
    <template
        v-slot:action
    >
      <template
          v-if="[TicketItem.Ticket?.Seller?.id, TicketItem.Ticket?.Manager?.id].includes($store.state.auth.User.id)"
      >
        <template
            v-if="TicketItem.ticketItemCommentCreatable"
        >
          <v-spacer/>

          <CreateTicketItemTicketItemCommentAuth
              :small="true"
              :ticketItemId="TicketItem.id"
              @onCreateTicketItem="$emit('refresh')"
          />
        </template>
      </template>
    </template>

    <template
        v-slot:default
    >
      <IsEmptyHelper
          :value="TicketItem.ticketItemComments.length > 0"
          if-no="v-row"
          if-yes="p"
          empty="žiadne"
      >
        <v-col
            v-for="(_TicketItemComment) in TicketItem.ticketItemComments"
            :key="_TicketItemComment.id"
            cols="12"
        >
          <p>
            <span
                v-if="_TicketItemComment.status === statusList.important?.value"
                class="primary--text font-weight-bold"
            >
              <v-icon
                  :small="true"
                  color="primary"
              >
                mdi-exclamation
              </v-icon>

              {{ _TicketItemComment.message }}
            </span>

            <template
                v-if="_TicketItemComment.status === statusList.unimportant?.value"
            >
              {{ _TicketItemComment.message }}
            </template>

            <template
                :value="_TicketItemComment.files.length > 0"
            >
              <template
                  v-for="(_File) in _TicketItemComment.files"
              >
                <br
                    :key="_File.id + 'br'"
                />

                <a
                    :key="_File.id + 'a'"
                    :href="$global.axiosUrl() + 'files/'  + _File.token + '/generate/original'"
                    target="_blank"
                >
                  {{ _File.name }}
                </a>
              </template>
            </template>

            <v-divider/>

            <small>
              {{ _TicketItemComment.createdAt|moment('DD.MM.YYYY HH:mm') }} od {{ _TicketItemComment.Creator.name }}

              <template
                  v-if="_TicketItemComment.updatable"
              >
                &nbsp;

                <UpdateStatusUnimportantTicketItemCommentAuth
                    v-if="_TicketItemComment.status === statusList.important?.value"
                    :x-small="true"
                    :id="_TicketItemComment.id"
                    @onUpdateStatusUnimportant="$emit('refresh')"
                />

                <UpdateStatusImportantTicketItemCommentAuth
                    v-if="_TicketItemComment.status === statusList.unimportant?.value"
                    :x-small="true"
                    :id="_TicketItemComment.id"
                    @onUpdateStatusImportant="$emit('refresh')"
                />
              </template>

              <template
                  v-if="_TicketItemComment.deletable"
              >
                &nbsp;

                <Delete
                    :x-small="true"
                    :id="_TicketItemComment.id"
                    @onDelete="$emit('refresh')"
                />
              </template>
            </small>
          </p>
        </v-col>
      </IsEmptyHelper>
    </template>
  </PanelPart>
</template>

<script>
  import Delete from '@/components/TicketItemComments/Delete'

  import CreateTicketItemTicketItemCommentAuth from '@/components/TicketItemCommentsAuth/CreateTicketItem'
  import UpdateStatusImportantTicketItemCommentAuth from '@/components/TicketItemCommentsAuth/UpdateStatusImportant'
  import UpdateStatusUnimportantTicketItemCommentAuth from '@/components/TicketItemCommentsAuth/UpdateStatusUnimportant'

  import PanelPart from '@/components/_Part/Panel'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItemComments',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      Delete,
      CreateTicketItemTicketItemCommentAuth,
      UpdateStatusImportantTicketItemCommentAuth,
      UpdateStatusUnimportantTicketItemCommentAuth,
      PanelPart,
      IsEmptyHelper
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItemComment?.status
      }
    }
  }
</script>
