import Vue from 'vue'

import Qs from 'qs'

class Expenses {
  async suggestOnlyAlertBalance() {
    return await Vue.axios
      .get('app/expenses/suggest/only-alert-balance')
  }

  async index({params}) {
    return await Vue.axios
      .get('app/expenses', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async indexAccount({accountId, params}) {
    return await Vue.axios
      .get('app/expenses/by-account/' + accountId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createAccount({accountId, data}) {
    return await Vue.axios
      .post('app/expenses/by-account/' + accountId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/expenses/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/expenses/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/expenses/' + id)
  }
}

const expenses = new Expenses()

export default expenses
