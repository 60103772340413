<template>
  <SaveModal
      color="green"
      icon="mdi-check"
      :maxWidth="500"
      button="Prijať"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="acceptData"
      @onSave="onAccept"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.pricingFeedback"
              :error-messages="errors.pricingFeedback"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.PricingClientSignature"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Podpis"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :dense="true"
                  align="center"
              >
                <v-col
                    v-if="pricingClientSignature"
                    cols="auto"
                >
                  <v-img
                      :src="$global.axiosUrl() + 'files/' + pricingClientSignature + '/generate/original'"
                      alt="Podpis"
                      max-width="300px"
                      max-height="100px"
                  />
                </v-col>

                <v-col
                    cols="auto"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <Signature
                          :small="true"
                          :pricingToken="Item.pricingToken"
                          @onSignature="(response) => {
                            ItemForm.PricingClientSignature = response.data.data.id

                            pricingClientSignature = response.data.data.token
                          }"
                      />
                    </v-col>

                    <v-col
                        v-if="ItemForm.PricingClientSignature && pricingClientSignature"
                        cols="12"
                    >
                      <v-btn
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="error"
                          @click="() => {
                            ItemForm.PricingClientSignature = null

                            pricingClientSignature = null
                          }"
                      >
                        <v-icon>
                          mdi-auto-fix
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </SaveModal>
</template>

<script>
  import TicketPricing from '@/services/ticket-pricing'

  import Signature from '@/components/Pricing/Signature'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Accept',
    props: [
      'pricingToken'
    ],
    components: {
      Signature,
      SaveModal
    },
    data() {
      return {
        token: null,
        pricingClientSignature: null
      }
    },
    methods: {
      fetchData() {
        return TicketPricing
          .exist({
            pricingToken: this.pricingToken
          })
      },
      setTitle(Item) {
        return 'Prijať cenovú ponuku č.' + Item.id
      },
      setItemForm(Item) {
        this.token = Item.token

        this.pricingClientSignature = null

        return {
          pricingFeedback: null,
          PricingClientSignature: null
        }
      },
      acceptData(ItemForm) {
        return TicketPricing
          .accept({
            pricingToken: this.pricingToken,
            data: ItemForm
          })
      },
      onAccept() {
        setTimeout(() => {
          window.location.assign(this.$global.axiosUrl() + 'tickets/' + this.token + '/generate/pricing')
        }, 100)
      }
    }
  }
</script>
