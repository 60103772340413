var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 40px)","title":title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":7,"empty":"Nenašli sa žiadne maily.","itemsKey":"mails","filterKey":"Mail","defaultFilters":{
          subject: null,
          status: null
        },"defaultOrder":"mai.createdAt","defaultBy":"DESC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.subject,"outlined":true,"dense":true,"hide-details":"auto","label":"Predmet","clearable":true,"module":"mail_subject"},on:{"change":setFilter},model:{value:(params.Filters.subject),callback:function ($$v) {_vm.$set(params.Filters, "subject", $$v)},expression:"params.Filters.subject"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","items":_vm.statuses,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',[_vm._v(" Od ")]),_c('th',[_vm._v(" Komu ")]),_c('th',[_vm._v(" Predmet ")]),_c('th',[_vm._v(" Stav ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"mai.createdAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_c('OrderList',{attrs:{"orderKey":"mai.updatedAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item
          }){return [_c('td',[_c('a',{attrs:{"href":'mailto: ' + Item.fromEmail}},[_c('TruncateHelper',{attrs:{"title":Item.fromEmail,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.fromEmail)+" "),(Item.fromName)?[_vm._v(" ("+_vm._s(Item.fromName)+") ")]:_vm._e()],2)],1)]),_c('td',[_c('a',{attrs:{"href":'mailto: ' + Item.toEmail}},[_c('TruncateHelper',{attrs:{"title":Item.toEmail,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.toEmail)+" "),(Item.toName)?[_vm._v(" ("+_vm._s(Item.toName)+") ")]:_vm._e()],2)],1)]),_c('td',[_c('TruncateHelper',{attrs:{"title":Item.subject,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.subject)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.statusList[Item.status]?.text)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.updatedAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/mail/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }