import Vue from 'vue'

import Qs from 'qs'

class TicketItemReportsAuth {
  async indexTicketItem({ticketItemId, params}) {
    return await Vue.axios
      .get('app/ticket-item-reports/auth/by-ticket-item/' + ticketItemId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicketItem({ticketItemId, data}) {
    return await Vue.axios
      .post('app/ticket-item-reports/auth/by-ticket-item/' + ticketItemId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-reports/auth/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-item-reports/auth/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-item-reports/auth/' + id)
  }

  async uploadFile({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/ticket-item-reports/auth/' + id + '/upload/file', data)
    } else {
      return await Vue.axios
        .post('app/ticket-item-reports/auth/upload/file', data)
    }
  }
}

const ticketItemReportsAuth = new TicketItemReportsAuth()

export default ticketItemReportsAuth
