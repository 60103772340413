<template>
  <FormPart
      :Item="TicketItem"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-select
              v-model="ItemForm.status"
              :error-messages="errors.status"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Stav"
              :items="statuses"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormStatus',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItem?.status
      },
      statuses: function () {
        return Object.values(this.statusList)
      }
    },
    methods: {
      setItemForm(Item) {
        return {
          status: Item.status
        }
      },
      submitData(ItemForm) {
        return TicketItems
          .updateStatus({
            id: this.TicketItem.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/ticket/' + this.TicketItem.Ticket.id)
      }
    }
  }
</script>
