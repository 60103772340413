import Vue from 'vue'

class TicketProtocol {
  async send({data}) {
    return await Vue.axios
      .post('app/ticket/protocol/send', data)
  }

  async exist({protocolToken}) {
    return await Vue.axios
      .get('app/ticket/protocol/exist/' + protocolToken)
  }

  async confirm({protocolToken, data}) {
    return await Vue.axios
      .put('app/ticket/protocol/confirm/' + protocolToken, data)
  }

  async uploadSignature({protocolToken, data}) {
    return await Vue.axios
      .post('app/ticket/protocol/upload/signature/' + protocolToken, data)
  }
}

const ticketProtocol = new TicketProtocol()

export default ticketProtocol
