<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          maxWidth="calc(100% - 80px)"
          :title="title"
          :hasSearch="true"
          :hasFilter="true"
          :hasExtra="false"
          :standardClass="() => {}"
          :extraClass="() => {}"
          :colspan="5"
          empty="Nenašli sa žiadne tarify."
          itemsKey="tariffs"
          filterKey="Tariff"
          :defaultFilters="{
            frequency: null,
            unit: null,
            type: null
          }"
          defaultOrder="tar.title"
          defaultBy="ASC"
          :defaultLimit="25"
          :fetchData="fetchData"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          &nbsp;
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-text-field
              v-model="params.query"
              :error-messages="errors.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setSearch"
          />
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-row>
            <v-col
                cols="12"
                lg="3"
            >
              <SuggestIndexesModule
                  v-model="params.Filters.frequency"
                  :error-messages="errors.Filters?.frequency"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Frekvencia"
                  :clearable="true"
                  @change="setFilter"
                  module="tariff_frequency"
              />
            </v-col>

            <v-col
                cols="12"
                lg="3"
            >
              <SuggestIndexesModule
                  v-model="params.Filters.unit"
                  :error-messages="errors.Filters?.unit"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Jednotka"
                  :clearable="true"
                  @change="setFilter"
                  module="tariff_unit"
              />
            </v-col>

            <v-col
                cols="12"
                lg="3"
            >
              <v-select
                  v-model="params.Filters.type"
                  :error-messages="errors.Filters?.type"
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Typ"
                  :items="types"
                  :clearable="true"
                  @change="setFilter"
              />
            </v-col>
          </v-row>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th
              class="fixed"
          >
            <OrderList
                orderKey="tar.title"
                :order="order"
                :by="by"
                :orderList="orderList"
                :byList="byList"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Sadzba
          </th>

          <th>
            Typ
          </th>

          <th>
            Nastavenia
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="fixed"
          >
            <TruncateHelper
                :title="Item.title"
                maxWidth="300px"
            >
              {{ Item.title }}
            </TruncateHelper>
          </td>

          <td>
            {{ Item.price|currency('€') }}

            <template
                v-if="Item.frequency"
            >
              / {{ Item.frequency }}
            </template>

            / {{ Item.unit }}
          </td>

          <td>
            {{ typeList[Item.type]?.text }}
          </td>

          <td>
            <template
                v-if="Item.type === typeList?.simple?.value"
            >
              {{ Item.minProfit|percent }}
            </template>

            <template
                v-if="Item.type === typeList?.group?.value"
            >
              {{ Item.priceOffers|currency('€') }} / {{ Item.timeOffers|number }} h
            </template>

            (max. {{ Item.maxPriceHour|currency('€ / h') }})
          </td>

          <td
              class="text-right fixed"
          >
            <Update
                :id="Item.id"
                @onUpdate="init"
            />

            <template
                v-if="$store.getters['auth/isLogged'] && $store.getters['auth/hasRole']('ROLE_MASTER')"
            >
              &nbsp;

              <Delete
                  :id="Item.id"
                  @onDelete="init"
              />
            </template>
          </td>
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Tariffs from '@/services/tariffs'

  import Create from '@/components/Tariffs/Create'
  import Update from '@/components/Tariffs/Update'
  import Delete from '@/components/Tariffs/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderList from '@/components/_Part/_Grid/_List/Order'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Tariffs',
    components: {
      Create,
      Update,
      Delete,
      BlankPage,
      GridPart,
      OrderList,
      TruncateHelper,
      SuggestIndexesModule
    },
    computed: {
      typeList: function () {
        return this.$store.state.core.Entity.Tariff?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      fetchData(params) {
        return Tariffs
          .index({
            params: params
          })
      },
      setTitle() {
        return 'Tarify'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
