import Vue from 'vue'

import Qs from 'qs'

class TicketShares {
  async indexTicket({ticketId, params}) {
    return await Vue.axios
      .get('app/ticket-shares/by-ticket/' + ticketId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createTicket({ticketId, data}) {
    return await Vue.axios
      .post('app/ticket-shares/by-ticket/' + ticketId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/ticket-shares/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/ticket-shares/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-shares/' + id)
  }
}

const ticketShares = new TicketShares()

export default ticketShares
