<template>
  <CreateSave
      :maxWidth="500"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreateTicketItem', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.workedAt"
              :error-messages="errors.workedAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum vykonania"
              type="date"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.timeSpend"
              :error-messages="errors.timeSpend"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Strávený čas"
              type="number"
              suffix="h"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.note"
              :error-messages="errors.note"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.files"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Prílohy"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :no-gutters="true"
                  :dense="true"
              >
                <template
                    v-if="files.length > 0"
                >
                  <v-col
                      v-for="(_file, index) in files"
                      :key="_file.id"
                      cols="12"
                  >
                    <a
                        :href="$global.axiosUrl() + 'files/'  + _file.token + '/generate/original'"
                        target="_blank"
                    >
                      {{ _file.name }}
                    </a>

                    <v-btn
                        :x-small="true"
                        :icon="true"
                        :outlined="true"
                        color="error"
                        @click="() => {
                          ItemForm.files.splice(index, 1)

                          files.splice(index, 1)
                        }"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </template>

                <v-col
                    cols="12"
                >
                  <File
                      :x-small="true"
                      :id="null"
                      @onFile="(response) => {
                        ItemForm.files.push(response.data.data.id)

                        files.push({
                          id: response.data.data.id,
                          token: response.data.data.token,
                          name: response.data.data.name
                        })
                      }"
                  />
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import TicketItemReportsAuth from '@/services/ticket-item-reports-auth'

  import File from '@/components/TicketItemReportsAuth/File'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'CreateTicketItem',
    props: [
      'ticketItemId'
    ],
    emits: [
      'onCreateTicketItem'
    ],
    components: {
      File,
      CreateSave
    },
    data() {
      return {
        files: []
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť výkaz'
      },
      setItemForm() {
        this.files = []

        return {
          workedAt: this.$moment().format('YYYY-MM-DD'),
          timeSpend: 0,
          note: null,
          files: []
        }
      },
      createData(ItemForm) {
        return TicketItemReportsAuth
          .createTicketItem({
            ticketItemId: this.ticketItemId,
            data: ItemForm
          })
      }
    }
  }
</script>
