<template>
  <FormPart
      :Item="TicketItem"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm,
          submit
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestUsersAuthRoleTicketItem
              v-model="ItemForm.Worker"
              :error-messages="errors.Worker"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Pracovník"
              :clearable="true"
              role="ROLE_WORKER"
              :ticketItemId="TicketItem.id"
              @change="submit"
          />
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketItemsAuth from '@/services/ticket-items-auth'

  import FormPart from '@/components/_Part/Form'

  import SuggestUsersAuthRoleTicketItem from '@/components/UsersAuth/SuggestRoleTicketItem'

  export default {
    name: 'FormAssignment',
    props: [
      'TicketItem'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart,
      SuggestUsersAuthRoleTicketItem
    },
    methods: {
      setItemForm(Item) {
        return {
          Worker: Item.Worker?.id
        }
      },
      submitData(ItemForm) {
        return TicketItemsAuth
          .updateAssignment({
            id: this.TicketItem.id,
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail() {
        this.$router.push('/dashboard')
      }
    }
  }
</script>
