import Register from '@/services/register'

const state = {
  Filter: null
}

const mutations = {
  SET_ENTITY(state, Entity) {
    state.Entity = Entity
  },
  SET_FILTER(state, Filter) {
    state.Filter = Filter
  }
}

const actions = {
  async check({commit}) {
    return await Register
      .exist()
      .then((response) => {
        commit('SET_ENTITY', response.data.data.Entity)

        commit('SET_FILTER', response.data.data.Filter)
      })
      .catch(() => {
        commit('SET_ENTITY', null)

        commit('SET_FILTER', null)
      })
  }
}

const getters = {}

const core = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default core
