<template>
  <SaveModal
      color="warning"
      icon="mdi-pencil"
      :maxWidth="500"
      button="Hromadne upraviť"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="updateMassData"
      @onSave="(response) => $emit('onUpdateMass', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestClosings
              v-model="ItemForm.ClosingWage"
              :error-messages="errors.ClosingWage"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Uzávierka"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-select
              v-model="ItemForm.status"
              :error-messages="errors.status"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Stav"
              :items="statuses"
          />
        </v-col>

        <v-col
            v-if="ItemForm.status == statusList['paid']?.value"
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.paidAt"
              :error-messages="errors.paidAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum uhradenia"
              type="date"
          />
        </v-col>

        <template
            v-if="ItemForm.status !== statusList['in-queue']?.value"
        >
          <v-col
              cols="12"
          >
            <v-select
                v-model="ItemForm.pay"
                :error-messages="errors.pay"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Platba"
                :items="pays"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
        >
          <SuggestAccounts
              v-model="ItemForm.Account"
              :error-messages="errors.Account"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Účet"
              :createButton="true"
          />
        </v-col>
      </v-row>
    </template>
  </SaveModal>
</template>

<script>
  import TicketCommissions from '@/services/ticket-commissions'

  import SaveModal from '@/components/_Part/_Modal/Save'

  import SuggestClosings from '@/components/Closings/Suggest'
  import SuggestAccounts from '@/components/Accounts/Suggest'

  export default {
    name: 'UpdateMass',
    props: [
      'closingId',
      'ids'
    ],
    emits: [
      'onUpdateMass'
    ],
    components: {
      SaveModal,
      SuggestClosings,
      SuggestAccounts
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketCommission?.status
      },
      statuses: function () {
        return Object.values(this.statusList)
      },
      payList: function () {
        return this.$store.state.core.Entity.TicketCommission?.pay
      },
      pays: function () {
        return Object.values(this.payList)
      }
    },
    methods: {
      setTitle() {
        return 'Hromadne upraviť provízie'
      },
      setItemForm() {
        return {
          ids: this.ids,
          ClosingWage: this.closingId,
          status: this.statusList['in-queue']?.value,
          paidAt: this.$moment().format('YYYY-MM-DD'),
          pay: this.payList['in-queue']?.value,
          Account: null
        }
      },
      updateMassData(ItemForm) {
        return TicketCommissions
          .updateMass({
            data: ItemForm
          })
      }
    }
  }
</script>
