<template>
  <BlankPage
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          maxWidth="100%"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <v-tabs
                  v-model="tabs"
              >
                <v-tab>
                  Položky
                </v-tab>

                <v-tab>
                  Notifikácie
                </v-tab>

                <v-tab>
                  Výplaty
                </v-tab>
              </v-tabs>

              <v-tabs-items
                  v-model="tabs"
              >
                <v-tab-item>
                  <TicketItemsAuth/>
                </v-tab-item>

                <v-tab-item>
                  <NotificationsAuth/>
                </v-tab-item>

                <v-tab-item>
                  <WagesAuth/>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import NotificationsAuth from '@/components/Dashboard/NotificationsAuth'
  import TicketItemsAuth from '@/components/Dashboard/TicketItemsAuth'
  import WagesAuth from '@/components/Dashboard/WagesAuth'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Dashboard',
    components: {
      NotificationsAuth,
      TicketItemsAuth,
      WagesAuth,
      BlankPage,
      CardPart
    },
    data() {
      return {
        tabs: 0
      }
    },
    methods: {
      setTitle() {
        return 'Nástenka'
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
