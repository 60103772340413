import Vue from 'vue'

import router from '@/plugins/router'
import store from '@/plugins/store'
import vuetify from '@/plugins/vuetify'

import '@/plugins/axios'
import '@/plugins/editor'
import '@/plugins/file-upload-as-bsf'
import '@/plugins/filters'
import '@/plugins/highcharts'
import '@/plugins/json-pretty'
import '@/plugins/moment'
import '@/plugins/meta'
import '@/plugins/signature-pad'
import '@/plugins/vuelidate'

import '@/helpers/global'

import App from '@/App'

import '@/assets/css/style.css'

const options = {
  router,
  store,
  vuetify,
  render: (element) => {
    return element(App)
  }
}

new Vue(options).$mount('#app')
