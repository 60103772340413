<template>
  <CreateSave
      :maxWidth="750"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreateClient', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <vue-editor
              v-model="ItemForm.note"
          />
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import Projects from '@/services/projects'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'CreateClient',
    props: [
      'clientId'
    ],
    emits: [
      'onCreateClient'
    ],
    components: {
      CreateSave
    },
    methods: {
      setTitle() {
        return 'Vytvoriť projekt'
      },
      setItemForm() {
        return {
          title: null,
          note: null
        }
      },
      createData(ItemForm) {
        return Projects
          .createClient({
            clientId: this.clientId,
            data: ItemForm
          })
      }
    }
  }
</script>
