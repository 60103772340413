import store from '@/plugins/store'

const Base = function (to, from, next) {
  store.dispatch('auth/check')
    .finally(() => {
      next()
    })
}

export default Base
