<template>
  <SaveModal
      color="warning"
      icon="mdi-pencil"
      :maxWidth="500"
      button="Nastaviť"
      itemKey="User"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="profileSetData"
      @onSave="onProfileSet"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-select
              v-model="ItemForm.gender"
              :error-messages="errors.gender"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Pohlavie"
              :items="genders"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.name"
              :error-messages="errors.name"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Meno"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.emailBusiness"
              :error-messages="errors.emailBusiness"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail firemný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.emailPersonal"
              :error-messages="errors.emailPersonal"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail osobný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.phone"
              :error-messages="errors.phone"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Telefón"
          />
        </v-col>
      </v-row>
    </template>
  </SaveModal>
</template>

<script>
  import UserProfile from '@/services/user-profile'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'ProfileSet',
    components: {
      SaveModal
    },
    computed: {
      genderList: function () {
        return this.$store.state.core.Entity.User?.gender
      },
      genders: function () {
        return Object.values(this.genderList)
      }
    },
    methods: {
      fetchData() {
        return UserProfile
          .exist()
      },
      setTitle() {
        return 'Nastaviť profil'
      },
      setItemForm(Item) {
        return {
          gender: Item.gender,
          name: Item.name,
          emailBusiness: Item.emailBusiness,
          emailPersonal: Item.emailPersonal,
          phone: Item.phone
        }
      },
      profileSetData(ItemForm) {
        return UserProfile
          .set({
            data: ItemForm
          })
      },
      onProfileSet() {
        setTimeout(() => {
          window.location.reload()
        }, 100)
      }
    }
  }
</script>
