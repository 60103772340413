import Vue from 'vue'

import VueRouter from 'vue-router'

import AuthHome from '@/middleware/auth/home'
import AuthError from '@/middleware/auth/error'
import AuthBase from '@/middleware/auth/base'
import AuthLogged from '@/middleware/auth/logged'
import AuthUnLogged from '@/middleware/auth/unlogged'

import PageAuth from '@/views/PageAuth'
import Login from '@/views/Login'
import NewPasswordRestore from '@/views/NewPasswordRestore'
import NewPasswordSet from '@/views/NewPasswordSet'
import PageBackend from '@/views/PageBackend'
import Dashboard from '@/views/Dashboard'
import WageAuth from '@/views/WageAuth'
import Users from '@/views/Users'
import Tariffs from '@/views/Tariffs'
import Clients from '@/views/Clients'
import Client from '@/views/Client'
import Tickets from '@/views/Tickets'
import Ticket from '@/views/Ticket'
import TicketItem from '@/views/TicketItem'
import TicketItemAuth from '@/views/TicketItemAuth'
import Management from '@/views/Management'
import Wage from '@/views/Wage'
import Finance from '@/views/Finance'
import Bilance from '@/views/Bilance'
import Accounts from '@/views/Accounts'
import IndexGroups from '@/views/IndexGroups'
import IndexGroup from '@/views/IndexGroup'
import AuditLogs from '@/views/AuditLogs'
import AuditLog from '@/views/AuditLog'
import Mails from '@/views/Mails'
import Mail from '@/views/Mail'
import Files from '@/views/Files'
import File from '@/views/File'
import PageDocument from '@/views/PageDocument'
import Pricing from '@/views/Pricing'
import Protocol from '@/views/Protocol'
import PageError from '@/views/PageError'
import Error from '@/views/Error'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      role: 'ROLE_WORKER'
    },
    beforeEnter: AuthHome
  },
  {
    path: '*',
    meta: {
      role: 'ROLE_WORKER'
    },
    beforeEnter: AuthError
  },
  {
    path: '/',
    component: PageAuth,
    children: [
      {
        path: '/login',
        component: Login,
        beforeEnter: AuthUnLogged
      },
      {
        path: '/new-password/restore',
        component: NewPasswordRestore,
        beforeEnter: AuthUnLogged
      },
      {
        path: '/new-password/set/:newPasswordToken',
        component: NewPasswordSet,
        beforeEnter: AuthUnLogged
      }
    ]
  },
  {
    path: '/',
    component: PageBackend,
    children: [
      {
        path: '/dashboard',
        component: Dashboard,
        meta: {
          role: 'ROLE_WORKER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/wage-auth/:id',
        component: WageAuth,
        meta: {
          role: 'ROLE_WORKER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/users',
        component: Users,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/tariffs',
        component: Tariffs,
        meta: {
          role: 'ROLE_SELLER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/clients',
        component: Clients,
        meta: {
          role: 'ROLE_SELLER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/client/:id',
        component: Client,
        meta: {
          role: 'ROLE_SELLER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/tickets',
        component: Tickets,
        meta: {
          role: 'ROLE_MANAGER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/ticket/:id',
        component: Ticket,
        meta: {
          role: 'ROLE_MANAGER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/ticket-item/:id',
        component: TicketItem,
        meta: {
          role: 'ROLE_MANAGER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/ticket-item-auth/:id',
        component: TicketItemAuth,
        meta: {
          role: 'ROLE_WORKER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/management/:userId?',
        component: Management,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/wage/:id',
        component: Wage,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/finance/:accountId?',
        component: Finance,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/bilance/:id',
        component: Bilance,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/accounts',
        component: Accounts,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/index-groups',
        component: IndexGroups,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/index-group/:id',
        component: IndexGroup,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/audit-logs',
        component: AuditLogs,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/audit-log/:id',
        component: AuditLog,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/mails',
        component: Mails,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/mail/:id',
        component: Mail,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/files',
        component: Files,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/file/:id',
        component: File,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      }
    ]
  },
  {
    path: '/',
    component: PageDocument,
    children: [
      {
        path: '/pricing/:pricingToken',
        component: Pricing,
        beforeEnter: AuthUnLogged
      },
      {
        path: '/protocol/:protocolToken',
        component: Protocol,
        beforeEnter: AuthUnLogged
      }
    ]
  },
  {
    path: '/',
    component: PageError,
    children: [
      {
        path: '/error',
        component: Error,
        beforeEnter: AuthBase
      }
    ]
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
