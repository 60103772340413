<template>
  <FormPart
      :Item="Ticket"
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.protocolEmail"
              :error-messages="errors.protocolEmail"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail"
              :clearable="true"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.protocolName"
              :error-messages="errors.protocolName"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Meno"
              :clearable="true"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.protocolMessage"
              :error-messages="errors.protocolMessage"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Správa"
              :rows="5"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-btn
              type="submit"
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
          >
            <v-icon>
              mdi-send
            </v-icon>

            Odoslať
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import TicketProtocol from '@/services/ticket-protocol'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'FormProtocolSend',
    props: [
      'Ticket'
    ],
    emits: [
      'refresh'
    ],
    components: {
      FormPart
    },
    methods: {
      setItemForm(Item) {
        return {
          token: Item.token,
          protocolEmail: Item.Project?.Client?.contactEmail,
          protocolName: Item.Project?.Client?.contactName,
          protocolMessage: null
        }
      },
      submitData(ItemForm) {
        return TicketProtocol
          .send({
            data: ItemForm
          })
      },
      onSuccess() {
        this.$emit('refresh')
      },
      onFail(response) {
        if (response.status !== 409) {
          this.$router.push('/tickets')
        }
      }
    }
  }
</script>
