<template>
  <v-card>
    <v-card-title>
      <TruncateHelper
          :title="title"
          :maxWidth="maxWidth"
      >
        {{ title }}
      </TruncateHelper>

      <slot
          name="action"
      />
    </v-card-title>

    <v-card-text>
      <slot
          name="default"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Card',
    props: [
      'maxWidth',
      'title'
    ],
    components: {
      TruncateHelper
    }
  }
</script>
