import Vue from 'vue'

import Qs from 'qs'

class Bilances {
  async indexAccount({accountId, params}) {
    return await Vue.axios
      .get('app/bilances/by-account/' + accountId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/bilances/' + id)
  }
}

const bilances = new Bilances()

export default bilances
