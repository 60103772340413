<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      :color="color"
      @click.prevent.stop="init"
      v-bind="$attrs"
  >
    <v-icon>
      {{ icon }}
    </v-icon>

    <v-dialog
        v-if="dialog"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        :maxWidth="maxWidth"
    >
      <CardPart
          maxWidth="calc(100% - 40px)"
          :title="title"
      >
        <template
            v-slot:action
        >
          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </template>

        <template
            v-slot:default
        >
          <slot
              name="default"
              :Item="Item"
              :closeDialog="closeDialog"
          />
        </template>
      </CardPart>
    </v-dialog>
  </v-btn>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Modal',
    props: [
      'color',
      'icon',
      'maxWidth',
      'itemKey',
      'fetchData',
      'setTitle'
    ],
    emits: [
      'onSuccess',
      'onFail'
    ],
    components: {
      CardPart
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Item: null,
        title: null
      }
    },
    methods: {
      closeDialog() {
        this.dialog = false
      },
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        if (this.fetchData) {
          this.loading = true

          this.dialog = false

          this.fetchData()
            .then((response) => {
              this.dialog = true

              this.Item = response.data.data[this.itemKey]

              this.title = this.setTitle(this.Item)

              this.$emit('onSuccess', response)
            })
            .catch((error) => {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.dialog = true

          this.title = this.setTitle()
        }
      }
    }
  }
</script>
