<template>
  <ModalPart
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :itemKey="itemKey"
      :fetchData="fetchData"
      :setTitle="setTitle"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          closeDialog
        }"
    >
      <FormPart
          :Item="Item"
          :setItemForm="setItemForm"
          :submitData="saveData"
          @onSuccess="(response) => {
            $emit('onSave', response)

            closeDialog()
          }"
          @onFail="closeDialog"
      >
        <template
            v-slot:default="{
              loading,
              errors,
              ItemForm
            }"
        >
          <slot
              name="default"
              :Item="Item"
              :errors="errors"
              :ItemForm="ItemForm"
          />

          <v-row>
            <v-col
                cols="12"
            >
              <v-btn
                  type="submit"
                  :rounded="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="loading"
                  :color="color"
              >
                <v-icon>
                  {{ icon }}
                </v-icon>

                {{ button }}
              </v-btn>

              &nbsp;

              <v-btn
                  :rounded="true"
                  :outlined="true"
                  color="default"
                  @click="closeDialog"
              >
                <v-icon>
                  mdi-close
                </v-icon>

                Zrušiť
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </FormPart>
    </template>
  </ModalPart>
</template>

<script>
  import ModalPart from '@/components/_Part/Modal'
  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Save',
    props: [
      'color',
      'icon',
      'maxWidth',
      'button',
      'itemKey',
      'fetchData',
      'setTitle',
      'setItemForm',
      'saveData'
    ],
    emits: [
      'onSave'
    ],
    components: {
      ModalPart,
      FormPart
    }
  }
</script>
