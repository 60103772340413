<template>
  <v-menu
      :open-on-hover="true"
      :offset-y="true"
      :bottom="true"
      :left="true"
      :right="true"
      max-width="500px"
  >
    <template
        v-slot:activator="{
          on,
          attrs
        }"
    >
      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>
          mdi-bell
        </v-icon>

        <v-badge
            v-if="$store.state.auth.User.unseenNotifications"
            color="error"
            :content="$store.state.auth.User.unseenNotifications"
        />
      </v-btn>
    </template>

    <v-list
        v-if="$store.state.auth.User.notificationsLast5.length > 0"
        :three-line="true"
        :dense="true"
    >
      <v-list-item
          v-for="(_Notification) in $store.state.auth.User.notificationsLast5"
          :key="_Notification.id"
          :href="$global.axiosUrl() + 'app/notification/visit/auth/' + _Notification.id"
      >
        <v-list-item-content>
          <v-list-item-title>
            <span
                v-if="_Notification.status === statusList.unseen?.value"
                class="primary--text font-weight-bold"
            >
              <v-icon
                  :small="true"
                  color="primary"
              >
                mdi-eye
              </v-icon>

              {{ _Notification.message }}
            </span>

            <template
                v-if="_Notification.status === statusList.seen?.value"
            >
              {{ _Notification.message }}
            </template>
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ _Notification.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'Notifications',
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.Notification?.status
      }
    }
  }
</script>
