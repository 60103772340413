<template>
  <DeleteConfirm
      itemKey="TicketItemComment"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :deleteData="deleteData"
      @onDelete="(response) => $emit('onDelete', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import TicketItemComments from '@/services/ticket-item-comments'

  import DeleteConfirm from '@/components/_Part/_Modal/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: [
      'id'
    ],
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    methods: {
      fetchData() {
        return TicketItemComments
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Odstrániť komentár z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name
      },
      setQuestion(Item) {
        return 'Naozaj chcete odstrániť komentár z ' + this.$moment(Item.createdAt).format('DD.MM.YYYY HH:mm') + ' od ' + Item.Creator.name + '?'
      },
      deleteData() {
        return TicketItemComments
          .delete({
            id: this.id
          })
      }
    }
  }
</script>
