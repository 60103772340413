var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" Výplaty ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalWages,'€'))+" "),(_vm.Bilance.totalWagesBank || _vm.Bilance.totalWagesCash)?[_c('br'),_c('small',[(_vm.Bilance.totalWagesBank)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-bank-transfer ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalWagesBank,'€'))+" ")]:_vm._e(),(_vm.Bilance.totalWagesCash)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.Bilance.totalWagesCash,'€'))+" ")]:_vm._e()],2)]:_vm._e()],2),_c('IsEmptyHelper',{attrs:{"value":_vm.Bilance.bilanceWages.length > 0,"if-no":"table","if-yes":"p","no-gutters":true}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"2.5%"}},[_c('v-checkbox',{attrs:{"dense":true,"hide-details":"auto"},on:{"change":(value) => {
                  _vm.ids = []

                  _vm.total = 0

                  if (value) {
                    _vm.Bilance.bilanceWages.forEach((_BilanceWage) => {
                      _vm.ids.push(_BilanceWage.id)

                      _vm.total += _BilanceWage.total
                    })
                  }
                }},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Dátum ")]),_c('th',{attrs:{"width":"70%","align":"left"}},[_vm._v(" Názov ")]),_c('th',{attrs:{"width":"10%","align":"left"}},[_vm._v(" Suma ")]),_c('th',{attrs:{"width":"7.5%","align":"left"}},[_vm._v(" Platba ")])])]),_c('tbody',_vm._l((_vm.Bilance.bilanceWages),function(_BilanceWage){return _c('tr',{key:_BilanceWage.id,attrs:{"valign":"top"}},[_c('td',[_c('v-checkbox',{attrs:{"value":_BilanceWage.id,"dense":true,"hide-details":"auto"},on:{"change":() => {
                  if (_vm.ids.length === _vm.Bilance.bilanceWages.length) {
                    _vm.all = true
                  } else {
                    _vm.all = false
                  }

                  if (_vm.ids.includes(_BilanceWage.id)) {
                    _vm.total += _BilanceWage.total
                  } else {
                    _vm.total -= _BilanceWage.total
                  }
                }},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_BilanceWage.paidAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_BilanceWage.paidAt,'DD.MM.YYYY'))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_BilanceWage.userName)+" za "),_c('IsEmptyHelper',{attrs:{"value":_BilanceWage.wageClosedAt,"if-no":"span","if-yes":"span","empty":"Neuzavreté"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_BilanceWage.wageClosedAt,'MM/YYYY'))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_BilanceWage.total,'€'))+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":_BilanceWage.pay,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm.payList[_BilanceWage.pay]?.text)+" ")])],1)])}),0),(_vm.ids.length > 0)?_c('tfoot',[_c('tr',[_c('th',{attrs:{"align":"left","colspan":3}}),_c('th',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total,'€'))+" ")]),_c('th',{attrs:{"align":"left","colspan":2}})])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }