<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/dashboard"
      itemKey="TicketItem"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item,
          refresh
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <h3>
            Projekt
          </h3>

          <IsEmptyHelper
              :value="Item.Ticket.currentProjectTitle"
              if-no="p"
              if-yes="p"
          >
            {{ Item.Ticket.currentProjectTitle }}
          </IsEmptyHelper>
        </v-col>

        <template
            v-if="Item.showableTask || Item.showableTicketItemComments || Item.showableTicketItemTasks"
        >
          <v-col
              cols="12"
              lg="8"
          >
            <v-row>
              <template
                  v-if="Item.showableTask"
              >
                <v-col
                    cols="12"
                >
                  <PanelPart
                      maxWidth="100%"
                      color="primary"
                      icon="mdi-content-copy"
                      title="Zadanie"
                  >
                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.content"
                          if-no="div"
                          if-yes="p"
                      >
                        <div
                            v-html="Item.content"
                        />
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>

                <v-col
                    cols="12"
                >
                  <PanelPart
                      maxWidth="100%"
                      color="primary"
                      icon="mdi-attachment"
                      :title="'Prílohy (' + Item.files.length + ')'"
                  >
                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.files.length > 0"
                          if-no="v-row"
                          if-yes="p"
                          empty="žiadne"
                      >
                        <v-col
                            v-for="(_File) in Item.files"
                            :key="_File.id"
                            cols="auto"
                            align="center"
                        >
                          <a
                              :href="$global.axiosUrl() + 'files/'  + _File.token + '/generate/original'"
                              target="_blank"
                          >
                            <v-icon
                                :large="true"
                            >
                              mdi-file
                            </v-icon>

                            <br/>

                            {{ _File.name }}
                          </a>
                        </v-col>
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>

                <v-col
                    v-if="Item.Ticket?.Project"
                    cols="12"
                >
                  <PanelPart
                      maxWidth="calc(100% - 40px)"
                      color="primary"
                      icon="mdi-key"
                      title="Poznámka"
                      :value="Item.Ticket?.Project?.note"
                  >
                    <template
                        v-slot:action
                    >
                      <v-spacer/>

                      <UpdateNoteProjectAuth
                          :small="true"
                          :id="Item.Ticket?.Project?.id"
                          @onUpdateNote="refresh"
                      />
                    </template>

                    <template
                        v-slot:default
                    >
                      <IsEmptyHelper
                          :value="Item.Ticket?.Project?.note"
                          if-no="div"
                          if-yes="p"
                      >
                        <div
                            v-html="Item.Ticket?.Project?.note"
                        />
                      </IsEmptyHelper>
                    </template>
                  </PanelPart>
                </v-col>
              </template>

              <v-col
                  v-if="Item.showableTicketItemComments"
                  cols="12"
              >
                <TicketItemComments
                    :TicketItem="Item"
                    @refresh="refresh"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
              v-if="Item.showableTicketItemTasks"
              cols="12"
              lg="4"
          >
            <TicketItemTasks
                :TicketItem="Item"
                @refresh="refresh"
            />
          </v-col>
        </template>

        <v-col
            cols="12"
            lg="3"
        >
          <CardPart
              maxWidth="100%"
              title="Informácie"
          >
            <v-row>
              <template
                  v-if="Item.showableStatus"
              >
                <v-col
                    v-if="Item.updatableStatus"
                    cols="12"
                >
                  <FormStatus
                      :TicketItem="Item"
                      @refresh="refresh"
                  />
                </v-col>

                <template
                    v-else
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Stav
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.status"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ statusList[Item.status]?.text }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </template>

              <template
                  v-if="Item.showableSettings"
              >
                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Dátum dokončenia
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.deadlineAt"
                          if-no="p"
                          if-yes="p"
                      >
                        <v-icon
                            v-if="Item.alertDeadlineAt && Item.printableAlert"
                            color="error"
                        >
                          mdi-alert
                        </v-icon>

                        {{ Item.deadlineAt|moment('DD.MM.YYYY') }}
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Priorita
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.priority"
                          if-no="p"
                          if-yes="p"
                      >
                        {{ Item.priority }} úroveň
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>
              </template>

              <v-col
                  cols="12"
              >
                <v-row
                    align="center"
                >
                  <v-col
                      cols="6"
                  >
                    <h4>
                      Zostávajúci čas
                    </h4>
                  </v-col>

                  <v-col
                      cols="6"
                  >
                    <p>
                      <v-icon
                          v-if="Item.alertTimeRemain && Item.printableAlert"
                          color="error"
                      >
                        mdi-alert
                      </v-icon>

                      {{ Item.timeRemainReports|number }} h
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <template
                  v-if="Item.showableAssignment"
              >
                <v-col
                    cols="12"
                >
                  <v-divider/>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-row
                      align="center"
                  >
                    <v-col
                        cols="6"
                    >
                      <h4>
                        Vedúci
                      </h4>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <IsEmptyHelper
                          :value="Item.Leader?.name"
                          if-no="p"
                          if-yes="p"
                      >
                        {{ Item.Leader?.name }}
                      </IsEmptyHelper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    v-if="Item.updatableAssignment"
                    cols="12"
                >
                  <FormAssignment
                      :TicketItem="Item"
                      @refresh="refresh"
                  />
                </v-col>

                <template
                    v-else
                >
                  <v-col
                      cols="12"
                  >
                    <v-row
                        align="center"
                    >
                      <v-col
                          cols="6"
                      >
                        <h4>
                          Pracovník
                        </h4>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <IsEmptyHelper
                            :value="Item.Worker?.name"
                            if-no="p"
                            if-yes="p"
                        >
                          {{ Item.Worker?.name }}
                        </IsEmptyHelper>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </template>
            </v-row>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
            lg="9"
        >
          <v-tabs
              v-model="tabs"
          >
            <v-tab
                v-if="Item.showableTicketItemReports"
            >
              Výkazy
            </v-tab>
          </v-tabs>

          <v-tabs-items
              v-model="tabs"
          >
            <v-tab-item
                v-if="Item.showableTicketItemReports"
            >
              <TicketItemReports
                  :TicketItem="Item"
                  @refresh="refresh"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import TicketItemsAuth from '@/services/ticket-items-auth'

  import TicketItemComments from '@/components/TicketItemAuth/TicketItemComments'
  import TicketItemTasks from '@/components/TicketItemAuth/TicketItemTasks'
  import TicketItemReports from '@/components/TicketItemAuth/TicketItemReports'
  import FormStatus from '@/components/TicketItemAuth/FormStatus'
  import FormAssignment from '@/components/TicketItemAuth/FormAssignment'

  import UpdateNoteProjectAuth from '@/components/ProjectsAuth/UpdateNote'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import PanelPart from '@/components/_Part/Panel'
  import CardPart from '@/components/_Part/Card'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'TicketItemAuth',
    components: {
      TicketItemComments,
      TicketItemTasks,
      TicketItemReports,
      FormStatus,
      FormAssignment,
      UpdateNoteProjectAuth,
      NotBlankPage,
      PanelPart,
      CardPart,
      IsEmptyHelper
    },
    data() {
      return {
        tabs: 0
      }
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.TicketItem?.status
      }
    },
    methods: {
      fetchData() {
        return TicketItemsAuth
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Nástenka',
            to: '/dashboard'
          }
        ]
      }
    }
  }
</script>
