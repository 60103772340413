<template>
  <NotBlankPage
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/dashboard"
      itemKey="Wage"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <TicketItemReports
              :Wage="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <TicketBonuses
              :Wage="Item"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <TicketCommissions
              :Wage="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import WagesAuth from '@/services/wages-auth'

  import TicketItemReports from '@/components/WageAuth/TicketItemReports'
  import TicketBonuses from '@/components/WageAuth/TicketBonuses'
  import TicketCommissions from '@/components/WageAuth/TicketCommissions'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'WageAuth',
    components: {
      TicketItemReports,
      TicketBonuses,
      TicketCommissions,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return WagesAuth
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        if (Item.Closing.closedAt) {
          return this.$moment(Item.Closing?.closedAt).format('MM/YYYY')
        } else {
          return 'Neuzavreté'
        }
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Nástenka',
            to: '/dashboard'
          }
        ]
      }
    }
  }
</script>
