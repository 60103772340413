var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SaveModal',_vm._b({attrs:{"color":"success","icon":"mdi-pen","maxWidth":_vm.maxWidth,"button":"Podpísať","setTitle":_vm.setTitle,"setItemForm":_vm.setItemForm,"saveData":_vm.signatureData},on:{"onSave":(response) => _vm.$emit('onSignature', response)},scopedSlots:_vm._u([{key:"default",fn:function({
        errors,
        ItemForm
      }){return [(errors.name || errors.type || errors.content || errors.size)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Podpis nie je vyplnený! ")]):_vm._e(),_c('VueSignaturePad',{ref:"signature",attrs:{"width":_vm.width,"height":_vm.height,"options":{
          onBegin: () => {
            _vm.$refs.signature.resizeCanvas()
          },
          onEnd: () => {
            const signature = _vm.$refs.signature.saveSignature()

            let SignatureForm = _vm.loadSignature(signature)

            if (SignatureForm) {
              ItemForm.name = SignatureForm.name

              ItemForm.type = SignatureForm.type

              ItemForm.content = SignatureForm.content

              ItemForm.size = SignatureForm.size
            }
          }
        }}})]}}])},'SaveModal',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }