import Vue from 'vue'

import Qs from 'qs'

class Accounts {
  async suggest() {
    return await Vue.axios
      .get('app/accounts/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('app/accounts', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/accounts', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/accounts/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/accounts/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/accounts/' + id)
  }

  async uploadSignature({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/accounts/' + id + '/upload/signature', data)
    } else {
      return await Vue.axios
        .post('app/accounts/upload/signature', data)
    }
  }
}

const accounts = new Accounts()

export default accounts
