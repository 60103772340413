<template>
  <ConfirmModal
      color="red"
      icon="mdi-lock-off"
      :maxWidth="500"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="restoreData"
      @onConfirm="(response) => $emit('onRestore', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Tickets from '@/services/tickets'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'Restore',
    props: [
      'id'
    ],
    emits: [
      'onRestore'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Obnoviť tiket "' + Item.title + '"'
      },
      setQuestion(Item) {
        return 'Naozaj chcete obnoviť tiket "' + Item.title + '"?'
      },
      restoreData() {
        return Tickets
          .restore({
            id: this.id
          })
      }
    }
  }
</script>
