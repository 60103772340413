var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CreateSave',_vm._b({attrs:{"maxWidth":500,"setTitle":_vm.setTitle,"setItemForm":_vm.setItemForm,"createData":_vm.createData},on:{"onCreate":(response) => _vm.$emit('onCreateTicketItem', response)},scopedSlots:_vm._u([{key:"default",fn:function({
        errors,
        ItemForm
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.workedAt,"filled":true,"dense":true,"hide-details":"auto","label":"Dátum vykonania","type":"date"},model:{value:(ItemForm.workedAt),callback:function ($$v) {_vm.$set(ItemForm, "workedAt", $$v)},expression:"ItemForm.workedAt"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.timeSpend,"filled":true,"dense":true,"hide-details":"auto","label":"Strávený čas","type":"number","suffix":"h"},model:{value:(ItemForm.timeSpend),callback:function ($$v) {_vm.$set(ItemForm, "timeSpend", $$v)},expression:"ItemForm.timeSpend"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"error-messages":errors.note,"filled":true,"dense":true,"hide-details":"auto","label":"Poznámka","rows":5},model:{value:(ItemForm.note),callback:function ($$v) {_vm.$set(ItemForm, "note", $$v)},expression:"ItemForm.note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-input',{attrs:{"error-messages":errors.files,"filled":true,"dense":true,"hide-details":"auto","label":"Prílohy"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("   "),_c('v-row',{attrs:{"no-gutters":true,"dense":true}},[(_vm.files.length > 0)?_vm._l((_vm.files),function(_file,index){return _c('v-col',{key:_file.id,attrs:{"cols":"12"}},[_c('a',{attrs:{"href":_vm.$global.axiosUrl() + 'files/'  + _file.token + '/generate/original',"target":"_blank"}},[_vm._v(" "+_vm._s(_file.name)+" ")]),_c('v-btn',{attrs:{"x-small":true,"icon":true,"outlined":true,"color":"error"},on:{"click":() => {
                        ItemForm.files.splice(index, 1)

                        _vm.files.splice(index, 1)
                      }}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('File',{attrs:{"x-small":true,"id":null},on:{"onFile":(response) => {
                      ItemForm.files.push(response.data.data.id)

                      _vm.files.push({
                        id: response.data.data.id,
                        token: response.data.data.token,
                        name: response.data.data.name
                      })
                    }}})],1)],2)]},proxy:true}],null,true)})],1)],1)]}}])},'CreateSave',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }