<template>
  <NotBlankPage
      maxWidth="calc(100% - 40px)"
      :hasBackLink="false"
      failLink="/home"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <iframe
              :src="$global.axiosUrl() + 'app/ticket/protocol/preview/' + Item.protocolToken"
              width="100%"
              height="1000px"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-btn
              color="info"
              :large="true"
              :block="true"
              @click="$refs.confirm.$el.click()"
          >
            <v-icon>
              mdi-check
            </v-icon>

            Potvrdiť
          </v-btn>

          <Confirm
              ref="confirm"
              :x-small="true"
              :protocolToken="Item.protocolToken"
              class="d-none"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import TicketProtocol from '@/services/ticket-protocol'

  import Confirm from '@/components/Protocol/Confirm'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'Protocol',
    components: {
      Confirm,
      NotBlankPage
    },
    methods: {
      fetchData() {
        return TicketProtocol
          .exist({
            protocolToken: this.$route.params.protocolToken
          })
      },
      setTitle(Item) {
        return 'Protokol č.' + Item.id
      },
      setBreadcrumbs() {
        return []
      }
    }
  }
</script>
