import store from '@/plugins/store'

const Logged = function (to, from, next) {
  store.dispatch('auth/check')
    .finally(() => {
      if (!(store.getters['auth/isLogged'] && store.getters['auth/hasRole'](to.meta.role))) {
        next('/error')
      }

      next()
    })
}

export default Logged
