<template>
  <SuggestPart
      item-value="id"
      item-text="title"
      itemsKey="ticketItems"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import SuggestPart from '@/components/_Part/Suggest'

  export default {
    name: 'SuggestTicket',
    props: [
      'ticketId'
    ],
    components: {
      SuggestPart
    },
    methods: {
      fetchData() {
        return TicketItems
          .suggestTicket({
            ticketId: this.ticketId
          })
      }
    }
  }
</script>
