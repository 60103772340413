<template>
  <CreateSave
      :maxWidth="500"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreateTicket', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestTariffs
              v-model="ItemForm.Tariff"
              :error-messages="errors.Tariff"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Tarifa"
              :createButton="true"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import TicketItems from '@/services/ticket-items'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import SuggestTariffs from '@/components/Tariffs/Suggest'

  export default {
    name: 'CreateTicket',
    props: [
      'ticketId'
    ],
    emits: [
      'onCreateTicket'
    ],
    components: {
      CreateSave,
      SuggestTariffs
    },
    methods: {
      setTitle() {
        return 'Vytvoriť položku'
      },
      setItemForm() {
        return {
          Tariff: null,
          title: null
        }
      },
      createData(ItemForm) {
        return TicketItems
          .createTicket({
            ticketId: this.ticketId,
            data: ItemForm
          })
      }
    }
  }
</script>
