const master = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/users',
    title: 'Užívatelia',
    icon: 'mdi-account'
  },
  {
    to: '/tariffs',
    title: 'Tarify',
    icon: 'mdi-tag'
  },
  {
    to: '/clients',
    title: 'Klienti',
    icon: 'mdi-domain'
  },
  {
    to: '/tickets',
    title: 'Tikety',
    icon: 'mdi-briefcase'
  },
  {
    to: '/management',
    title: 'Manažment',
    icon: 'mdi-timeline'
  },
  {
    to: '/finance',
    title: 'Financie',
    icon: 'mdi-finance'
  },
  {
    title: 'Nastavenia',
    icon: 'mdi-cog',
    value: false,
    subItems: [
      {
        to: '/accounts',
        title: 'Účty'
      },
      {
        to: '/index-groups',
        title: 'Skupiny číselníkov'
      },
      {
        to: '/audit-logs',
        title: 'Audit logy'
      },
      {
        to: '/mails',
        title: 'Maily'
      },
      {
        to: '/files',
        title: 'Súbory'
      }
    ]
  }
]

const seller = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/tariffs',
    title: 'Tarify',
    icon: 'mdi-tag'
  },
  {
    to: '/clients',
    title: 'Klienti',
    icon: 'mdi-domain'
  },
  {
    to: '/tickets',
    title: 'Tikety',
    icon: 'mdi-briefcase'
  }
]

const manager = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/tickets',
    title: 'Tikety',
    icon: 'mdi-briefcase'
  }
]

const leader = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const worker = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const items = {
  master,
  seller,
  manager,
  leader,
  worker
}

const menu = {
  items
}

export default menu
