<template>
  <CreateSave
      :maxWidth="750"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="(response) => $emit('onCreate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
            lg="2"
        >
          <v-select
              v-model="ItemForm.gender"
              :error-messages="errors.gender"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Pohlavie"
              :items="genders"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.name"
              :error-messages="errors.name"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Meno"
          />
        </v-col>

        <v-col
            cols="12"
            lg="5"
        >
          <v-text-field
              v-model="ItemForm.username"
              :error-messages="errors.username"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Užívateľské meno"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.emailBusiness"
              :error-messages="errors.emailBusiness"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail firemný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.emailPersonal"
              :error-messages="errors.emailPersonal"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="E-mail osobný"
              type="email"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.phone"
              :error-messages="errors.phone"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Telefón"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-select
              v-model="ItemForm.type"
              :error-messages="errors.type"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Typ"
              :items="types"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.basePriceHour"
              :error-messages="errors.basePriceHour"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Základný plat"
              type="number"
              suffix="€ / h"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.minPriceHour"
              :error-messages="errors.minPriceHour"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Minimálny plat"
              type="number"
              suffix="€ / h"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-checkbox
              v-model="ItemForm.agreementGdpr"
              :error-messages="errors.agreementGdpr"
              :dense="true"
              hide-details="auto"
              label="Súhlasím so spracovaním osobných údajov"
          />
        </v-col>
      </v-row>
    </template>
  </CreateSave>
</template>

<script>
  import Users from '@/services/users'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'Create',
    emits: [
      'onCreate'
    ],
    components: {
      CreateSave
    },
    computed: {
      genderList: function () {
        return this.$store.state.core.Entity.User?.gender
      },
      genders: function () {
        return Object.values(this.genderList)
      },
      typeList: function () {
        return this.$store.state.core.Entity.User?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť užívateľa'
      },
      setItemForm() {
        return {
          gender: this.genderList.male?.value,
          name: null,
          username: null,
          emailBusiness: null,
          emailPersonal: null,
          phone: null,
          type: this.typeList.worker?.value,
          basePriceHour: 0,
          minPriceHour: 0,
          agreementGdpr: false
        }
      },
      createData(ItemForm) {
        return Users
          .create({
            data: ItemForm
          })
      }
    }
  }
</script>
