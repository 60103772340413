import Vue from 'vue'

class UsersAuth {
  async suggestRoleTicketItem({role, ticketItemId}) {
    return await Vue.axios
      .get('app/users/auth/suggest/by-role-and-ticket-item/' + role + '/' + ticketItemId)
  }
}

const usersAuth = new UsersAuth()

export default usersAuth
