<template>
  <SaveModal
      color="info"
      icon="mdi-check"
      :maxWidth="500"
      button="Potvrdiť"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :saveData="confirmData"
      @onSave="onConfirm"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.protocolFeedback"
              :error-messages="errors.protocolFeedback"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Recenzia"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.ProtocolClientSignature"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Podpis"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :dense="true"
                  align="center"
              >
                <v-col
                    v-if="protocolClientSignature"
                    cols="auto"
                >
                  <v-img
                      :src="$global.axiosUrl() + 'files/' + protocolClientSignature + '/generate/original'"
                      alt="Podpis"
                      max-width="300px"
                      max-height="100px"
                  />
                </v-col>

                <v-col
                    cols="auto"
                >
                  <v-row
                      :dense="true"
                  >
                    <v-col
                        cols="12"
                    >
                      <Signature
                          :small="true"
                          :protocolToken="Item.protocolToken"
                          @onSignature="(response) => {
                            ItemForm.ProtocolClientSignature = response.data.data.id

                            protocolClientSignature = response.data.data.token
                          }"
                      />
                    </v-col>

                    <v-col
                        v-if="ItemForm.ProtocolClientSignature && protocolClientSignature"
                        cols="12"
                    >
                      <v-btn
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="error"
                          @click="() => {
                            ItemForm.ProtocolClientSignature = null

                            protocolClientSignature = null
                          }"
                      >
                        <v-icon>
                          mdi-auto-fix
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </SaveModal>
</template>

<script>
  import TicketProtocol from '@/services/ticket-protocol'

  import Signature from '@/components/Protocol/Signature'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Confirm',
    props: [
      'protocolToken'
    ],
    components: {
      Signature,
      SaveModal
    },
    data() {
      return {
        token: null,
        protocolClientSignature: null
      }
    },
    methods: {
      fetchData() {
        return TicketProtocol
          .exist({
            protocolToken: this.protocolToken
          })
      },
      setTitle(Item) {
        return 'Potvrdiť protokol č.' + Item.id
      },
      setItemForm(Item) {
        this.token = Item.token

        this.protocolClientSignature = null

        return {
          protocolFeedback: null,
          ProtocolClientSignature: null
        }
      },
      confirmData(ItemForm) {
        return TicketProtocol
          .confirm({
            protocolToken: this.protocolToken,
            data: ItemForm
          })
      },
      onConfirm() {
        setTimeout(() => {
          window.location.assign(this.$global.axiosUrl() + 'tickets/' + this.token + '/generate/protocol')
        }, 100)
      }
    }
  }
</script>
