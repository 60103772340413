<template>
  <ConfirmModal
      color="primary"
      icon="mdi-logout"
      :maxWidth="500"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="logoutData"
      @onConfirm="onLogout"
      v-bind="$attrs"
  />
</template>

<script>
  import Security from '@/services/security'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'Logout',
    components: {
      ConfirmModal
    },
    methods: {
      setTitle() {
        return 'Odhlásiť sa'
      },
      setQuestion() {
        return 'Naozaj sa chcete odhlásiť?'
      },
      logoutData() {
        return Security
          .logout()
      },
      onLogout() {
        setTimeout(() => {
          this.$router.push('/login')
        }, 100)
      }
    }
  }
</script>
