<template>
  <SuggestPart
      item-value="id"
      item-text="name"
      itemsKey="users"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="createButton"
      >
        <Create
            :small="true"
            @onCreate="init"
        />

        &nbsp;
      </template>
    </template>
  </SuggestPart>
</template>

<script>
  import Users from '@/services/users'

  import SuggestPart from '@/components/_Part/Suggest'

  import Create from '@/components/Users/Create'

  export default {
    name: 'SuggestRoleTicketItem',
    props: [
      'role',
      'ticketItemId',
      'createButton'
    ],
    components: {
      SuggestPart,
      Create
    },
    methods: {
      fetchData() {
        return Users
          .suggestRoleTicketItem({
            role: this.role,
            ticketItemId: this.ticketItemId
          })
      }
    }
  }
</script>
