var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 40px)","title":title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":7,"empty":"Nenašli sa žiadne audit logy.","itemsKey":"auditLogs","filterKey":"AuditLog","defaultFilters":{
          method: null,
          statusCode: null
        },"defaultOrder":"audlog.createdAt","defaultBy":"DESC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.method,"outlined":true,"dense":true,"hide-details":"auto","label":"Metóda","clearable":true,"module":"audit_log_method"},on:{"change":setFilter},model:{value:(params.Filters.method),callback:function ($$v) {_vm.$set(params.Filters, "method", $$v)},expression:"params.Filters.method"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.statusCode,"outlined":true,"dense":true,"hide-details":"auto","label":"Stavový kód","clearable":true,"module":"audit_log_status_code"},on:{"change":setFilter},model:{value:(params.Filters.statusCode),callback:function ($$v) {_vm.$set(params.Filters, "statusCode", $$v)},expression:"params.Filters.statusCode"}})],1)],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',[_vm._v(" Uri ")]),_c('th',[_vm._v(" Užívateľ ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"audlog.createdAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Metóda ")]),_c('th',[_c('OrderList',{attrs:{"orderKey":"audlog.updatedAt","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Stavový kód ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item
          }){return [_c('td',[_c('a',{attrs:{"href":Item.uri,"target":"_blank"}},[_c('TruncateHelper',{attrs:{"title":Item.uri,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.uri)+" ")])],1)]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.User?.name,"if-no":"span","if-yes":"span"}},[_c('TruncateHelper',{attrs:{"title":Item.User?.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.User?.name)+" ")])],1)],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(Item.method)+" ")]),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.updatedAt,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.statusCode,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(Item.statusCode)+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/audit-log/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }