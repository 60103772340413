var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"maxWidth":"calc(100% - 40px)","title":_vm.title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":(Item) => {
      return _vm.statusList[Item.status]?.class
    },"extraClass":() => {},"colspan":9,"empty":"Nenašli sa žiadne položky.","itemsKey":"ticketItems","filterKey":"TicketItem","defaultFilters":{
      type: _vm.typeList['to-work']?.value
    },"defaultOrder":"custom","defaultBy":"CUSTOM","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
        params,
        errors,
        setFilter
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.type,"outlined":true,"dense":true,"hide-details":"auto","label":"Typ","items":_vm.types,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.type),callback:function ($$v) {_vm.$set(params.Filters, "type", $$v)},expression:"params.Filters.type"}})],1)],1)]}},{key:"list-head",fn:function(){return [_c('th',[_vm._v(" Projekt ")]),_c('th',{staticClass:"fixed"},[_vm._v(" Názov ")]),_c('th',[_vm._v(" Stav ")]),_c('th',[_vm._v(" Dátum dokončenia ")]),_c('th',[_vm._v(" Priorita ")]),_c('th',[_vm._v(" Zostávajúci čas ")]),_c('th',[_vm._v(" Vedúci ")]),_c('th',[_vm._v(" Pracovník ")]),_c('th',{staticClass:"text-right fixed"})]},proxy:true},{key:"list-body-standard",fn:function({
        Item
      }){return [_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.Ticket.currentProjectTitle,"if-no":"span","if-yes":"span"}},[_c('TruncateHelper',{attrs:{"title":Item.Ticket.currentProjectTitle,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.Ticket.currentProjectTitle)+" ")])],1)],1),_c('td',{staticClass:"fixed"},[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.status,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(_vm.statusList[Item.status]?.text)+" ")])],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.deadlineAt,"if-no":"span","if-yes":"span"}},[(Item.alertDeadlineAt && Item.printableAlert)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("moment")(Item.deadlineAt,'DD.MM.YYYY'))+" ")],1)],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.priority,"if-no":"span","if-yes":"span"}},[_vm._v(" "+_vm._s(Item.priority)+" úroveň ")])],1),_c('td',[(Item.alertTimeRemain && Item.printableAlert)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("number")(Item.timeRemainReports))+" h ")],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.Leader?.name,"if-no":"span","if-yes":"span"}},[_c('TruncateHelper',{attrs:{"title":Item.Leader?.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.Leader?.name)+" ")])],1)],1),_c('td',[_c('IsEmptyHelper',{attrs:{"value":Item.Worker?.name,"if-no":"span","if-yes":"span"}},[_c('TruncateHelper',{attrs:{"title":Item.Worker?.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.Worker?.name)+" ")])],1)],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/ticket-item-auth/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_c('v-badge',{attrs:{"color":"error","value":Item.alertTasks,"content":Item.undoneTasks}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }