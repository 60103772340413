import Vue from 'vue'

class ProjectsAuth {
  async read({id}) {
    return await Vue.axios
      .get('app/projects/auth/' + id)
  }

  async updateNote({id, data}) {
    return await Vue.axios
      .put('app/projects/auth/' + id + '/note', data)
  }
}

const projectsAuth = new ProjectsAuth()

export default projectsAuth
