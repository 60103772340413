var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":"calc(100% - 80px)","title":title,"hasSearch":true,"hasFilter":true,"hasExtra":false,"standardClass":() => {},"extraClass":() => {},"colspan":5,"empty":"Nenašli sa žiadne tarify.","itemsKey":"tariffs","filterKey":"Tariff","defaultFilters":{
          frequency: null,
          unit: null,
          type: null
        },"defaultOrder":"tar.title","defaultBy":"ASC","defaultLimit":25,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}}),_vm._v("   ")]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.frequency,"outlined":true,"dense":true,"hide-details":"auto","label":"Frekvencia","clearable":true,"module":"tariff_frequency"},on:{"change":setFilter},model:{value:(params.Filters.frequency),callback:function ($$v) {_vm.$set(params.Filters, "frequency", $$v)},expression:"params.Filters.frequency"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SuggestIndexesModule',{attrs:{"error-messages":errors.Filters?.unit,"outlined":true,"dense":true,"hide-details":"auto","label":"Jednotka","clearable":true,"module":"tariff_unit"},on:{"change":setFilter},model:{value:(params.Filters.unit),callback:function ($$v) {_vm.$set(params.Filters, "unit", $$v)},expression:"params.Filters.unit"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"error-messages":errors.Filters?.type,"outlined":true,"dense":true,"hide-details":"auto","label":"Typ","items":_vm.types,"clearable":true},on:{"change":setFilter},model:{value:(params.Filters.type),callback:function ($$v) {_vm.$set(params.Filters, "type", $$v)},expression:"params.Filters.type"}})],1)],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',{staticClass:"fixed"},[_c('OrderList',{attrs:{"orderKey":"tar.title","order":order,"by":by,"orderList":orderList,"byList":byList},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Sadzba ")]),_c('th',[_vm._v(" Typ ")]),_c('th',[_vm._v(" Nastavenia ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item,
            init
          }){return [_c('td',{staticClass:"fixed"},[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(Item.price,'€'))+" "),(Item.frequency)?[_vm._v(" / "+_vm._s(Item.frequency)+" ")]:_vm._e(),_vm._v(" / "+_vm._s(Item.unit)+" ")],2),_c('td',[_vm._v(" "+_vm._s(_vm.typeList[Item.type]?.text)+" ")]),_c('td',[(Item.type === _vm.typeList?.simple?.value)?[_vm._v(" "+_vm._s(_vm._f("percent")(Item.minProfit))+" ")]:_vm._e(),(Item.type === _vm.typeList?.group?.value)?[_vm._v(" "+_vm._s(_vm._f("currency")(Item.priceOffers,'€'))+" / "+_vm._s(_vm._f("number")(Item.timeOffers))+" h ")]:_vm._e(),_vm._v(" (max. "+_vm._s(_vm._f("currency")(Item.maxPriceHour,'€ / h'))+") ")],2),_c('td',{staticClass:"text-right fixed"},[_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(_vm.$store.getters['auth/isLogged'] && _vm.$store.getters['auth/hasRole']('ROLE_MASTER'))?[_vm._v("   "),_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}})]:_vm._e()],2)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }