var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SuggestPart',_vm._g(_vm._b({attrs:{"item-value":"id","item-text":(Item) => {
      if (Item.closedAt) {
        return this.$moment(Item.closedAt).format('MM/YYYY')
      } else {
        return 'Neuzavreté'
      }
    },"itemsKey":"closings","fetchData":_vm.fetchData}},'SuggestPart',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }