import Vue from 'vue'

class TicketType {
  async choosePricing({data}) {
    return await Vue.axios
      .post('app/ticket/type/choose/pricing', data)
  }

  async chooseUnlimited({data}) {
    return await Vue.axios
      .post('app/ticket/type/choose/unlimited', data)
  }
}

const ticketType = new TicketType()

export default ticketType
