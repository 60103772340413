<template>
  <UpdateSave
      :maxWidth="750"
      itemKey="Tariff"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.title"
              :error-messages="errors.title"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Názov"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.description"
              :error-messages="errors.description"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Popis"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <v-text-field
              v-model="ItemForm.price"
              :error-messages="errors.price"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Sadzba"
              type="number"
              suffix="€"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <SuggestIndexesModule
              v-model="ItemForm.frequency"
              :error-messages="errors.frequency"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Frekvencia"
              :clearable="true"
              module="tariff_frequency"
          />
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <SuggestIndexesModule
              v-model="ItemForm.unit"
              :error-messages="errors.unit"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Jednotka"
              :clearable="true"
              module="tariff_unit"
          />
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <v-select
              v-model="ItemForm.type"
              :error-messages="errors.type"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Typ"
              :items="types"
          />
        </v-col>

        <v-col
            v-if="ItemForm.type === typeList.simple?.value"
            cols="12"
            lg="6"
        >
          <v-text-field
              v-model="ItemForm.minProfit"
              :error-messages="errors.minProfit"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Minimálny zárobok"
              type="number"
              suffix="%"
          />
        </v-col>

        <template
            v-if="ItemForm.type === typeList.group?.value"
        >
          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.priceOffers"
                :error-messages="errors.priceOffers"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Cena práce"
                type="number"
                suffix="€"
            />
          </v-col>

          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.timeOffers"
                :error-messages="errors.timeOffers"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Čas práce"
                type="number"
                suffix="h"
            />
          </v-col>

          <v-col
              cols="12"
              lg="6"
          >
            <v-text-field
                v-model="ItemForm.maxPriceHour"
                :error-messages="errors.maxPriceHour"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Maximálny plat"
                type="number"
                suffix="€ / h"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import Tariffs from '@/services/tariffs'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import SuggestIndexesModule from '@/components/Indexes/SuggestModule'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateSave,
      SuggestIndexesModule
    },
    computed: {
      typeList: function () {
        return this.$store.state.core.Entity.Tariff?.type
      },
      types: function () {
        return Object.values(this.typeList)
      }
    },
    methods: {
      fetchData() {
        return Tariffs
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť tarifu "' + Item.title + '"'
      },
      setItemForm(Item) {
        return {
          title: Item.title,
          description: Item.description,
          price: Item.price,
          frequency: Item.frequency,
          unit: Item.unit,
          type: Item.type,
          minProfit: Item.minProfit,
          priceOffers: Item.priceOffers,
          timeOffers: Item.timeOffers,
          maxPriceHour: Item.maxPriceHour
        }
      },
      updateData(ItemForm) {
        return Tariffs
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
