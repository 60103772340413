<template>
  <ConfirmModal
      color="green"
      icon="mdi-lock"
      :maxWidth="500"
      itemKey="Ticket"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="closeData"
      @onConfirm="(response) => $emit('onClose', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import Tickets from '@/services/tickets'

  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'Close',
    props: [
      'id'
    ],
    emits: [
      'onClose'
    ],
    components: {
      ConfirmModal
    },
    methods: {
      fetchData() {
        return Tickets
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Uzavrieť tiket "' + Item.title + '"'
      },
      setQuestion(Item) {
        return 'Naozaj chcete uzavrieť tiket "' + Item.title + '"?'
      },
      closeData() {
        return Tickets
          .close({
            id: this.id
          })
      }
    }
  }
</script>
