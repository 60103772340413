<template>
  <UpdateSave
      :maxWidth="500"
      itemKey="TicketItemReport"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="(response) => $emit('onUpdate', response)"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.workedAt"
              :error-messages="errors.workedAt"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Dátum vykonania"
              type="date"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-textarea
              v-model="ItemForm.note"
              :error-messages="errors.note"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Poznámka"
              :rows="5"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-input
              :error-messages="errors.files"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Prílohy"
          >
            <template
                v-slot:default
            >
              &nbsp;

              <v-row
                  :no-gutters="true"
                  :dense="true"
              >
                <template
                    v-if="files.length > 0"
                >
                  <v-col
                      v-for="(_file, index) in files"
                      :key="_file.id"
                      cols="12"
                  >
                    <a
                        :href="$global.axiosUrl() + 'files/'  + _file.token + '/generate/original'"
                        target="_blank"
                    >
                      {{ _file.name }}
                    </a>

                    <v-btn
                        :x-small="true"
                        :icon="true"
                        :outlined="true"
                        color="error"
                        @click="() => {
                          ItemForm.files.splice(index, 1)

                          files.splice(index, 1)
                        }"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </template>

                <v-col
                    cols="12"
                >
                  <File
                      :x-small="true"
                      :id="Item.id"
                      @onFile="(response) => {
                        ItemForm.files.push(response.data.data.id)

                        files.push({
                          id: response.data.data.id,
                          token: response.data.data.token,
                          name: response.data.data.name
                        })
                      }"
                  />
                </v-col>
              </v-row>
            </template>
          </v-input>
        </v-col>
      </v-row>
    </template>
  </UpdateSave>
</template>

<script>
  import TicketItemReportsAuth from '@/services/ticket-item-reports-auth'

  import File from '@/components/TicketItemReportsAuth/File'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    emits: [
      'onUpdate'
    ],
    components: {
      File,
      UpdateSave
    },
    data() {
      return {
        files: []
      }
    },
    methods: {
      fetchData() {
        return TicketItemReportsAuth
          .read({
            id: this.id
          })
      },
      setTitle(Item) {
        return 'Upraviť výkaz z ' + this.$moment(Item.workedAt).format('DD.MM.YYYY') + ' od ' + Item.User.name
      },
      setItemForm(Item) {
        this.files = Item.files.map((_File) => {
          return {
            id: _File.id,
            token: _File.token,
            name: _File.name
          }
        })

        return {
          workedAt: this.$moment(Item.workedAt).format('YYYY-MM-DD'),
          note: Item.note,
          files: Item.files.map((_File) => _File.id)
        }
      },
      updateData(ItemForm) {
        return TicketItemReportsAuth
          .update({
            id: this.id,
            data: ItemForm
          })
      }
    }
  }
</script>
