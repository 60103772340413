<template>
  <SuggestPart
      item-value="id"
      item-text="title"
      itemsKey="projects"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <template
          v-if="createButton"
      >
        <CreateClientProject
            :small="true"
            :clientId="clientId"
            @onCreateClient="init"
        />

        &nbsp;
      </template>
    </template>
  </SuggestPart>
</template>

<script>
  import Projects from '@/services/projects'

  import SuggestPart from '@/components/_Part/Suggest'

  import CreateClientProject from '@/components/Projects/CreateClient'

  export default {
    name: 'SuggestClient',
    props: [
      'clientId',
      'createButton'
    ],
    components: {
      SuggestPart,
      CreateClientProject
    },
    methods: {
      fetchData() {
        return Projects
          .suggestClient({
            clientId: this.clientId
          })
      }
    }
  }
</script>
