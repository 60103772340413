import Vue from 'vue'

import Qs from 'qs'

class Wages {
  async indexUser({userId, params}) {
    return await Vue.axios
      .get('app/wages/by-user/' + userId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/wages/' + id)
  }
}

const wages = new Wages()

export default wages
