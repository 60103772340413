<template>
  <SuggestPart
      item-value="id"
      :item-text="(Item) => {
        return this.$moment(Item.deliveredAt).format('DD.MM.YYYY') + ' - ' + Item.title + ' (' + this.$options.filters.currency(Item.balance, '€') + ')'
      }"
      itemsKey="tickets"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import Tickets from '@/services/tickets'

  import SuggestPart from '@/components/_Part/Suggest'

  export default {
    name: 'SuggestOnlyStatusInProgress',
    components: {
      SuggestPart
    },
    methods: {
      fetchData() {
        return Tickets
          .suggestOnlyStatusInProgress()
      }
    }
  }
</script>
