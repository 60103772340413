<template>
  <NotBlankPage
      v-if="$route.params.accountId"
      :key="$route.params.accountId"
      maxWidth="calc(100% - 80px)"
      :hasBackLink="true"
      failLink="/finance"
      itemKey="Account"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <SuggestAccounts
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Účet"
              :createButton="true"
              @change="(value) => {
                $router.push('/finance/' + value)
              }"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-tabs
              v-model="tabs"
          >
            <v-tab>
              Príjmy
            </v-tab>

            <v-tab>
              Výdaje
            </v-tab>

            <v-tab>
              Bilancia
            </v-tab>
          </v-tabs>

          <v-tabs-items
              v-model="tabs"
          >
            <v-tab-item>
              <Revenues
                  :Account="Item"
              />
            </v-tab-item>

            <v-tab-item>
              <Expenses
                  :Account="Item"
              />
            </v-tab-item>

            <v-tab-item>
              <Bilances
                  :Account="Item"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>

  <BlankPage
      v-else
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          maxWidth="100%"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <SuggestAccounts
                  :outlined="true"
                  :dense="true"
                  hide-details="auto"
                  label="Účet"
                  :createButton="true"
                  @change="(value) => {
                    $router.push('/finance/' + value)
                  }"
              />
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import Accounts from '@/services/accounts'

  import Revenues from '@/components/Finance/Revenues'
  import Expenses from '@/components/Finance/Expenses'
  import Bilances from '@/components/Finance/Bilances'

  import SuggestAccounts from '@/components/Accounts/Suggest'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'
  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Finance',
    components: {
      Revenues,
      Expenses,
      Bilances,
      SuggestAccounts,
      NotBlankPage,
      BlankPage,
      CardPart
    },
    data() {
      return {
        tabs: 2
      }
    },
    methods: {
      fetchData() {
        return Accounts
          .read({
            id: this.$route.params.accountId
          })
      },
      setTitle(Item) {
        if (this.$route.params.accountId) {
          return Item.billingName
        } else {
          return 'Financie'
        }
      },
      setBreadcrumbs() {
        if (this.$route.params.accountId) {
          return [
            {
              text: 'Financie',
              to: '/finance',
              exact: true
            }
          ]
        } else {
          return []
        }
      }
    }
  }
</script>
