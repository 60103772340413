import Vue from 'vue'

import Qs from 'qs'

class Notifications {
  async indexUser({userId, params}) {
    return await Vue.axios
      .get('app/notifications/by-user/' + userId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }
}

const notifications = new Notifications()

export default notifications
