<template>
  <ConfirmModal
      color="error"
      icon="mdi-delete"
      :maxWidth="500"
      :itemKey="itemKey"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setQuestion="setQuestion"
      :confirmData="deleteData"
      @onConfirm="(response) => $emit('onDelete', response)"
      v-bind="$attrs"
  />
</template>

<script>
  import ConfirmModal from '@/components/_Part/_Modal/Confirm'

  export default {
    name: 'Delete',
    props: [
      'itemKey',
      'fetchData',
      'setTitle',
      'setQuestion',
      'deleteData'
    ],
    emits: [
      'onDelete'
    ],
    components: {
      ConfirmModal
    }
  }
</script>
