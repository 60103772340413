import Vue from 'vue'

class TicketItemComments {
  async read({id}) {
    return await Vue.axios
      .get('app/ticket-item-comments/' + id)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/ticket-item-comments/' + id)
  }
}

const ticketItemComments = new TicketItemComments()

export default ticketItemComments
