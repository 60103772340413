<template>
  <ModalPart
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :itemKey="itemKey"
      :fetchData="fetchData"
      :setTitle="setTitle"
      v-bind="$attrs"
  >
    <template
        v-slot:default="{
          Item,
          closeDialog
        }"
    >
      <ConsentPart
          :Item="Item"
          :setQuestion="setQuestion"
          :acceptData="confirmData"
          @onSuccess="(response) => {
            $emit('onConfirm', response)

            closeDialog()
          }"
          @onFail="closeDialog"
      >
        <template
            v-slot:default="{
              loading,
              accept
            }"
        >
          <v-row>
            <v-col
                cols="12"
            >
              <v-btn
                  :rounded="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="loading"
                  :color="color"
                  @click="accept"
              >
                <v-icon>
                  {{ icon }}
                </v-icon>

                Áno
              </v-btn>

              &nbsp;

              <v-btn
                  :rounded="true"
                  :outlined="true"
                  color="default"
                  @click="closeDialog"
              >
                <v-icon>
                  mdi-close
                </v-icon>

                Nie
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ConsentPart>
    </template>
  </ModalPart>
</template>

<script>
  import ModalPart from '@/components/_Part/Modal'
  import ConsentPart from '@/components/_Part/Consent'

  export default {
    name: 'Confirm',
    props: [
      'color',
      'icon',
      'maxWidth',
      'itemKey',
      'fetchData',
      'setTitle',
      'setQuestion',
      'confirmData'
    ],
    emits: [
      'onConfirm'
    ],
    components: {
      ModalPart,
      ConsentPart
    }
  }
</script>
