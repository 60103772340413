<template>
  <v-app
      v-if="content"
  >
    <router-view/>

    <v-snackbar
        v-if="$store.state.layout.snackbar.status"
        v-model="$store.state.layout.snackbar.status"
        :app="true"
        :color="$store.state.layout.snackbar.message?.color"
    >
      {{ $store.state.layout.snackbar.message?.content }}
    </v-snackbar>
  </v-app>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        content: false,
        loading: false
      }
    },
    metaInfo() {
      return {
        titleTemplate: this.$global.title() + ' - Systém | %s',
        meta: [
          {
            charset: 'utf-8'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ],
        link: [
          {
            type: 'text/css',
            href: this.$global.style(),
            rel: 'stylesheet'
          }
        ]
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      refresh() {
        this.refreshData()
      },
      initData() {
        this.prepareData('content', false)
      },
      refreshData() {
        this.prepareData('loading', true)
      },
      prepareData(key, value) {
        this[key] = value

        this.$store.dispatch('core/check')
          .finally(() => {
            this[key] = !value
          })
      }
    }
  }
</script>
