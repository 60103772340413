import Vue from 'vue'

class Closings {
  async suggest() {
    return await Vue.axios
      .get('app/closings/suggest')
  }
}

const closings = new Closings()

export default closings
