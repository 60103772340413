<template>
  <NotBlankPage
      maxWidth="calc(100% - 120px)"
      :hasBackLink="true"
      failLink="/mails"
      itemKey="Mail"
      :fetchData="fetchData"
      :setTitle="setTitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:action="{
          Item
        }"
    >
      <Duplicate
          :id="Item.id"
      />

      &nbsp;
    </template>

    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.error"
            cols="12"
        >
          <v-alert
              type="error"
          >
            {{ Item.error }}
          </v-alert>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Od
          </h3>

          <p>
            <a
                :href="'mailto: ' + Item.fromEmail"
            >
              {{ Item.fromEmail }}

              <template
                  v-if="Item.fromName"
              >
                ({{ Item.fromName }})
              </template>
            </a>
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Komu
          </h3>

          <p>
            <a
                :href="'mailto: ' + Item.toEmail"
            >
              {{ Item.toEmail }}

              <template
                  v-if="Item.toName"
              >
                ({{ Item.toName }})
              </template>
            </a>
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Predmet
          </h3>

          <p>
            {{ Item.subject }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Stav
          </h3>

          <p>
            {{ statusList[Item.status]?.text }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Dátum a čas zadania
          </h3>

          <p>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </p>
        </v-col>

        <v-col
            cols="12"
            lg="4"
        >
          <h3>
            Dátum a čas odoslania
          </h3>

          <IsEmptyHelper
              :value="Item.updatedAt"
              if-no="p"
              if-yes="p"
          >
            {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
          </IsEmptyHelper>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-xml"
              title="HTML"
          >
            <template
                v-slot:default
            >
              <iframe
                  :srcdoc="Item.html"
                  width="100%"
                  height="500px"
              />
            </template>
          </PanelPart>
        </v-col>

        <v-col
            cols="12"
            lg="6"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-text"
              title="Text"
          >
            <template
                v-slot:default
            >
              <p>
                {{ Item.text }}
              </p>
            </template>
          </PanelPart>
        </v-col>

        <v-col
            cols="12"
        >
          <PanelPart
              maxWidth="100%"
              color="primary"
              icon="mdi-attachment"
              :title="'Prílohy (' + Item.files.length + ')'"
          >
            <template
                v-slot:default
            >
              <IsEmptyHelper
                  :value="Item.files.length > 0"
                  if-no="v-row"
                  if-yes="p"
                  empty="žiadne"
              >
                <v-col
                    v-for="(_File) in Item.files"
                    :key="_File.id"
                    cols="auto"
                    align="center"
                >
                  <a
                      :href="$global.axiosUrl() + 'files/' + _File.token + '/generate/original'"
                      target="_blank"
                  >
                    <v-icon
                        :large="true"
                    >
                      mdi-file
                    </v-icon>

                    <br/>

                    {{ _File.name }}
                  </a>
                </v-col>
              </IsEmptyHelper>
            </template>
          </PanelPart>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Mails from '@/services/mails'

  import Duplicate from '@/components/Mail/Duplicate'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import PanelPart from '@/components/_Part/Panel'

  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Mail',
    components: {
      Duplicate,
      NotBlankPage,
      PanelPart,
      IsEmptyHelper
    },
    computed: {
      statusList: function () {
        return this.$store.state.core.Entity.Mail?.status
      }
    },
    methods: {
      fetchData() {
        return Mails
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Maily',
            to: '/mails'
          }
        ]
      }
    }
  }
</script>
