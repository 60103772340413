<template>
  <SuggestPart
      item-value="id"
      item-text="name"
      itemsKey="users"
      :fetchData="fetchData"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import UsersAuth from '@/services/users-auth'

  import SuggestPart from '@/components/_Part/Suggest'

  export default {
    name: 'SuggestRoleTicketItem',
    props: [
      'role',
      'ticketItemId'
    ],
    components: {
      SuggestPart
    },
    methods: {
      fetchData() {
        return UsersAuth
          .suggestRoleTicketItem({
            role: this.role,
            ticketItemId: this.ticketItemId
          })
      }
    }
  }
</script>
